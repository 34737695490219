/* @font-face {
  font-family: 'CircularStd-Book';
  src: url('../src/assets/fonts/CircularStd-Book/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/CircularStd-Book/CircularStd-Book.otf') format('opentype'),
    url('../src/assets/fonts/CircularStd-Book/CircularStd-Book.woff') format('woff'),
    url('../src/assets/fonts/CircularStd-Book/CircularStd-Book.ttf') format('truetype'),
    url('../src/assets/fonts/CircularStd-Book/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: 'OpenSans';
  src: url('../src/assets/fonts/OpenSans/OpenSans.eot?#iefix') format('embedded-opentype'),  
  url('../src/assets/fonts/OpenSans/OpenSans.woff') format('woff'), 
  url('../src/assets/fonts/OpenSans/OpenSans.ttf')  format('truetype'), 
  url('../src/assets/fonts/OpenSans/OpenSans.svg#OpenSans') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Roboto';
  src: url('../src/assets/fonts/Roboto-Regular/Roboto-Regular.eot?#iefix') format('embedded-opentype'),  
  url('../src/assets/fonts/Roboto-Regular/Roboto-Regular.woff') format('woff'), 
  url('../src/assets/fonts/Roboto-Regular/Roboto-Regular.ttf')  format('truetype'), 
  url('../src/assets/fonts/Roboto-Regular/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
} */
:root {
  --main-blue-color: #1f82f2;
  --main-text-white-color: #fff;
  --btn-green-color: #94D285;
  --btn-green-hover-color: #A4E295;
  --main-text-black-color: #403e3d;
  --main-text-orange-color: #f28f1f;
  --main-text-green-color: #94D285;
  --star-color: #ff9c45;
  --grey-color: #ddd;
  --chat-border-color: #c4c4c4;
  --main-background-color: #e3e9ed;
  --light-white-color-1: #eee;
  --light-white-color-2: #ccc;
  --light-white-color-3: #CFD8DC;
  --light-white-color-4: #e3e3e3;
  --dark-grey: #aaa;
  --black-color: #403e3d;
  --red-color: #ff0000;
  --facebook-color: #1f82f2;
  --gmail-color: #f28f1f;
  --darkColor-with-02-opacity: rgba(23, 19, 23, 0.2);
  --darkColor-with-05-opacity: rgba(23, 19, 23, 0.5);
  --darkColor-with-06-opacity: rgba(23, 19, 23, 0.6);
  --darkColor-with-07-opacity: rgba(23, 19, 23, 0.7);
  --sliderBgColor: #d6dadd;
  --light-bg-color: #f7f7f7;


  /* var( --main-text-black-color); */
}

/* Master Styles */
h1 {
  font-size: 250%;
}

h2,
h3 {
  font-weight: lighter;
}


body {
  /* margin: 2em; */
  /* background-color: var(--main-background-color) !important; */
  background-color: var( --light-bg-color) !important;
}

html body,
html body button,
html body input,
html body select,
html body textarea {
  font-family: 'Roboto', sans-serif;
}


/* body,
input[text],
button {
  color: #888;
} */

a {
  cursor: pointer;
  cursor: hand;
  color: var(--main-blue-color);
}

button {
  background-color: var(--light-white-color-1);
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  cursor: hand;
}

/* button:hover {
  background-color: var(--light-white-color-3);
} */

button:disabled {
  background-color: var(--light-white-color-1);
  color: var(--dark-grey);
  cursor: auto;
}

/* Navigation link styles */
nav a {
  padding: 5px 10px;
  text-decoration: none;
  /* margin-right: 10px; */
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  /* background-color: var(--light-white-color-1); */
  border-radius: 4px;
}

/* items class */
.items {
  margin: 0 0 2em 0;
  list-style-type: none;
  padding: 0;
  width: 24em;
}

.items li {
  cursor: pointer;
  position: relative;
  left: 0;
  background-color: var(--light-white-color-1);
  margin: .5em;
  padding: .3em 0;
  height: 1.6em;
  border-radius: 4px;
}

.items li:hover {
  color: #607D8B;
  background-color: var(--grey-color);
  left: .1em;
}

.items li.selected {
  background-color: var(--light-white-color-3);
  color: var(--main-text-white-color);
}

.items li.selected:hover {
  background-color: #BBD8DC;
}

.items .text {
  position: relative;
  top: -3px;
}

.items .badge {
  display: inline-block;
  font-size: small;
  color: var(--main-text-white-color);
  padding: 0.8em 0.7em 0 0.7em;
  background-color: #607D8B;
  line-height: 1em;
  position: relative;
  left: -1px;
  top: -4px;
  height: 1.8em;
  margin-right: .8em;
  border-radius: 4px 0 0 4px;
}

/* everywhere else */
* {
  outline: none !important;
}

/* Common Css Start */

body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body .h1,
body .h2,
body .h3,
body .h4,
body .h5,
body .h6 {
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.marginTop1em {
  margin-top: 1em;
}

.marginTop2em {
  margin-top: 2em;
}

.marginTop3em {
  margin-top: 3em;
}

.marginTop4em {
  margin-top: 4em;
}

.marginTop0Imp {
  margin-top: 0px !important;
}

.marginTop1emImp {
  margin-top: 1em !important;
}

.marginTop2emImp {
  margin-top: 2em !important;
}

.marginTop4emImp {
  margin-top: 4em !important;
}

.marginBottom1em {
  margin-bottom: 1em;
}

.marginTop4px {
  margin-top: 4px;
}

.marginBottom2em {
  margin-bottom: 2em;
}

.marginLeft0 {
  margin-left: 0px !important;
}

.marginRight0 {
  margin-right: 0px !important;
}

.marginBottom4em {
  margin-bottom: 4em;
}

.marginBottom1emImp {
  margin-bottom: 1em !important;
}

.marginBottom2emImp {
  margin-bottom: 2em !important;
}

.marginBottom4emImp {
  margin-bottom: 4em !important;
}

.marginBottom0Imp {
  margin-bottom: 0px !important;
}

.nonBold {
  font-weight: normal;
}

body .leftPadding25 {
  padding-left: 25px;
}

body .leftPadding10 {
  padding-left: 10px;
}


body .padding15 {
  padding: 0 15px 15px 15px !important;
}

body .pointerNone {
  pointer-events: none;
}

body .handPointer {
  cursor: pointer;
}

.displayNoneForDesktop {
  display: none;
}

body .noPaddingRightImp {
  padding-right: 0px !important;
}

.btnBlue {
  background-color: var(--main-blue-color);
  color: var(--main-text-white-color);
}

/* .btn.facebookBtn:hover,
.btn.gmailBtn:hover */

/* .btn.facebookBtn:focus,
.btn.gmailBtn:focus  */
/* { */
/* opacity: 0.8; */
/* color: var(--main-text-white-color); */
/* } */

app-sign-up .btnSignUpArea button.btn.gmailBtn:hover {
  opacity: 0.7 !important;
  /* background-color: var(--main-text-white-color) !important;
  color: #db3236 !important;
  border: 1px solid #db3236; */
}

app-sign-up .btnSignUpArea button.btn.facebookBtn:hover {
  opacity: 0.7 !important;
  /* background-color: var(--main-text-white-color) !important;
  color: #3b5998 !important;
  border: 1px solid #3b5998 !important; */
}

.btn.btnBlue:hover,
.btn.btnBlue:active {
  opacity: 0.7 !important;
  /* background-color: var(--main-text-white-color) !important;
  color: var(--main-blue-color) !important; */
}

.btn.btnBlue {
  background-color: var(--main-blue-color) !important;
  color: var(--main-text-white-color) !important;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid var(--main-blue-color);
}

.facebookBtn {
  background-color: var(--facebook-color) !important;
  color: var(--main-text-white-color) !important;
  padding-left: 30px;
  padding-right: 30px;
}

.gmailBtn {
  background-color: var(--gmail-color) !important;
  color: var(--main-text-white-color) !important;
  padding-left: 30px;
  padding-right: 30px;
}


.orangeHeading {
  color: var(--main-text-orange-color);
}

.blueHeading {
  color: var(--main-blue-color);
}

.greyHeading {
  color: var(--darkColor-with-06-opacity);
}

.logo img {
  float: left;
  max-width: 120px;
  position: relative;
  top: 20px;
  margin-right: 37px;
  max-height: 30px;
}

.logoArea {
  width: 190px;
}

.navbar-header.logo {
  width: 165px;
}

.navbar-header.logo.activeDomain {
  width: 169px;
}

.font16 {
  font-size: 16px !important;
}

span.logoText {
  float: left;
}

.verficiationText {
  text-align: left;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 100 !important;
}

.verificationHeader {
  font-size: 22px;
}

h4.signUpHeader {
  font-size: 22px;
}

.marginTop20px {
  margin-top: 20px;
}

.marginTop10px {
  margin-top: 10px;
}

.verificationHeader {
  font-size: 22px;
}

body .form-control {
  border-radius: 6px;
}

.logoText {
  float: left;
  margin-top: 10px;
  margin-right: 10px;
}

h4.signUpHeader {
  font-size: 22px;
}

.CommonHeaderFont22px {
  font-size: 22px;
}

.logoText span {
  float: left;
  margin-top: 4px;
  font-size: 38px;
  color: rgb(237, 125, 49);
  font-weight: bold;
  line-height: 24px;
}

.headerMainArea .navbar-nav>li>a {
  padding: 21px 22px 21px 22px;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  margin: 0;
  position: relative;
  background-color: var(--main-blue-color) !important;
}

/* .headerMainArea .navbar-nav>li>a:after{

} */
.navbar.navbar-default .headerMenu>.nav>li.activeMenu>a:before,
.navbar.navbar-default .headerMenu>.nav>li:hover>a:before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  background-color: var(--main-blue-color);
  left: 10px;
  bottom: 28px;
  border-radius: 10px;
}

.navbar.navbar-default .nav>li.activeMenu>a,
.navbar.navbar-default .nav>li:hover>a {
  color: var(--gmail-color) !important;
}

.iconForNavBar {
  color: black;
}

.noOptionHeader a {
  padding-bottom: 15px !important;
}

.helpMenuSection img {
  width: 21px;
}

.helpMenuSection ul.dropdown-menu {
  margin-top: 12px;
  border-radius: 0px 0px 5px 5px;
}

.header1AccountArea .fa-angle-up {
  display: none;
}

.header1AccountArea .dropdown.open .fa-angle-up {
  display: inline-block;
  text-align: center;
}

.header1AccountArea .dropdown.open .fa-angle-down {
  display: none;
}

.header1AccountArea.headerMenu .dropdown:hover .dropdown-menu {
  display: block;
}

.headerMenu {
  width: calc(100% - 365px) !important;
  text-align: center;
  color: var(--main-blue-color) !important;

}

.menuWithutLoginCenter {
  display: inline-block;
  width: calc(100% - 325px);
  margin-top: 8px;
  text-align: center;
}

.headerMenu ul.nav.navbar-nav {
  width: 100%;
  /* font-weight: 600; */
  text-align: center;
}

.headerMenu ul.nav.navbar-nav li>a {
  text-transform: uppercase;
  /* font-weight: 500; */
  letter-spacing: .085em;
  font-family: 'Poppins', sans-serif !important;
}

.header1AccountArea.text-xs-center.pull-right.logoutBtnArea {
  position: relative;
  top: 7px;
  width: auto;
}

.headerMenu.header1AccountArea>ul>li>a {
  color: var(--main-blue-color) !important;
}

.dropdown-menu>li a {
  letter-spacing: 0 !important;
  text-transform: capitalize !important;
}

.headerMenu ul.nav.navbar-nav li>ul> * {
  color: var(--main-blue-color) !important;
}

.headerMenu li.dropdown {
  float: none;
  display: inline-block;
}

.rightSideUserMenu .fa-angle-up {
  display: none;
}

.rightSideUserMenu .dropdown.open .fa-angle-up {
  display: inline-block;
}

.rightSideUserMenu .dropdown.open .fa-angle-down {
  display: none;
}

body .rightSideUserMenu a.dropdown-toggle {
  padding-top: 10px;
  margin-bottom: 2px;
  padding-right: 0px;
}

.currentUserName {
  text-transform: none;
  margin-right: 5px;
}

.inputWithIcon .inputBox span.glyphicon {
  float: left;
  color: var(--main-text-white-color);
  background: var(--main-blue-color);
  padding: 9px;
  border-radius: 50px;
}

.inputWithIcon .inputBox .form-control {
  width: calc(100% - 40px);
  float: right;
  cursor: pointer
}

.inputBox {
  float: left;
  width: 100%;
  margin-top: 30px;
  /* cursor: pointer; */
}

.blueLink,
.blueHeading {
  color: var(--main-blue-color);
}

body .panel {
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, .15); */
  /* box-shadow: 0 16px 40px 0 rgba(5, 5, 5, 0.1); */
  -webkit-box-shadow: 2px 2px 0px #ddd !important;
  box-shadow: 2px 2px 0px var( --grey-color) !important;
}

body .modal-content {
  background-color: var( --light-bg-color);
}

.footerArea {
  float: left;
  width: 100%;
  background-color: #24242b;
  color: var(--main-text-white-color);
  padding: 15px 0;
  border-top: 1px solid var(--grey-color);
  box-shadow: 0 0 3px #c8c8c8;
  /* margin-top: 25px; */
  position: relative;
  top: 1px;
}

.footerArea p {
  margin: 0px;
  font-size: 12px;
}

.footerArea a {
  color: var(--main-text-white-color);
}

.footerArea a:hover {
  opacity: 0.7;
  color: var(--main-text-white-color);
}

.content {
  min-height: calc(100vh - 49px);
}

.content>.container {
  margin-bottom: 25px;
}

.blueBg {
  background-color: var(--main-blue-color);
}

.greyBg {
  /* background-color: #f5f8fa; */
  background-color: var( --light-bg-color);
  height: 175px;
  /* box-shadow: 0 1px 5px var(--light-white-color-2); */
  /* margin-top: 30px; */
  /* border: 1px solid var(--light-white-color-2); */
}

.greyBg.userNotVerified {
  height: 200px !important;
}

body .navbar {
  margin-bottom: 0px;
}

body .blueBtn

/* body .blueBtn:focus  */
  {
  background-color: var(--main-blue-color);
  color: #fafafa !important;
  font-size: 14px;
  outline: none !important;
}

body .blueBtn:hover,
body .blueBtn:active {
  opacity: 0.7 !important;
  /* background-color: var(--main-text-white-color);
  border: 1px solid var(--main-blue-color);
  color: var(--main-blue-color) !important; */
}

body .noPadding {
  padding: 0px !important;
}

body .noPaddingLeft {
  padding-left: 0px;
}

body .PaddingLeft {
  padding-left: 15px;
}

body .PaddingLeft15px {
  padding-left: 15px !important;
}

body .paddingRight15px {
  padding-right: 15px !important;
}

body .noPaddingRight {
  padding-right: 0px;
}

body .noPaddingTop {
  padding-top: 0px !important;
}

body .noPaddingBottom {
  padding-bottom: 0px !important;
}

body .noPaddingLeftRightImp,
body .equalSizeBtn.noPaddingLeftRightImp {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

html,
body {
  height: 100%;
}

.headerMainArea.navbar-default {
  padding: 5px 0 3px 0;
  background-color: var(--main-text-white-color);
  border: none;
  border-radius: 0px;
  /* box-shadow: 0 2px 0px 0 rgba(23, 19, 23, 0.08); */
  min-height: 70px;
}

.header1AccountArea a {
  background-color: transparent;
  color: var(--main-blue-color) !important;
  /* font-weight: bold; */
}

.header1AccountArea .dropdown-menu>li>a {
  margin-top: 5px;
  margin-bottom: 5px;
}

.notificationBellIcon {
  float: left;
  margin-top: 10px;
  color: var(--main-blue-color);
  /* opacity: .9; */
}

.notificationBellIcon span {
  font-size: 24px;
  float: left;
  color: var(--main-blue-color);
}

.notificationBell .dropdown-menu {
  min-width: 170px;
}

.menuHelpBox {
  margin: 0 0 0 0;
  position: relative;
  top: 12px;
  font-size: 18px;
  background: transparent;
  font-weight: bold;
  /* border: 2px solid var(--main-text-black-color);
  border-radius: 50px; */
  line-height: 18px;
  right: 5px;
  padding: 2px 7px;
}

.menuHelpBox img {
  height: 19px;
}

.menuHelpBox span,
.menuHelpBox span:hover,
.menuHelpBox:hover {
  background: transparent;
  text-decoration: none;
  color: var(--main-text-black-color);
}

/* body .navbar-nav>li>.dropdown-menu {
  margin-top: 24px;
} */

body .navbar-nav.rightSideUserMenu>li>.dropdown-menu {
  font-family: 'Poppins', sans-serif !important;
  margin-top: -10px;
  z-index: 9999999;
}

.rightSideUserMenu .dropdown-menu a {
  margin-top: 5px;
  margin-bottom: 5px;
}

.navbar.navbar-default .nav>li>a {
  font-size: 14px;
  background-color: transparent !important;
}

img.profileImageInTopBar {
  width: 40px;
  border-radius: 50px;
  position: relative;
  top: 0px;
  /* padding-left: 0px !important; */
  /* margin-left: 10px; */
}

body .rightSideUserMenu>li>a {
  margin-right: 0px;
  margin-top: 5px;
}

.logo>a,
.logo>a:hover {
  padding: 0;
  margin: 0;
  background-color: unset;
}

body .dropdown-menu {
  left: unset;
  right: 0;
}

.btnSignUpArea {
  /* background-color: var(--main-text-white-color); */
  /* padding-top: 40px; */
  padding-bottom: 35px;
  /* border-top: 1px solid var(--grey-color); */
  border-radius: 0 0px 10px 10px;
}

.socialBtnRow {
  text-align: center;
}

.socialBtnRow li {
  display: inline-block;
  margin: 2em 15px 0 15px;
}

.btnSignUpArea h4:after {
  content: "";
  position: absolute;
  right: 10%;
  width: 35%;
  height: 1.5px;
  background-color: var(--black-color);
  top: 50%;
  transform: translateY(-50%);
}

.btnSignUpArea h4:before {
  content: "";
  position: absolute;
  left: 10%;
  width: 35%;
  height: 1.5px;
  background-color: var(--black-color);
  top: 50%;
  transform: translateY(-50%);
}

.noMargin {
  margin: 0px !important;


}


.panelPadding {
  padding-top: 2em;
  padding-bottom: 2em;
}

.footerArea.fixedFooter {
  position: absolute;
  bottom: 0;
}

.modal-header .close {
  color: var(--main-text-white-color);
  opacity: 0.8;
}

.setMenuRight {
  float: right;
  margin-bottom: -11px;
}

.setMenuRight>li {
  float: left;
}

.modal-header {
  background-color: var(--main-blue-color);
  color: var(--main-text-white-color);
  border-radius: 4px 4px 0 0;
}

body .equalSizeBtn {
  padding: 8px 30px 8px !important;
  float: right;
  margin-right: 60px;
  min-width: 150px;
}

body .btnGreyBG.btn {
  background-color: var( --light-white-color-2);
}

body .btnGreyBG.btn:hover,
body .btnGreyBG.btn:active {
  /* border-color: var( --light-white-color-2);
  color: var(--main-text-black-color);
  background-color: var( --light-white-color-1); */
  opacity: 0.7 !important;
}

body .orangeBtn.btn,
body .homeSlideBtn.orangeBtn.btn {
  background-color: var(--gmail-color);
  color: var(--main-text-white-color);
}

body .orangeBtn.btn:hover,
body .orangeBtn.btn:active {
  /* border-color: var(--gmail-color);
  color: var(--gmail-color);
  background-color: var(--main-text-white-color); */
  opacity: 0.7 !important;
}

/* body .homeSlideBtn.orangeBtn.btn:hover,
body .homeSlideBtn.orangeBtn.btn:focus,
body .homeSlideBtn.orangeBtn.btn:active {
  background-color: var(--gmail-color);
  opacity: 0.7;
} */

.coachInfoDesc,
.coachInfoDate {
  color: var(--darkColor-with-06-opacity);
}

/* .coachInvDesc b {
  color: var(--main-text-black-color);
} */

.nocursor {
  cursor: unset !important;
  font-size: 17px;
}

.offerList,
.proposalList {
  margin-top: -70px;
}

.noProfileImageSec,
body .sponsoredCoachesCardsSec .noProfileImageSec {
  float: left;
  width: 80px;
  margin-right: 15px;
  margin-left: 15px;
  font-size: 32px;
  padding: 18px 0;
  max-height: 80px;
  text-decoration: none !important;
}

body .sponsoredCoachesCardsSec div .noProfileImageSec {
  margin-left: 0;
}

body .cardInfo {
  min-height: 87px;
}

.chatNoProfileImageSec {
  float: left;
  border-radius: 50px;
  width: 100%;
  font-size: 18px;
  padding: 9px 0;
  max-height: 50px;
}

.hirePersonNoImage {
  float: left;
  border-radius: 10px;
  width: 90px;
  font-size: 40px;
  padding: 17px 0;
  max-height: 114px;
  margin-right: 15px;
  margin-left: -5px;
}

.noProfileImageSecMainChat {
  float: left;
  width: 30px;
  font-size: 14px;
  padding: 6px 0 4px 0;
  border-radius: 50px;
  cursor: pointer;
}

.noProfileImageHeader {
  display: inline-block;
  width: 40px;
  font-size: 16px;
  padding: 10px 0px 10px 0px;
  border-radius: 50px;
  /* margin-left: 10px; */
}

.noProfileImageSecChatFullArea {
  width: 96px;
  font-size: 40px;
  padding: 18px 0;
  max-height: 96px;
  border-radius: 50%;
  text-decoration: none !important;
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;
}

.noProfileImageSecChatFullArea,
.noProfileImageHeader,
.noProfileImageSecMainChat,
.hirePersonNoImage,
.chatNoProfileImageSec,
.noProfileImageSec {
  color: var(--main-text-white-color) !important;
  background-color: var(--main-blue-color) !important;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal !important;
}

.removeNumberArrow::-webkit-outer-spin-button,
.removeNumberArrow::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.removeNumberArrow {
  -moz-appearance: textfield;
  /* Firefox */
}

.fullWidth {
  float: left;
  width: 100%;
}

.fullWidthRight {
  float: right !important;
  width: 100%;
}

.whiteColor {
  color: var(--main-text-white-color);
}

.modalHeader16px {
  font-size: 16px !important;
}

.modalHeader22px {
  font-size: 22px !important;
  font-weight: 800;
}

.ngx-editor-button i {
  font-size: 14px;
  color: #444;
}

.ngx-editor-textarea {
  min-height: 150px !important;
}

.detailDescSec ul,
.ngx-editor-textarea ul {
  display: block;
  list-style-type: disc;
  margin: 0;
  padding-inline-start: 40px;

}

.detailDescSec ol,
.ngx-editor-textarea ol {
  display: block;
  margin: 0;
  padding-inline-start: 40px;
}

.ngx-toolbar-set:nth-child(5),
.ngx-toolbar-set:nth-child(3) {
  display: none !important;
}

.ngx-toolbar-set [title="Paragraph"],
.ngx-toolbar-set [title="Remove Blockquote"],
.ngx-toolbar-set [title="Horizontal Line"],
.ngx-toolbar-set [title="Blockquote"] {
  display: none;
}

/* Common Css Ends */


/* Coach Search list page start */

.detailDescSec p,
.ngx-editor-textarea p {
  font-family: 'Roboto', sans-serif !important;
}

.coachInfoDetails.detailDescSec p {
  margin-top: 0;
}

.detailDescSec {
  word-break: break-word;
}

.blueBannerArea {
  padding-top: 6px;
  padding-bottom: 0px;
  text-align: center;
}

.greyBannerArea {
  padding-top: 20px;
  padding-bottom: 15px;
  text-align: center;
}

.blueBannerArea h4,
.greyBannerArea h4 {
  float: left;
  width: 100%;
  text-align: center;
  color: var(--main-text-white-color);
  margin-bottom: 30px;
}

.mainImageSec {
  float: left;
  width: 60%;
}

.mainProfileImage {
  width: 400px;
  float: left;
  margin-right: 15px;
  border-radius: 10px;
  margin-left: 15px;
}

.croppedImageSec {
  float: right;
  width: 35%;
}

img.source-image {
  box-shadow: 0 0px 10px var( --dark-grey);
  max-height: 300px !important;
}

.croppedImageSec img {
  /* float: left; */
  max-width: 100%;
  box-shadow: 0 0 10px var( --dark-grey);
}

.greyBannerArea.fixed {
  position: fixed;
  z-index: 9;
  width: 100%;
  background-color: var( --light-bg-color);
  top: 0;
  left: 0;
  margin: 0;
  border-bottom: 1px solid var(--light-white-color-2);
  /* box-shadow: 0 1px 5px var(--light-white-color-2); */
}

body .greyBannerArea .skillsDropDownList .selected-list .c-token {
  margin-top: -2px !important;
}

.searchCardExpertise span {
  margin-top: 3px !important;
}

.searchCardMainExpertise {
  margin-top: 22px;
}

.searchCardRating {
  margin-top: 3px;
}

body .selected-list .c-angle-down,
body .selected-list .c-angle-up {
  width: 10px !important;
  right: 15px !important;
}

.skillsDropDownList {
  float: left;
  width: 250px;
  max-width: 100%;
}

body .skillsDropDownList .selected-list .c-btn {
  padding: 10px 20px !important;
  overflow-y: auto;
  top: 11px;
  height: 40px !important;
  display: inline-block !important;
  text-align: left;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border: 1px solid var(--darkColor-with-02-opacity);
  border-radius: 6px;
  font-size: 13px;
}

body .selected-list .c-list .c-token {
  background-color: var(--main-blue-color) !important;
}

body .coachSearchListSec .skillsDropDownList .selected-list .c-token {
  margin-bottom: 4px !important;
}

body .skillsDropDownList .selected-list .c-token {
  margin-top: 0px !important;
  margin-bottom: 2px !important;
}

.coachSearchListSec>div>div {
  padding: 0px;
}

.coachSearchForm {
  margin-left: 15px;
  float: left;
  margin-top: 25px;
  width: calc(100% - 280px);
  text-align: left;
}

.coachSearchForm .formField,
body .dateViewer .wc-date-container {
  display: inline-block;
  width: 250px;
  max-width: 100%;
  height: 40px;
  font-size: 13px;
  margin: 0 10px;
  padding: 10px 20px;
  border: 1px solid var(--darkColor-with-02-opacity);
  box-shadow: none;
}

.btn.searchCoachBtn {
  height: 40px;
  border-radius: 6px;
  background-color: var(--main-background-color);
  /* color: var(--darkColor-with-05-opacity); */
  font-weight: bold;
  padding-left: 24px;
  padding-right: 24px;
}

.coachSearchForm .btn {
  margin-left: 20px;
  margin-top: -3px;
  float: none;
}

img.profileImage {
  width: 90px;
  float: left;
  margin-right: 15px;
  border-radius: 10px;
  margin-left: 15px;
}

.noProfileImage {
  width: 90px !important;
  border-radius: 10px;
  font-size: 40px !important;
  padding: 18px 0 !important;
  min-height: 90px !important;
}

.profileImageInList {
  cursor: pointer;
}

.coachInfo {
  width: calc(100% - 105px);
  float: left;
}

body .optionsSetting .equalSizeBtn {
  margin-right: 10px;
  padding: 8px 0px 8px !important;
}

.proposalInfo .coachInfoDesc {
  max-height: 60px;
  overflow: hidden;
}

.contractDetailModal .coachInfo h4,
.contractDetailModal img.hirePersonImage,
.contractDetailModal .hirePersonNoImage,
.contractDetailModal .coachInfo.proposalStatusInfo h4 {
  cursor: unset !important;
}

/* 
.totalSec {
  position: relative;
  left: -10px;
} */

.coachInfo.proposalStatusInfo {
  width: 100%;
}

body .proposalMsgButton {
  font-size: 12px !important;
  padding: 0px 20px;
  position: relative;
  left: -5px;
  top: 5px;
  width: 90px;
}

.contractDetailModal .modal-dialog {
  width: 1100px;
  max-width: 100%;
}

.contractDetailModal .close {
  position: absolute;
  top: 12px;
  right: 15px;
}

.coachInfo h4,
img.hirePersonImage,
.hirePersonNoImage {
  cursor: pointer;
}

.coachBoxHeading {
  float: left;
  color: var(--main-text-black-color);
  font-size: 20px;
  margin-right: 30px;
  cursor: pointer;
  font-weight: bold;
}

.coachListRatingSec {
  float: left;
  font-size: 14px;
  width: 100%;
  margin-top: 5px;
  color: var(--darkColor-with-06-opacity);
}

.coachListRatingSec b {
  color: var(--main-text-black-color);
}

.greyBox.panel.panel-default {
  margin-bottom: 5px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, .15);
}

.coachInfo span {
  float: left;
  font-size: 14px;
}

.lightColor {
  color: var(--darkColor-with-06-opacity);
}

.coachInfo p {
  float: left;
  width: 100%;
  margin-top: 15px;
}

.coachInfoDetails {
  float: left;
  width: 100%;
  /* height: 37px; */
  overflow: hidden;
  max-height: 60px;
  /* margin-top: 10px; */
  color: var(--darkColor-with-06-opacity);
  margin-bottom: 0;
  font-size: 14px;
  margin-top: 5px;
}

.coachListImageSec {
  float: left;
  width: 125px;
}

.coachInfo a {
  float: left;
  margin-top: 5px;
  color: var(--main-blue-color);
}

.coachSkillsSec span {
  float: left;
  margin-top: 10px;
  word-break: break-word;
  color: var(--darkColor-with-06-opacity);
  font-size: 14px;
}

.coachSkillsSec span.glyphicon.glyphicon-map-marker {
  margin-right: 4px;
  font-size: 18px;
}

/* .coachSkillsSec h3.coachBoxHeading {
  margin-top: 20px;
} */



.coachSkillsSec button.btn.blueBtn {
  padding: 10px 30px 11px;
  border-radius: 4px;
  position: relative;
  top: 10px;
  left: -5px;
  float: right;
  width: 100%;
  max-width: 160px;
}

a.coachListReadReview {
  font-size: 14px;
  color: var(--main-text-black-color);
  /* font-weight: bold; */
  text-decoration: underline !important;
}

.coachProfileArea .coachMainInfoSec a.coachListReadReview {
  position: relative;
  top: -3px;
}

a.coachListReadReview:hover {
  color: var(--main-text-black-color);
  text-decoration: none !important;
}

.locationImage {
  width: 25px;
  margin-top: 15px;
}

body .addressSec {
  float: left;
  width: 70%;
  margin-top: 10px;
  margin-left: 12%;
}

.favButton {
  position: absolute !important;
  left: 15px;
  top: -8px !important;
  cursor: pointer;
  background-color: var(--main-text-white-color);
  border-radius: 16px;
  width: 32px;
  height: 32px;
  text-align: center;
}

.favButton img {
  margin-top: 10px;
}

body .addressSec span {
  margin-top: 0;
  font-size: 10px;
  float: left;
  text-align: center;
  width: 100%;
}

.ratingImage {
  float: right;
  width: 80px;
  margin-left: 10px;
}

.reviewRatingSec {
  margin-top: 10px;
}

h3.needCoach {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: var(--main-text-black-color);
  position: relative;
  margin-bottom: 20px;
  margin-top: 5px;
}

body .greyBox,
.paymentBox {
  background-color: var(--main-text-white-color);
  padding: 20px 0;
  margin-bottom: 20px !important;
  /* box-shadow: 0 16px 40px 0 rgba(5, 5, 5, 0.1) !important; */
  -webkit-box-shadow: 2px 2px 0px #ddd !important;
  box-shadow: 2px 2px 0px var( --grey-color) !important;
  border-radius: 6px;
  border: none !important;
}

.fullCoachListSec {
  position: relative;
  top: -100px;
}

.fullCoachListSec .coachInfo {
  width: calc(100% - 150px);
}

.fullCoachListSec .btnsSec {
  margin-bottom: 0 !important;
  margin-top: 15px;
  float: right !important;
  margin-right: 5px;
}

.fullCoachListSec .btnsSec .btn {
  /* min-width: 159px; */
  font-size: 14px;
  float: right;
  left: 0px !important;
  top: 0px !important;
  max-width: 160px;
  width: 100%;
}

.fullCoachListSec .btnsSec .btn.unfollowBtn {
  padding: 11px 30px 10px 30px;
}

.messageCoach a.btn.orangeBtn.whiteColor {
  padding-top: 10px;
  padding-bottom: 10px;
}

.coachInfoDate {
  float: left;
  width: 100%;
}

body .fullCoachListSec .btnsSec button.btn.unfollowBtn.pull-right {
  margin-top: -5px;
  padding: 10px 30px;

}

.disableBtn {
  pointer-events: none;
  opacity: 0.5;
}

.fullCoachListSec .btnsSec .btn:hover {
  font-size: 14px;
}

.greyBannerArea.fixed>div {
  width: 1170px;
  margin: auto;
  float: none;
  position: relative;
  top: -8px;
}

h3.needCoach:after {
  content: "";
  position: absolute;
  width: 90px;
  height: 2px;
  background-color: var(--main-background-color);
  top: 5px;
  right: 300px;
}

h3.needCoach:before {
  content: "";
  position: absolute;
  width: 90px;
  height: 2px;
  background-color: var(--main-background-color);
  top: 5px;
  left: 300px;
}

body .pure-checkbox label::after {
  border-color: var(--main-blue-color) !important;
}

body .pure-checkbox input:checked+label:before {
  /* background: var(--main-blue-color) !important; */
  border-color: var(--main-blue-color) !important;
  color: var(--main-blue-color) !important;
}

/* Coach Search list page Ends */

/* Complete Your Profile Page Start */
.iAgreeArea label {
  font-weight: normal;
}

.iAgreeArea {
  margin-bottom: 2em;
}

.userTypeArea .active {
  border: 1px solid var(--main-blue-color);
  background-color: var(--main-text-white-color);
  color: var(--main-blue-color);
}

.userTypeArea .active:hover {
  color: var(--main-blue-color);
}

.ratingArea span {
  position: relative;
  top: 2px;
  right: 0px;
  margin: 0;
  float: none;
}

/* Complete Your Profile Page End */

/* Account Page Start */
.accountPaymentLogo {
  width: 210px;
  float: left;
  margin-right: 10px;
  padding-top: 28px;
  padding-bottom: 45px;
}

.paymentBox h4 {
  float: left;
  width: calc(100% - 250px);
  margin-bottom: 10px;
  font-size: 20px;
  color: var(--main-blue-color);
}

ul.paymentConditions {
  float: left;
}

.paymentConditions li::before {
  content: "●";
  color: var(--main-blue-color);
  font-size: 16px;
  padding-left: 2px;
  position: relative;
  left: -10px;
}

.paymentBox a.pull-right {
  margin-top: 5px;
  color: var(--main-blue-color);
  position: absolute;
  right: 20px;
}

.otherSkypeInfo {
  float: left;
  width: 100%;
  /* padding-left: 15px; */
  margin-top: 15px;
}

.skypeData {
  text-align: left;
  vertical-align: middle;
  margin-top: 35px;
  padding-bottom: 20px;
}

/* Account Page Ends */

/* Coach Profile Page Start */
/* 
.dividerLine {
  border-left: 10px solid #E3E9ED;
} */

.row.profileRateSec {
  max-height: 200px;
  overflow: hidden;
}

.row.profileRateSec.expandProfileRateSec {
  max-height: unset;
}

.showMoreLessSec span {
  color: var(--main-blue-color);
  text-decoration: underline;
  cursor: pointer;
}

.showMoreLessSec span:hover {
  text-decoration: none;
}

.hireCoachSliding {
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  z-index: 9999;
  transition: 0.5s ease;
}

.hireCoachSlidingMain {
  position: fixed;
  right: -90%;
  top: 0;
  background-color: var(--main-background-color) !important;
  width: 90%;
  height: 100%;
  overflow-y: auto;
  box-shadow: 0px 0px 10px #555;
  transition: 0.5s ease;
  opacity: 0;
}

button.closeBtnHireModal {
  font-size: 40px;
  background-color: var(--main-text-white-color);
  position: absolute;
  right: 90.5%;
  border-radius: 70px;
  height: 50px;
  width: 50px;
  line-height: 0;
  padding: 0;
  top: 0px;
  transition: 0.5s ease;
}

.showHireDiv {
  width: 100%;
  transition: 0.5s ease;
}

.showHireDiv .hireCoachSlidingMain {
  opacity: 1;
  right: 0%;
  transition: 0.5s ease;
}

.hireCoachDiv {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding-top: 15px;
}

.hireCoachDiv .panel.panel-default {
  border: 0px solid #000 !important;
}

/* .hireCoachExpertiseSec,*/
.hireCoachRateSec {
  height: 316px;
  overflow-y: auto;
}

body .hireCoachExpertiseSec {
  height: 90px;
  width: 98%;
  margin-right: 2%;
  height: 90px;
}

.hireCoachExpertiseSec span.hireSkillName {
  float: right;
  margin-top: 3px;
  margin-left: 2px;
  width: calc(100% - 18px);
}

.hireCoachPopup .modal-dialog {
  width: 850px;
  max-width: 95%;
  max-height: calc(100% - 100px);
  overflow-x: auto;
  border-radius: 6px;
}

.hireCoachRateSec label {
  text-indent: 10px;
}

.hireCoachDiv i.fa.fa-external-link,
.settingMainTab i.fa.fa-external-link {
  padding-left: 5px;
}

.homeCoachesList a.expertiseLink {
  color: var(--gmail-color);
  text-decoration: none !important;
  font-size: 12px;
  text-transform: uppercase;
  /* font-family: 'Poppins', sans-serif !important; */
}

.coachSlider.slick-initialized.slick-slider {
  float: left;
  width: 100%;
}

span.homeSkillheading {
  width: 100%;
  text-align: center;
  display: block;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 5px;
  /* font-family: 'Poppins', sans-serif !important; */
}

.hireCoachPopup .content {
  min-height: unset;
}

img.integrationLogo {
  float: left;
  max-width: 200px;
  padding: 25px 0;
  max-height: 100px;
  margin-top: 20px;
}

app-hire-coach a.linkBtn {
  position: relative;
  top: 7px;
}

.hireModalHeading {
  font-size: 20px;
  font-weight: bold;
  padding-left: 15px;
  position: relative;
  top: 7px;
}

.hireCoachPopup button.close {
  top: 18px;
  position: relative;
  right: 18px;
  z-index: 99;
}

body .hirePageText {
  padding: 15px;
  min-height: 570px;
}

.hireCoachDiv textarea.form-control {
  resize: none;
  max-height: 100px;
}

.hireCoachExpertiseSec label {
  width: 100%;
}

p.fullWidth.zoomtext2nd {
  margin-top: 15px;
  margin-bottom: 5px;
}

.ratingPopupBox {
  display: none;
  position: absolute;
  color: var( --main-text-black-color);
  right: 115px;
  background-color: var(--main-text-white-color);
  border: 1px solid var( --main-text-black-color);
  top: -12px;
  z-index: 9999;
  padding: 10px 10px;
  border-radius: 10px;
  width: 300px;
}

.commentsSec .ratingPopupBox {
  display: none;
  position: absolute;
  color: var( --main-text-black-color);
  background-color: var(--main-text-white-color);
  border: 1px solid var( --main-text-black-color);
  top: -12px;
  z-index: 9999;
  padding: 10px 10px;
  border-radius: 10px;
  width: 280px;
  /* left: -77px; */
}

.hireCoachDiv li input[type=checkbox],
.hireCoachDiv li input[type=radio],
.hireCoachDiv span input[type=radio] {
  position: relative;
  top: 2px;
}

.ratingPopupBox span.ratingCount {
  float: right;
  font-size: 14px !important;
  margin: 0;
  right: 0;
  width: auto;
}

.ratings:hover .ratingPopupBox {
  display: block;
}

.modalDetailReview,
.ratingBox h3 {
  float: left;
  width: 100%;
}

.ratingBarArea span {
  font-size: 16px !important;
  float: left;
  width: 55px;
}

.ratingBarArea li {
  float: left;
  width: 100%;
  margin: 2px 0;
}

.ratingBar {
  width: calc(100% - 110px);
  height: 25px;
  border: 1px solid var( --main-text-black-color);
  float: left;
  margin-left: 5px;
  max-width: 100%;
}

span.ratingHeaderSec {
  font-size: 18px !important;
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.ratingBarWidth {
  float: left;
  background-color: var(--gmail-color);
  max-width: 100%;
  height: 23px;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var( --main-text-black-color);
  position: absolute !important;
  z-index: 999;
  right: -10px !important;
  top: 13px !important;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var( --main-text-black-color);
  position: absolute !important;
  z-index: 999;
  left: -10px;
  top: 13px !important;
  display: none;
}

/* .arrow-right {
  width: 0; 
  height: 0; 
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  
  border-left: 60px solid green;
} */

.coachProfileArea textarea.form-control,
.contactTextarea {
  min-height: 75px;
  resize: none;
}

.uploadIcon {
  left: -5px;
  position: relative;
}

.uploadBoxIcon {
  top: -30px !important;
  right: -70px;
  background-color: var(--main-text-white-color);
}

body .modelBtn.orangeBtn {
  color: var(--main-text-white-color);
  padding: 6px 30px;
}

body .modelBtn.orangeBtn:hover {
  color: var(--main-text-orange-color);
}

/* #bannerPic .modal-dialog {
  width: 1300px;
  max-width: 90%;
} */

.userProfileBanner {
  height: 400px;
  width: 100%;
  float: left;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  /* opacity: 0.7; */
  top: 15px;
}

.bannerAdded.coachMainInfoSec {
  /* margin-top: -125px; */
  position: relative;
  z-index: 9;
}

#bannerPic .cropper span.resize {
  display: none;
}

#bannerPic .cropper span.resize-bar {
  pointer-events: none;
}

.dimentionInfo {
  font-size: 10px;
  opacity: 0.7;
  margin-top: 5px;
}

p.orginalSec {
  margin-bottom: 5px;
  margin-top: 20px;
  font-size: 20px;
}

body .cropper .move {
  border: 3px dotted var( --main-text-black-color) !important;
}

body .subTitleSec {
  min-height: auto !important;
  height: 60px !important;
}

.mainHeadingProfile {
  margin-bottom: 5px;
  color: var(--main-text-black-color);
  font-size: 22px;
  font-weight: bold;
}

.lightColorSec {
  color: var(--darkColor-with-06-opacity);
  font-weight: normal;
}

.coachProfileImageArea {
  position: relative;
  float: left;
}

.coachDesc h3.porfileUserName span {
  top: 0px;
  left: 0px;
}

.coachProfileArea .selected-list .c-list .c-token .c-label {
  color: var(--main-text-white-color);
}

.coachProfileArea .form-control {
  display: inline-block;
  max-width: 100%;
  height: 40px;
  font-size: 13px;
  padding: 10px 20px;
  border: 1px solid var(--darkColor-with-02-opacity);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.coachBGSecNew {
  float: left;
  width: 100%;
  height: 200px;
  position: absolute;
  /* background-color: #f5f8fa; */
  background-color: var( --light-bg-color);
  z-index: -1;
}

.profileRadioSec {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

body .coachProfileImageArea .favButton {
  left: -15px;
  top: -15px !important;
}

.coachProfileArea h4 {
  margin-bottom: 10px;
  color: var(--darkColor-with-06-opacity) !important;
  font-size: 14px;
  display: inline-block;
}

h4.coachDescHeading {
  position: relative;
}

.coachDesc h3 span,
.coachHourlySec h3 span,
.coachAboutSec h3 span {
  position: relative;
  top: -5px;
  /* left: 15px; */
}

.coachProfileArea .noProfileImageSec {
  padding: 53px 0;
  max-height: 150px;
  width: 150px;
  margin: 0;
}

.coachMainInfoSec {
  position: relative;
}

.col-xs-12.usdRate.usdRateOfProfile {
  margin-top: 10px;
}

.row.hourlyRateSec {
  padding-top: 35px;
}

.coachProfileRating .ratingImage {
  float: none;
}

.coachDesc h3,
.defaultHeading,
.hirePageText h3,
.hirePagePadding h3,
.coachHourlySec h3,
.coachAboutSec h3 {
  color: var(--main-text-black-color) !important;
  font-size: 20px;
  font-weight: bold;
}

span.totalRating {
  border: 1px solid var(--main-text-black-color);
  padding: 2px 10px;
  border-radius: 5px;
}

span.totalRating {
  border: 1px solid var(--main-text-black-color);
  padding: 4px 10px;
  border-radius: 5px;
  background-color: var(--main-text-orange-color);
  color: var(--main-text-white-color);
  font-size: 15px;
}

.coachProfileArea img.profileImage {
  width: 150px;
  margin: 0;
  border-radius: 0;
}

.coachProfileArea .profilePostsSec img.profileImage {
  width: unset;
  max-width: 150px;
}

.termSec {
  float: left;
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  color: var(--darkColor-with-06-opacity);
}

.scoreSec,
.coachSkillArea span {
  font-size: 14px;
}

.coachDesc .ratingArea,
.coachDesc .coachListReadReview {
  float: left;
  margin-right: 5px;
}

.coachDesc .ratingArea {
  margin-top: -3px;
  margin-left: 10px;
}

.termSec b,
.scoreSec b {
  color: var(--main-text-black-color);
  margin-left: 5px;
}

.coachDesc {
  float: left;
  width: calc(100% - 350px);
}

.coachProfileButtonGroup {
  text-align: right;
  /* margin-top: 20px; */
  float: right;
  width: 200px;
}

.coachProfileButtonGroup .btn {
  /* margin-top: 10px; */
  width: 160px;
  text-align: center;
  padding: 10px 0 11px 0;
  margin: 10px 0;
  display: block;
}

.coachProfileButtonGroup .btn.hireCoachBtn {
  background-color: var(--gmail-color) !important;
  color: var(--main-text-white-color) !important;
  border-color: var(--gmail-color);

}

/* .coachRightRatingArea {
  position: relative;
  right: 50%;
} */

app-coach-profile button.btn.blueBtn.hireCoachBtn {
  position: absolute;
  bottom: 15px;
  right: 30px;
}

app-coach-profile .coachProfileButtonGroup button.btn.blueBtn.messageBtn {
  position: absolute;
  bottom: 70px;
  right: 30px;
}

.publishBtn {
  right: 30px;
  float: left;
  margin-top: 12px !important;
  /* bottom: 50px !important; */
}

.coachProfileButtonGroup .ratingArea {
  text-align: center;
}

.coachProfileButtonGroup .btn.hireCoachBtn:hover,
/* .coachProfileButtonGroup .btn.hireCoachBtn:focus, */
.coachProfileButtonGroup .btn.hireCoachBtn:active {
  /* background-color: var(--main-text-white-color) !important;
  color: var(--gmail-color) !important;
  border-color: var(--gmail-color); */
  opacity: 0.7 !important;
}

body .coachHourlySec {
  border: none !important;
  padding: 20px 15px;
  border-radius: 6px;
  min-height: 225px;
}

.coachHourlySec ul {
  float: left;
  width: 100%;
  margin: 0;
}

.coachHourlySec li {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--grey-color);
  padding-bottom: 10px;
}

.coachHourlySec li:last-child {
  border: none;
  padding: 0;
  margin: 0;
}

.coachHourlySec label {
  margin-bottom: 3px;
}

.coachAboutSec {
  border: none !important;
  border-radius: 6px !important;
  padding: 20px 0px;
  margin-left: 0px !important;
  min-height: 245px;

}


.coachAboutSec h3,
.commentsSec h3,
.coachHourlySec h3 {
  float: left;
  width: 100%;
  color: var(--main-text-black-color);
  /* border-bottom: 1px solid var(--main-background-color); */
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.commentsSec h3 {
  /* padding: 20px 15px 0 15px; */
  /* text-align: center; */
  font-size: 20px;
  font-weight: bold;
  /* color: #ff9c45; */
  margin-bottom: 0 !important;

}

.commentsSec h3 span {
  color: var(--main-blue-color);
}

.commentsSec .ratingArea span {
  color: var(--main-text-black-color);
  font-size: 26px;
}


.coachAboutSec p {
  float: left;
  width: 100%;
  text-align: justify;
}

.coachSkillArea .singleSkillArea {
  float: left;
}

body .commentsSec {
  margin-bottom: 0px;
  padding-bottom: 15px;
  border: none !important;
  border-radius: 6px;
}

.coachMainInfoSec {
  border-radius: 6px !important;
  /* margin-top: 75px; */
  border: none !important;
  /* min-height: 300px; */
}

.commentsSec .reviewBoxArea {
  padding-left: 15px;
}

/* .coachSkillArea>div>span {
  background-color: var(--main-blue-color);
  color: var(--main-text-white-color);
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;
  float: left;
  margin-right: 5px;
}

.coachSkillArea>div>span:hover {
  opacity: 0.7;
} */

.coachReviewRatingBox .ratingImage {
  float: none;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 5px;
}

.coachTotalRating span {
  background-color: var(--main-text-black-color);
  color: var(--main-text-white-color);
  padding: 7px 7px;
  border-radius: 30px;
}

.ratingGridFullArea span {
  float: left;
  margin-right: 5px;
}

.ratingGridFullArea span.ratingGrid {
  width: calc(100% - 45px);
  background-color: var(--grey-color);
  height: 20px;
  border-radius: 2px;
  margin-left: 5px;
}

.ratingGridFullArea>div {
  margin: 3px 0;
}

.coachReviewRatingBox {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 1px 5px var(--grey-color);
}

.coachReviewRatingBox {
  border: 1px solid var(--grey-color);
  padding: 25px 0;
}

.ratingGridFullArea .glyphicon {
  color: var(--main-text-orange-color);
}

span.ratingGridSuccess {
  float: left;
  background-color: var(--btn-green-color);
  height: 20px;
  border-radius: 2px;
}

body .commentsSec h4 {
  float: left;
  color: rgba(23, 19, 23, 0.68);
  font-size: 12px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
}

body .commentsSec .reviewBoxArea p {
  color: var(--main-text-black-color);
  font-size: 14px;
  /* font-weight: bold; */
}

.reviewBoxArea .clientProfileImage {
  float: left;
  width: 85px;
}

.reviewBoxArea p {
  width: auto;
  margin-left: 15px;
}

.reviewBoxArea .ratingImage {
  float: none;
  margin-bottom: 5px;
  margin-top: 5px;
}

.reviewBoxArea p {
  width: 100%;
  margin-left: 15px;
  margin-bottom: 0;
}

.reviewBoxArea .reviewBy {
  font-size: 12px;
  font-style: italic;
  opacity: 0.8;
}

body button.btn.blueBtn.editProfileBtn {
  margin-top: 35px;
}

.personalFullAddress span {
  float: left;
}

span.personalFullAddress {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.coachAboutMainSec.editProfileSec .coachAboutSec {
  /* min-height: 643px; */
  min-height: 282px;
}

.coachAboutMainSec1 {
  min-height: 600px !important;
}

span.editRateIcon {
  font-size: 14px;
  cursor: pointer;
  color: var(--main-blue-color);
  margin: 0px 5px;
}

span.deleteIcon {
  font-size: 16px;
  cursor: pointer;
  color: var(--red-color);
  margin: 0px 5px;
  opacity: 0.7;
}

.separateSec {
  margin-top: 40px;
}

span.editProfileIconSec {
  position: absolute;
  font-size: 16px;
  right: 0px;
  top: -7px;
  cursor: pointer;
  color: var(--main-blue-color);
  z-index: 99;
}

app-coach-profile .coachAboutSec h3,
app-coach-profile .commentsSec h3,
app-coach-profile .coachHourlySec h3 {
  width: auto;
}

span.editProfileIconSec.updateUserImage {
  background-color: transparent;
  line-height: 0;
  height: 35px;
  width: 35px;
  padding: 10px 0px 0 10px;
  border-radius: 20px;
  top: 0;
  color: var(--main-text-white-color);
}

.editIconColor {
  color: var(--main-blue-color) !important;
}

.editProfileIconSec.hideSec.overviewSec {
  right: -15px;
}

span.editProfileIconSec.updateUserImage.bannerImageUpdateIcon {
  z-index: 9;
  right: 10px;
  bottom: 10px;
  top: unset;
  background-color: var(--main-text-white-color);
  color: var(--main-blue-color);
}


span.editProfileIconSec.updateUserImage.ProfileImageUpdateIcon {
  z-index: 9;
  right: 0px;
  /* bottom: 10px; */
  top: unset;
  background-color: var(--main-text-white-color);
  color: var(--main-blue-color);
  /* border: 1px solid var(--main-blue-color); */
  border-radius: 50px;
}

.noBannerImageSec {
  position: relative;
  float: left;
  width: 100%;
  height: 400px;
  background-image: url('./assets/images/DefaultBanner.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  top: 15px;
  z-index: 1;
}

.coachProfileArea .packagesSec h4.lightColorSec {
  font-size: 16px;
  font-weight: bold;
  float: left;
  width: 100%;
  margin-bottom: 10px !important;
  color: #000 !important;
}

app-coach-profile .coachAboutSec h3 {
  font-size: 18px;
}

.packagesSec .packTitleSec {
  margin-top: 10px;
  margin-bottom: 5px;
}

.packagesSec .noPadding.handPointer {
  float: left;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
}

/* Coach Profile Page Ends */

/* Help Page Start */
.helpArea .panel-header {
  background-color: var(--main-blue-color);
  padding: 20px 0;
  color: var(--main-text-white-color);
}

.helpArea textarea.form-control {
  resize: none;
  height: 200px;
}

/* Help Page Ends */

/* Loading Area Start */
.loadingArea {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 30px;
  color: var(--main-text-white-color);
  left: 0;
  top: 0;
  z-index: 99999;
  text-align: center;
}

/* Loading Area Ends */

/* Toast CSS Start */

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: var(--main-text-white-color);
}

.toast-message a:hover {
  color: var(--light-white-color-2);
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: var(--main-text-white-color);
  text-shadow: 0 1px 0 var(--main-text-white-color);
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: var(--black-color);
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.notoficationPopuptext {
  line-height: 1.5;
  padding: 10px;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .toast {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: var(--main-text-white-color);
}

.toast-container .toast:hover {
  box-shadow: 0 0 12px var(--black-color);
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .toast,
.toast-container.toast-bottom-center .toast {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .toast,
.toast-container.toast-bottom-full-width .toast {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: var(--black-color);
  opacity: 0.4;
}

div#toolbar {
  position: fixed !important;
  left: 0;
  top: 50% !important;
  transform: translateY(-50%);
  height: unset !important;
}

div#toolbar button {
  display: block;
  min-width: 115px;
  margin-bottom: 5px;
  margin-top: 5px;
  text-transform: capitalize;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .toast.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (max-width: 375px) {
  .forHeading {
    margin-right: 0px !important;
  }

  .endContract {
    margin-top: 16px !important;
  }

  .postLikeCommentSec li {
    margin-right: 10px !important;
  }

  /* .deletePackage {
    margin-left: 0px !important;
    top: 20px !important;
    left: -104px;
  } */

  /* .endContract{
    margin-top: 16px !important;
  } */

  .ratesUSD {
    top: 5px;
  }

  .packagesSec .packageRow {
    margin-bottom: 40px !important;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .toast.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .listImageSec {
    /* float: left; */
    width: 35px;
    /* margin-left: 15px; */
  }

  .postList .profileImage {
    height: 25px;
    width: 25px;
  }

  .listDataSec {
    /* float: left; */
    width: calc(100% - 50px);
  }

  .topLikeCommentSec li {
    margin-left: 0px;
    padding: 2px 10px !important;
  }

  .ratings {
    text-align: left;
    left: 27px
  }

  .packageForHeading {
    margin: 0 5px !important;
  }

  /* .greyBg {
    height: 220px !important;
  } */

  .usdRate {
    font-size: 9px !important;
  }

  .paddingLeftXS {
    padding-left: 15px !important;
  }

  /* .endContract{
    margin-top: 0px !important;
  } */
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }

  .labelForNotification {
    display: inline;
  }

  body .noPaddingXs {
    padding: 0px;
  }

  .ratesUSD {
    top: 5px;
  }

  /* .deletePackage {
    margin-left: 0px !important;
  } */

  .packagesSec .packageRow {
    margin-bottom: 30px !important;
  }

  .pull-Left-XS {
    float: left !important;
  }

  /* .locationSearchSec{
    background-position: 260px 10px;
  } */
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .toast.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }

  .paddingleftRes {
    padding-left: 15px !important;
  }

  /* .locationSearchSec{
    background-position: 260px 10px;
  } */
}

/* Toast Css ends */

/* Chat Message Start */

.inbox_people {
  background: var(--main-text-white-color) none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 30%;
  border: none;
  border-radius: 6px;
  margin-right: 2%;
  position: relative;
  z-index: 99;
  -webkit-box-shadow: 2px 2px 0px #ddd !important;
  box-shadow: 2px 2px 0px var( --grey-color) !important;
}

.panel.inbox_msg {
  border: none;
  background-color: transparent;
  clear: both;
  /* overflow: hidden; */
  box-shadow: none;
  border-radius: 0px;
}

.top_spac {
  margin: 20px 0 0;
}

.unreadBadge {
  position: relative;
}

.mesgs ngx-loading .full-screen {
  position: absolute;
}

.unreadBadge:after {
  content: "";
  background-color: var(--red-color);
  width: 10px;
  height: 10px;
  left: 0;
  top: 0;
  z-index: 99;
  position: absolute;
  border-radius: 50%;
}

.recent_heading {
  float: left;
  width: 40%;
}

.srch_bar {
  display: inline-block;
  /* text-align: right; */
  width: 100%;
  position: relative;
}

.heading_srch {
  padding: 10px 20px;
  overflow: hidden;
  margin-top: 10px;
}

.recent_heading h4 {
  color: var(--main-blue-color);
  font-size: 21px;
  margin: auto;
}

.srch_bar .glyphicon {
  position: absolute;
  color: #868588;
  top: 14px;
  left: 11px;
  font-size: 11px;
}

.srch_bar input {
  width: 100%;
  padding: 10px 0 10px 30px;
  background: #f5f8fa;
  border: none;
  border-radius: 4px;
}

.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}

.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 14px;
  color: var(--main-text-black-color);
  margin: 2px 0 2px 0;
}

.chat_ib h5 span {
  font-size: 10px;
  float: right;
  color: #757779;
}

.chat_ib p {
  font-size: 12px;
  color: #989898;
  margin: auto
}

.chat_img {
  float: left;
  width: 11%;
}

.chat_img img,
.incoming_msg_img img {
  float: left;
  border-radius: 50px;
  width: 100%;
}

.chat_img img {
  width: 100%;
}

.incoming_msg_img img {
  width: 30px;
}

.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}

.chat_list {
  margin: 0 6% 2px 6%;
  padding: 14px;
  cursor: pointer;
  width: 88%;
  border-radius: 3px;
}

.inbox_chat {
  height: 550px;
  overflow-y: auto;
}

.active_chat,
.chat_list:hover {
  background: var(--main-blue-color);
}

.active_chat .chat_ib h5,
.active_chat .chat_ib p,
.chat_list:hover .chat_ib h5,
.chat_list:hover .chat_ib p {
  color: var(--main-text-white-color);
}

.active_chat .chat_ib span,
.chat_list:hover .chat_ib span {
  color: var(--main-text-white-color);
}


.chat_list .chatNoProfileImageSec {
  font-size: 12px;
  padding: 7px 0;
}

.active_chat .chatNoProfileImageSec,
.chat_list:hover .chatNoProfileImageSec {
  opacity: 0.7 !important;
  /* background-color: var(--main-text-white-color) !important;
  color: var(--main-blue-color) !important; */
}

.incoming_msg_img {
  display: inline-block;
  width: 30px;
  margin-top: 2px;
}

.outgoingMsgImage img {
  width: 30px;
  border-radius: 50px;
}

.outgoing_msg .noProfileImageSecChatFullArea {
  float: right;
  width: 30px;
  font-size: 14px;
  padding: 6px 0 4px 0;
  margin-top: 0;
  border-radius: 50px;
}

.chatBoxCurrentOpponentUserArea {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  border-bottom: 1px solid var( --grey-color);
  padding: 10px 25px;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}

.received_withd_msg p {
  /* background: var(--main-blue-color); */
  border-radius: 3px;
  /* color: var(--main-text-white-color); */
  font-size: 14px;
  margin: unset;
  padding: 10px;
  width: 75%;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: #f1f1f4;
  color: #000;
}

.time_date {
  color: #747474;
  display: inline-block;
  font-size: 10px;
  margin: 8px 0 0;
  position: relative;
  right: 0;
  top: -15px;
}

.received_withd_msg {
  width: 60%;
  text-align: right;
  position: relative;
}

.mesgs {
  float: left;
  padding: 70px 15px 20px 25px;
  width: 68%;
  position: relative;
  background-color: var(--main-text-white-color);
  border-radius: 6px;
  /* box-shadow: 0 16px 40px 0 rgba(5, 5, 5, 0.1); */
  -webkit-box-shadow: 2px 2px 0px #ddd !important;
  box-shadow: 2px 2px 0px var( --grey-color) !important;
  min-height: 620px;
}

.sent_msg p,
.sent_msg p a {
  background: var(--main-blue-color) none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: var(--main-text-white-color);
  word-break: break-word;
  padding: 5px 10px 5px 12px;
  width: 100%;
  white-space: pre-wrap;
}

.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
  position: relative;
}

.outgoingMsgImage {
  position: absolute;
  right: 5px;
}

.sent_msg {
  float: right;
  width: 52%;
}

button.btn.btnBlue.accountEqualButton {
  padding-left: 0;
  padding-right: 0;
  min-width: 150px;
}

.outgoing_msg .sent_msg {
  float: right;
  width: 60%;
  right: 40px;
  position: relative;
}

.outgoing_msg .sent_msg p {
  float: right;
  width: 75%;
  margin-right: 3%;
  font-size: 14px;
  margin-left: 18%;
  padding: 10px;
}

.outgoing_msg .sent_msg .messageSendStatus {
  position: absolute;
  right: 0px;
  bottom: 0;
}

.outgoing_msg .sent_msg .time_date {
  right: unset;
  left: -5px;
}

/* 
.input_msg_write textarea {
  background-color: #f1f1f4;
  border: medium none;
  color: var(--darkColor-with-06-opacity);
  font-size: 14px;
  min-height: 48px;
  width: 100%;
  border-radius: 6px;
  padding: 0 55px 0 20px;
} */
.input_msg_write textarea {
  background-color: #f1f1f4;
  border: medium none;
  color: var(--darkColor-with-06-opacity);
  font-size: 14px;
  line-height: 1.4;
  min-height: 63px;
  width: 100%;
  border-radius: 6px;
  padding: 10px 55px 10px 20px;
}

.input_msg_write textarea::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox / */
.input_msg_write textarea {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.type_msg {
  /* border-top: 1px solid var(--chat-border-color); */
  position: relative;
}

.btn.facebookBtn:hover {
  /* background-color: var(--main-text-white-color) !important;
  color: var(--main-blue-color) !important;
  border: 1px solid var(--main-blue-color); */
  opacity: 0.7 !important;
}

.btn.gmailBtn:hover {
  /* background-color: var(--main-text-white-color) !important;
  color: var(--gmail-color) !important;
  border: 1px solid var(--gmail-color); */
  opacity: 0.7 !important;
}

.msg_send_btn:hover {
  /* background-color: var(--main-text-white-color) none repeat scroll 0 0 !important;
  color: var(--main-blue-color) !important;
  border: 1px solid var(--main-blue-color); */
  opacity: 0.7;
  /* background: var(--main-blue-color) none repeat scroll 0 0; */
}

.msg_send_btn {
  background: var(--main-blue-color) none repeat scroll 0 0;
  border: medium none;
  /* border-radius: 50%; */
  color: var(--main-text-white-color);
  cursor: pointer;
  font-size: 16px;
  height: 33px;
  position: absolute;
  right: 10px;
  top: 8px;
  /* width: 33px; */
}

.skypeArea {
  position: absolute;
  top: 9px;
  right: 7px;
  width: 30px;
  cursor: pointer;
}

.skypeArea img {
  float: left;
  width: 100%;
}

.reloadMessagesArea {
  float: left;
  margin-top: 10px;
}

.reloadMessagesArea button.btn.btnBlue {
  padding: 5px 10px;
}

.input_msg_write {
  float: right;
  /* width: calc(100% - 175px); */
  width: 100%;
}

.messageSendStatus {
  float: right;
  margin-top: 4px;
  margin-right: 10px;
  width: 100%;
  text-align: right;
}


.messageSendStatus .glyphicon-ok {
  color: green;
}

.messageSendStatus .glyphicon-alert {
  color: var(--red-color);
}

.messageSendStatus .glyphicon-eye-open {
  font-size: 12px;
  color: var(--main-blue-color);
}


.messaging {
  padding: 0 0 50px 0;
  float: left;
  width: 100%;
}

.msg_history {
  height: 427px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.msg_send_btn span {
  left: -4px;
  top: 2px;
}

.oppUserTopImageSec {
  float: left;
}

.oppUserTopInfoArea {
  float: left;
  width: calc(100% - 75px);
  margin-left: 15px;

}

.oppUserStatus {
  color: var(--darkColor-with-05-opacity);
  font-size: 10px;
  float: left;
  width: 100%;
}

.oppTopName {
  color: var(--black-color);
  font-size: 14px;
  cursor: pointer;
}

.oppUserNameInfoSec {
  float: left;
  width: 100%;
  position: relative;
  top: 5px;
}

.oppTopEmail {
  color: var(--darkColor-with-05-opacity);
  font-size: 12px;
  margin-left: 10px;
}

.oppUserSetting {
  float: right;
  font-size: 20px;
  color: var(--darkColor-with-05-opacity);
  margin-top: 2px;
}

.oppUserTopImage img {
  width: 30px;
  cursor: pointer;
  border-radius: 40px;
}

.chatIntroArea {
  float: left;
  width: 100%;
  padding: 265px 0;
}

.incoming_msg {
  margin: 20px 0;
  position: relative;
}

.chatIntroBox {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chatIntroArea img {
  margin: 20px 0;
  border-radius: 50%;
  width: 100px;
}

/* Chat Message Ends */

/* Multi Select css Start */

body .formField span.dropdown-btn {
  border: 0 !important;
}

body .formField.skillsDropdown {
  padding: 0px;
}

/* Multi Select css Ends */

/* Hire Coach css Starts */

img.hirePersonImage {
  float: left;
  margin-right: 15px;
  border-radius: 10px;
  margin-left: -5px;
  width: 90px;
}

span.hireSkillName {
  float: right;
  margin-top: 3px;
  margin-left: 5px;
}

.offerList .glyphicon {
  top: 2px;
}

body .offerList .greyBox,
body .proposalList .greyBox {
  padding: 20px 10px;
}

.dropdownBtns {
  position: absolute;
  border: 0;
  right: 9px;
  top: 1px;
  border-radius: 100%;
  box-shadow: 0 0 5px var(--dark-grey);
  text-align: center;
}

.nav-tabs.dropdownBtns>li>a {
  padding: 4px 6px;
  margin-top: 1px;
  text-align: center;
}

.nav-tabs.dropdownBtns li a {
  text-align: center;
}

body .nav-tabs.dropdownBtns>li>a:hover,
body .nav-tabs.dropdownBtns>li>a:focus,
body .nav-tabs.dropdownBtns>li>a:active {
  border-color: transparent !important;
  background-color: transparent !important;
}

select.offersDropdown,
body .offersKeyword {
  max-width: 100%;
  width: 250px;
  display: inline-block;
}

.contractHistorySec .panel-body,
.contractPaymentSec .panel-body {
  min-height: 195px;
}

select.offersDropdown {
  padding: 10px 20px !important;
  overflow-y: auto;
  top: 11px;
  height: 40px !important;
  display: inline-block !important;
  text-align: left;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border: 1px solid var(--darkColor-with-02-opacity);
  border-radius: 6px;
  font-size: 13px;
}

.row.blueBannerArea.coachSearchForm {
  /* float: left; */
  width: 100%;
  margin-top: 45px;
  padding: 0px;
  margin-left: 0;
}

.row.blueBannerArea.coachSearchForm>div {
  padding: 0px;
}

.offerList>div,
.proposalList>div {
  margin: 0px;
}

body .offersKeyword {
  margin: 30px 30px 20px;
}

.offerBtn {
  position: relative;
  top: -2px;
}

.contractHistorySec h3.fullWidth,
.contractPaymentSec h3.fullWidth {
  margin-bottom: 10px;
}

.coachInfo.proposalStatusInfo h4 {
  cursor: pointer !important;
}

/* Hire Coach css Ends */

/* Invoice Box Css Start */

.coacheeBtnSec {
  padding: 13px 15px !important;
}

.modal-body.bodyWithBorder {
  border-top: 1px solid #e5e5e5;
}

label.payOpionSec {
  width: 100%;
  display: inline-block;
}

label.payOpionSec input[type=radio] {
  width: 30px;
  float: left;
}

.paymentOptionPopup .modal-body {
  padding-left: 30px;
  padding-right: 30px;
}

.paymentOptionPopup p {
  margin-bottom: 0;
}

label.payOpionSec p {
  width: calc(100% - 50px);
  float: left;
  font-weight: normal;
}

.invoiceTableData div.noPaddingRight {
  padding-right: 0;
}

.form-control.invoiceInput {
  width: 70px;
  float: left;
}

span.invoiceCurrency {
  float: left;
  /* position: relative; */
  top: 8px;
  margin-right: 3px;
}

span.invoicePerHour {
  float: left;
  margin-left: 3px;
  position: relative;
  top: 8px;
}

span.invoiceTotalAmount {
  position: relative;
  top: 7px;
  font-weight: bold;
  font-size: 16px;
  color: var(--main-blue-color);
}

.contractHistorySec .historyData,
.contractPaymentSec .historyData {
  float: left;
  width: 100%;
  max-height: 128px;
  /* overflow-y: auto; */
  word-break: break-word;
}


.transactionHistoryData {
  max-height: 100px;
  overflow-y: auto;
  float: left;
  width: 100%;
  word-break: break-word;
}

/* Invoice Box css ends */

/* Invoice Page Css start */

.invoiceListHeading>div,
.notificationListHeading div {
  background-color: var(--main-text-white-color);
  /* border-top: 1.5px solid var(--main-text-black-color); */
  /* border-left: 1px solid var(--main-text-black-color); */
  border-bottom: 1px solid var(--grey-color);
  padding: 15px;
  font-weight: bold;
  color: var(--black-color);
  font-size: 14px;
}

/* .invoiceListHeading div:last-child,
.notificationListHeading div:last-child,
.rightBorderInvoice {
  border-right: 1.5px solid var(--main-text-black-color);
} */

.invoiceTableData div,
.notificationTableData div {
  /* border-left: 1px solid var(--main-text-black-color);
  border-bottom: 1px solid var(--main-text-black-color); */
  padding: 20px 15px;
  /* font-weight: bold; */
  font-size: 14px;
  word-break: break-word;
}

.invoiceTableData,
.notificationTableData,
.notificationListHeading,
.invoiceListHeading {
  display: flex;
}

/* .invoiceTableData div:last-child,
.notificationTableData div:last-child {
  border-right: 1.5px solid var(--main-text-black-color);
} */

.invoiceTableData:nth-child(even),
.notificationTableData:nth-child(even) {
  background-color: var(--main-text-white-color);
}

.invoiceTableData:nth-child(odd),
.notificationTableData:nth-child(odd) {
  background-color: var(--main-text-white-color);
}

.invoiceTableData:last-child,
.notificationTableData:last-child,
.invoiceTableData:last-child div,
.notificationTableData:last-child div {
  border-bottom: 0px solid transparent !important;
}

.invoiceListHeading>div:first-child,
.invoiceTableData>div:first-child,
.notificationTableData>div:first-child {
  border-left: 0px solid transparent !important;
}

.invoiceTableData a,
.notificationTableData a {
  color: var(--main-blue-color);
}

.dateViewer {
  position: relative;
  top: 1px;
  float: right;
  width: 80%;
}

.invoiceListTable>.row {
  /* box-shadow: 0 16px 40px 0 rgba(5, 5, 5, 0.1) !important; */
  -webkit-box-shadow: 2px 2px 0px #ddd !important;
  box-shadow: 2px 2px 0px var( --grey-color) !important;
  padding: 10px;
  background-color: var(--main-text-white-color);
  border-radius: 6px;
}

body .dateViewer .wc-date-container {
  border-radius: 6px;
  padding: 8px 20px;
  margin-left: 0;
  /* padding: 5px 10px;
  height: auto;
  border-color: var(--main-text-black-color); */
}

body .wc-date-popover {
  top: 40px;
  z-index: 1 !important;
}

/* body .dateViewer .wc-date-container>span {
  color: var(--main-text-black-color) !important;
} */

span.datePickerHeadingSec {
  position: relative;
  top: 10px;
  font-weight: bold;
}

.invoiceListTable {
  float: left;
  width: 100%;
  margin-top: -70px;
}

body .wc-details,
body .year-title,
body .selected-day {
  background-color: var(--main-blue-color) !important;
}

body .calendar-days,
body .cal-util>.ok {
  color: var(--main-blue-color) !important;
}

body .selected-day>span,
body .calendar-day:hover>span {
  background: var(--main-blue-color) !important;
  border-color: var(--main-blue-color) !important;
}

/* Invoice Page Css ends */


/* Notification Css Start */
.notificationBell {
  float: right;
  position: relative;
  top: 3px;
  right: 5px;
}

.notificationBell ul.dropdown-menu.dropdown-menu-left.pull-right {
  top: 60px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0 0 4px 4px;
}

a.dropdown-menu-header.notificationHeader {
  margin: 0;
  padding: 10px 15px;
}

.timeline {
  list-style: none;
  padding-left: 0;
  position: relative;
}

.timeline:after {
  content: "";
  height: auto;
  width: 1px;
  background: var(--light-white-color-4);
  position: absolute;
  top: 12px;
  left: 30px;
  bottom: 25px;
}

.timeline.timeline-sm:after {
  left: 12px;
}

.timeline li {
  position: relative;
  padding-left: 70px;
  margin-bottom: 20px;
}

/* .timeline li:after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--light-white-color-4);
  position: absolute;
  left: 24px;
  top: 5px;
} */
.timeline li .timeline-date {
  display: inline-block;
  width: 100%;
  color: #a6a6a6;
  font-style: italic;
  font-size: 13px;
}

.timeline.timeline-icons li {
  padding-top: 7px;
}

.timeline.timeline-icons li:after {
  width: 32px;
  height: 32px;
  background: var(--main-text-white-color);
  border: 1px solid var(--light-white-color-4);
  left: 14px;
  top: 5px;
  z-index: 11;
}

.timeline.timeline-icons li .timeline-icon {
  position: absolute;
  left: 23.5px;
  top: 7px;
  z-index: 12;
}

.timeline.timeline-icons li .timeline-icon [class*=glyphicon] {
  top: -1px !important;
}

.timeline.timeline-icons.timeline-sm li {
  padding-left: 0px;
  margin-bottom: 5px;
  display: inline-block;
  margin-top: 5px;
}


.notificationImgSec,
.notificationTextSec {
  display: table-cell;
  vertical-align: middle;
}

.notificationTextSec {
  width: calc(100% - 40px);
}

.notificationImgSec {
  width: 40px;
}

.timeline.timeline-icons.timeline-sm li:after {
  left: -5px;
}

.timeline.timeline-icons.timeline-sm li .timeline-icon {
  left: 4.5px;
}

.timeline.timeline-advanced li {
  padding-top: 0;
}

.timeline.timeline-advanced li:after {
  background: var(--main-text-white-color);
  border: 1px solid #29b6d8;
}

.timeline.timeline-advanced li:before {
  content: "";
  width: 52px;
  height: 52px;
  border: 10px solid var(--main-text-white-color);
  position: absolute;
  left: 4px;
  top: -10px;
  border-radius: 50%;
  z-index: 12;
}

.timeline.timeline-advanced li .timeline-icon {
  color: #29b6d8;
}

.timeline.timeline-advanced li .timeline-date {
  width: 75px;
  position: absolute;
  right: 5px;
  top: 3px;
  text-align: right;
}

.timeline.timeline-advanced li .timeline-title {
  font-size: 17px;
  margin-bottom: 0;
  padding-top: 5px;
  font-weight: bold;
}

.timeline.timeline-advanced li .timeline-subtitle {
  display: inline-block;
  width: 100%;
  color: #a6a6a6;
}

.timeline.timeline-advanced li .timeline-content {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 70px;
}

.timeline.timeline-advanced li .timeline-content p {
  margin-bottom: 3px;
}

.timeline.timeline-advanced li .timeline-content .divider-dashed {
  padding-top: 0px;
  margin-bottom: 7px;
  width: 200px;
}

.timeline.timeline-advanced li .timeline-user {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.timeline.timeline-advanced li .timeline-user:before,
.timeline.timeline-advanced li .timeline-user:after {
  content: " ";
  display: table;
}

.timeline.timeline-advanced li .timeline-user:after {
  clear: both;
}

.timeline.timeline-advanced li .timeline-user .timeline-avatar {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 10px;
}

.timeline.timeline-advanced li .timeline-user .timeline-user-name {
  font-weight: bold;
  margin-bottom: 0;
}

.timeline.timeline-advanced li .timeline-user .timeline-user-subtitle {
  color: #a6a6a6;
  margin-top: -4px;
  margin-bottom: 0;
}

.timeline.timeline-advanced li .timeline-link {
  margin-left: 5px;
  display: inline-block;
}

.timeline-load-more-btn {
  margin-left: 70px;
}

.timeline-load-more-btn i {
  margin-right: 5px;
}

.notificationBell .timeline.timeline-icons.timeline-sm p {
  word-break: break-word;
}

/* -----------------------------------------
   Dropdown
----------------------------------------- */
.dropdown-menu {
  padding: 0 0 0 0;
  z-index: 9999999;
}

a.dropdown-menu-header {
  background: #f7f9fe;
  font-weight: bold;
  border-bottom: 1px solid var(--light-white-color-4);
}

.dropdown-menu>li a {
  padding: 10px 20px;
}

/* -----------------------------------------
   Badge
----------------------------------------- */

.notificationBell .badge {
  padding: 8px 0px;
  position: absolute;
  top: 8px;
  right: 8px;
  display: inline-block;
  min-width: 15px;
  font-size: 10px;
  font-weight: bold;
  color: var(--main-text-white-color);
  line-height: 0;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: var(--red-color);
}

.badge-danger {
  background-color: #db5565;
}

.timeline a {
  margin: 0;
  padding: 0;
  word-break: break-word;
}

a.readNotification {
  opacity: 0.6;
}

img.notifyUserImage {
  position: relative;
  left: -2px;
  width: 30px;
  z-index: 99;
  border-radius: 50%;
}

span.noProfileImageHeader.notifyUserName {
  position: relative;
  left: -2px;
  width: 30px;
  z-index: 99;
  padding: 4px 0;
  margin-left: 0px;
}

.notificationBar {
  position: relative;
  width: 90%;
  /* max-width: 650px; */
  text-align: center;
  padding: 5px 20px;
  z-index: 9;
  border-radius: 6px;
  margin-top: 10px;
  /* opacity: 0.5; */
  top: 1px;
}

span.emailSendBtn {
  position: relative;
  top: 6px;
}

.expertProfileBar {
  position: relative;
  top: 1px;
}

.notificationBar span {
  /* float: right; */
  /* cursor: pointer; */
  color: var(--main-text-white-color);
}

.notificationBar.showBar {
  display: inline-block;
}

a.readNotification,
a.unreadNotification,
.notificationBell a,
a.readNotification:hover,
a.unreadNotification:hover,
.notificationBell a:hover,
.notificationHeader,
.notificationHeader:hover {
  background-color: transparent;
}

a.readNotification:hover,
a.unreadNotification:hover,
.notificationBell a:hover {
  color: var(--main-blue-color);
  text-decoration: none;
}

.notificationBell ngx-loading .full-screen {
  position: absolute;
}

.messageMenuLink {
  position: relative;
}

a.messageMenuLink.unreadBadge:after {
  top: 20px;
  left: unset;
  right: -5px;
}

a.dropdown-menu-header.notificationHeader:hover {
  background-color: transparent;
}

/* Notification Css Ends */


/* Settings Page Css Start */
.settingHome {
  background-color: var(--main-text-white-color);
  box-shadow: 0px 0px 5px #bbb;
  border-radius: 2px;
}

.settingRowHeading {
  background-color: #f1f1f1;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 15px;
}

.settingRow {
  padding: 10px 15px;
  border-top: 1px solid var(--grey-color);
}

.cardPanel {
  box-shadow: 0 0 5px var(--dark-grey);
}

.cardPanelHeading {
  padding: 0 15px 15px 15px;
  border-bottom: 1px solid var(--grey-color);
}


label.cardSec {
  /* float: left; */
  display: inline-block;
  width: 450px;
  text-align: center;
  box-shadow: 0 0 3px var(--dark-grey);
  padding: 15px;
  cursor: pointer;
  border-bottom: 5px solid var(--dark-grey);
  margin-bottom: 0px;
  max-width: 100%;
  opacity: 0.5;
}

/* .switch {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 32px;
  float: left;
  margin-bottom: 0px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--main-blue-color);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: var(--main-text-white-color);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--main-blue-color);
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(88px);
  -ms-transform: translateX(88px);
  transform: translateX(88px);
} */

/*------ ADDED CSS ---------*/
/* .on {
  display: none;
}

.on,
.off {
  position: absolute;
  color: var(--main-text-white-color);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 14px;
}

input:checked+.slider .on {
  display: block;
}

input:checked+.slider .off {
  display: none;
} */


/*--------- END --------*/

/* Rounded sliders */
/* .slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} */

.switchUserImage {
  width: 25px;
}

span.settingTextMiddle {
  margin-top: 5px;
  float: left;
}

.userTypeRadio.activeCard+label>h3 {
  color: var(--main-blue-color);
}

.userTypeRadio.activeCard+label {
  border-bottom: 5px solid var(--main-blue-color);
  pointer-events: none;
  opacity: 1;
}

.updateUserBtnSec {
  float: right;
  margin-left: 10px;
}

/* Setting Page Css Ends */


/* Rating Model Css Start */

.popupRating {
  margin-top: 4%;
  /* border: 1px #999 solid; */
  display: flex;
  font-size: 0;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  background: linear-gradient(to right, #D3D3D3, #9E9E9E, #696969);
  margin-bottom: 10px;
}

.popupRating input {
  display: none
}

.popupRating label {
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  background: var(--main-text-white-color);
  color: var( --main-text-black-color);
  margin-bottom: 0;
  padding: 5px 0;
  cursor: pointer;
}

.popupRating label {
  transition: .3s;
  /* border-left: 1px solid #999; */
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}

.popupRating input[type='radio']:hover~label,
.popupRating input[type='radio']:checked~label {
  background: transparent !important;
  color: var(--main-text-white-color);
}

.btn.disableBtn {
  pointer-events: none;
}

label.lastLabel {
  border-radius: 20px 0 0 20px;
  border-left: 1px solid #999 !important;
}

label.firstLabel {
  /* border-right: 1px solid #999 !important; */
  border-radius: 0 20px 20px 0;
}

.ratingSecSubHeading {
  float: left;
  width: 60%;
}

.fiveStarRatingSec {
  float: right;
  width: 40%;
}

.ratingSecSubHeading span {
  margin-top: 20px;
  float: left;
  font-size: 14px;
  margin-left: 15px;
}

.questionsText .starRatingBottomText {
  width: 40%;
  float: right;
}

.fullRatingSec .popupRating,
.fiveStarRatingSec .popupRating {
  width: 100%;
  border-radius: 20px;
  position: relative;
  z-index: 99999;
}

.fullRatingSec .popupRating label {
  width: 10%;
}

.fiveStarRatingSec .popupRating label {
  width: 20%;
}

.fullRatingSec .popupRating label:first-child {
  border-right: 1px #999 solid;
}

.ratingBtnSec li {
  display: inline-block;
  text-align: center;
}

.ratingBarArea.reviewRatingSec span.ratingSecHeading {
  font-size: 16px !important;
  float: left;
  width: 130px;
  position: relative;
  top: 0px;
}

.commentsSec .ratingBarArea.reviewRatingSec span.ratingSecHeading {
  width: 115px;
}

ul.ratingBtnSec {
  display: grid;
  width: 100%;
  margin-top: 15px;
  float: left;
  grid-template-columns: repeat(11, 1fr);
}

ul.ratingBtnSec.starRatingRadio {
  grid-template-columns: repeat(5, 1fr);
}

.contractPaymentSec .col-xs-4.noPadding {
  word-break: break-word;
}

span.questionCount {
  float: left;
  font-size: 18px;
  width: 100%;
  text-align: center;
}

.questionsText {
  float: left;
  width: 100%;
  position: relative;
  top: -5px;
  margin-bottom: 10px;
}

.poorTextColor {
  color: var(--main-blue-color);
  /* font-weight: bold; */
}

.excellentTextColor {
  color: var(--main-blue-color);
  /* font-weight: bold; */
}

.ratingBox h3 {
  font-size: 20px;
}

.ratingBox .modal-dialog {
  width: 850px;
  max-width: 95%;
}

.questionAnswerRatingArea h5 {
  font-size: 14px;
}

.ratingBox textarea.form-control {
  min-height: 150px;
}

.npsDetract {
  float: left;
  width: 60%;
  height: 5px;
  background-color: #ee0009;
}

.npsPassive {
  float: left;
  width: 20%;
  height: 5px;
  background-color: #fa8012;
}

.npsPromote {
  float: left;
  width: 20%;
  height: 5px;
  background-color: #00ff15;
}

.npsSec {
  float: left;
  width: 100%;
  height: 5px;
  position: relative;
  top: -10px;
}

/* Rating Model Css Ends */

/* Rating Stars Css Start */
/* Dont Change colors into variable its not working for ratings */
.ratings {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  color: var(--main-text-white-color);
  /* overflow: hidden; */
  left: 0px;
}

/* Dont Change colors into variable its not working for ratings */
.full-stars {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: var( --star-color);
}

/* Dont Change colors into variable its not working for ratings */
.empty-stars:before,
.full-stars:before {
  content: "\e006\e006\e006\e006\e006";
  font-size: 16px;
  font-family: 'Glyphicons Halflings';
  letter-spacing: 5px;
  margin-left: 4px;
  position: relative;
  top: -2px;
}

/* Dont Change colors into variable its not working for ratings */
.empty-stars:before {
  -webkit-text-stroke: 1px var( --star-color);
}

/* Dont Change colors into variable its not working for ratings */
.full-stars:before {
  -webkit-text-stroke: 1px var( --star-color);
}

/* Webkit-text-stroke is not supported on firefox or IE */
/* Firefox */
/* Dont Change colors into variable its not working for ratings */
@-moz-document url-prefix() {
  .full-stars {
    color: var( --star-color);
  }
}

/* Rating Stars Css Ends */

/* Home Page Start */

.followBtnSec button.btn {
  min-width: 170px;
}

.followBtnSec {
  margin-top: 5px;
}

.homeSkillsSec {
  margin-top: 39px;
  padding-left: 30px !important;
}

.fullWidth.homePackageSec {
  margin-top: 10px;
  margin-bottom: 10px;
}

.reviewAndRating.homeRating .full-stars:before,
.reviewAndRating.homeRating .empty-stars:before {
  margin-left: 0px;
}

.homeExpertiseSec {
  float: left;
  width: 90%;
}

.homeDesc {
  max-height: 78px;
  overflow-y: hidden;
}

.btnsSec {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.homeRating {
  margin-top: 5px;
  margin-bottom: 5px;
}

.userDataSec {
  float: left;
  width: 100%;
  height: 410px;
  position: relative;
  /* background-image: url('./assets/images/userBg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.toggleArea:nth-child(1) {
  margin-top: 0;
}

.whiteBoxSec {
  background-color: rgba(225, 225, 225, 0.7);
  position: relative;
  padding: 30px 20px 30px;
  border-radius: 6px;
}

/* .featuredCoachSec .homeCoachesList {
  top: 30px;
} */

/* .featuredCoachSec {
  margin-bottom: 2em;
} */

.fullHomeArea {
  float: left;
  width: 100%;
}

app-home .fullHomeArea {
  background-color: var( --light-bg-color);
}

.coachInvDesc {
  max-height: 64px !important;
  overflow-y: auto !important;
}

.expertiseCoachSec .singleCoachBox {
  margin: 15px;
}

.singleUserDesc span {
  word-break: break-word;
}

.expertiseCoachSec {
  float: left;
  width: 100%;
  margin-bottom: 100px;
}

.userDataSec p {
  margin-bottom: 0px;
}

a.btn.blueBtn.equalSizeBtn.marginTop1em.pendingContract {
  padding: 8px 15px 8px !important;
}

body div .btn.sessionBtn {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.whiteOverlay {
  background-image: linear-gradient(to bottom, rgba(227, 233, 237, 0.7), rgba(227, 233, 237, 0.9));
  position: absolute;
  height: 100%;
  width: 100%;
}

.btn.msgBtnSec,
.btn.msgBtnSec:hover {
  border-radius: 6px;
  background-color: var(--main-blue-color);
  color: var(--main-text-white-color);
  padding: 12px 30px;
  font-size: 12px;
}

body .btn.homeSlideBtn,
body .btn.homeSlideBtn:hover {
  border-radius: 6px;
  background-color: var(--main-blue-color);
  color: var(--main-text-white-color);
  padding: 12px 15px;
  font-size: 14px;
  float: right;
  min-width: 200px;
}

.btn.unfollowBtn {
  border-radius: 6px;
  background-color: var(--gmail-color);
  color: var(--main-text-white-color) !important;
  padding: 12px 30px;
  font-size: 12px;
}

.btn.msgBtnSec:hover,
body .btn.homeSlideBtn:hover,
.btn.unfollowBtn:hover {
  opacity: 0.7;
}

.homeSkillArea .userDataSec {
  margin-top: 3em;
}

.userDataSec h2 {
  font-weight: bold;
  color: var(--main-text-black-color);
}

.userDataSec div {
  font-size: 14px;
  /* color: var(--main-text-black-color); */
}

.userDataSec div b {
  /* color: var(--main-text-black-color); */
  font-size: 14px;
}

.userDataSec a {
  color: var(--main-blue-color);
}

.userDataSec .reviewAndRating a {
  float: left;
  margin-right: 10px;
  color: var(--main-text-black-color);
  /* text-decoration: underline; */
  /* font-weight: bold; */
  font-size: 14px;
  margin-top: 1px;
}

.reviewAndRating.homeRating .ratingArea {
  float: left;
  margin-right: 10px;
}

.ratingPart span.glyphicon.glyphicon-star {
  color: var(--gmail-color);
}

.modal-open .modal .removeHelpIcon {
  max-height: calc(100% - 100px);
  overflow-y: auto;
}

.userCategorySec {
  font-size: 14px;
}

.homeCoachesList {
  float: left;
  width: 96%;
  position: relative;
  margin-left: 2%;
  /* top: -5px; */
}

.toggleArea {
  float: left;
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  transition: max-height 0.5s;
  margin-top: 75px;
  margin-bottom: -120px;
}

.toggleArea.showToggle {
  max-height: 600px !important;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  transition: max-height 0.5s;
  overflow: visible !important;
}

.fullGreySec {
  background-color: var(--main-background-color);
}

.homeCoachesList h4 {
  color: var(--darkColor-with-05-opacity);
  font-size: 12px;
}

.homeCoachesList h3 {
  color: var(--main-text-black-color);
  font-size: 16px;
  font-weight: bold;
}

.singleUserImage img {
  float: left;
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.singleUserImage .noProfileImageSec {
  margin: 0;
  border-radius: 10px;
}

.singleCoachBox {
  float: left;
  width: 100%;
  background-color: var(--main-text-white-color);
  padding: 20px;
  cursor: pointer;
  border-radius: 6px;
  max-width: 346px;
  min-height: 215px;
  position: relative;
  box-shadow: 2px 2px 0px var( --grey-color);
}

.fullProfileSec {
  position: relative;
  z-index: 99;
  color: var(--main-blue-color);
  font-size: 11px !important;
  text-transform: uppercase;
}

swiper>.swiper.s-wrapper .swiper-wrapper .swiper-slide {
  text-align: center;
  /* overflow: hidden !important; */
  padding: 10px 0;
}

.swiper-wrapper .swiper-slide .singleCoachBox{
  float: none;
  display: inline-block;
  text-align: left;
}

/* .swiper-slide {
  max-width: 380px !important;
} */

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 18px;
  color: #000;
}

body .swiper-button-next:after {
  content: "\f105";
  font: normal normal normal 30px/1 FontAwesome !important;
  position: relative;
  left: 7px;
}

body .swiper-button-prev:after {
  content: "\f104";
  font: normal normal normal 30px/1 FontAwesome !important;
  position: relative;
  left: 10px;
}

body .swiper-button-next, body .swiper-button-prev {
  background: unset;
}

body .swiper-button-next {
  right: -9px;
}

body .swiper-button-prev {
  left: -9px;
}

swiper>.swiper.s-wrapper .swiper-pagination {
  display: none;
}

.slick-initialized .slick-slide {
  margin: 0 15px;
  float: left;
}

.slick-arrow {
  position: absolute;
  background-color: transparent;
  font-size: 40px;
  color: var(--main-text-black-color);
  padding: 0;
  opacity: 0.7;
  z-index: 999999;
}

.slick-arrow:hover {
  background-color: transparent;
  opacity: 1;
}

.slick-arrow.slick-prev {
  top: 50%;
  transform: translateY(-50%);
  left: -15px;
}

.slick-arrow.slick-next {
  top: 50%;
  transform: translateY(-50%);
  right: -15px;
}

p.singleUserDesc {
  padding: 10px 0 15px;
  margin-bottom: 0;
  font-size: 14px;
}

.row.singleUserRatingArea>div {
  font-size: 12px;
}

.row.singleUserRatingArea {
  opacity: 0.6;
}

.singleUserRatingArea {
  margin-top: 0px;
}

.singleCoachBox div {
  color: #000;
  font-size: 14px;
  /* font-family: 'Poppins', sans-serif !important; */
}

/* .singleCoachBox h3{
  font-family: 'Poppins', sans-serif !important;
} */

.singleCoachBox .singleUserImage {
  position: relative;
}

.singleCoachBox .singleUserImage .fa {
  position: absolute;
  background-color: var(--main-blue-color);
  color: var(--main-text-white-color);
  padding: 4px;
  text-align: center;
  top: 65px;
  right: 20px;
  border-radius: 100%;
  font-size: 16px;
  font-weight: lighter;
}

body .singleUserRatingSec {
  min-height: 35px;
  border-left: 1px solid var( --grey-color);
}

.singleCoachBox .singleUserRatingSec .ratings {
  left: 50%;
  transform: translateX(-50%);
}

.slick-initialized .slick-slide {
  padding: 10px 0px 15px;
}

.singleCoachBox.active,
.singleCoachBox:hover {
  box-shadow: 0 0 10px var(--dark-grey);
}

.singleCoachBox div b {
  color: var(--main-text-black-color);
}

.row.singleCardBottomSec {
  margin-top: 20px;
  position: absolute;
  width: calc(100% - 0px);
  bottom: 10px;
  left: 15px;
}

.col-xs-6.singleMessageSec.text-center {
  border-right: 1px solid var( --grey-color);
  min-height: 35px;
}

.row.singleCardBottomSec p {
  margin: 0px;
}

.singleMessageSec .msgSpan {
  color: var(--main-blue-color) !important;
  font-weight: bold;
}

.singleMessageSec .msgSpan img {
  display: inline-block;
  margin-right: 5px;
}

.singleMessageSec,
.singleUserRatingSec {
  padding-top: 5px;
  padding-bottom: 0px;
}

/* 
.singleMessageSec {
  border-right: 1px solid var( --grey-color);
} */

.slick-slide .singleMessageSec img {
  display: inline-block;
}

.singleCoachBox h3 {
  margin-top: 7px;
  margin-bottom: 5px;
}

.headingLeftMargin {
  margin-left: 50px;
}

/* .singleUserRatingSec .ratings {
  float: right;
} */

.singleUserRatingSec .ratings .full-stars,
.reviewAndRating .ratings .full-stars {
  color: var(--gmail-color);
}

.singleUserRatingSec .ratings .empty-stars,
.reviewAndRating .ratings .empty-stars {
  color: var(--main-text-white-color);
}

.slick-track {
  float: left;
}

.mainBannerImage {
  height: 410px;
  width: 100%;
  float: left;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  opacity: 0.8;
  background-image: url('./assets/images/userBg.jpg');
}

.homeSkillArea.currentUpperRow>div:first-child .userDataSec,
.toggleArea.showToggle.currentUpperRow {
  margin-top: 0;
}

/* Home Page Ends */

/* Login Page Start */

.panelSetting {
  /* background-color: var(--main-text-white-color); */
  background-color: var( --light-bg-color);
  padding: 20px 0;
  margin-bottom: 20px !important;
  /* -webkit-box-shadow: 0 16px 40px 0 rgba(5, 5, 5, 0.1) !important;
  box-shadow: 0 16px 40px 0 rgba(5, 5, 5, 0.1) !important; */
  -webkit-box-shadow: 2px 2px 0px #ddd !important;
  box-shadow: 2px 2px 0px var( --grey-color) !important;
  border-radius: 6px !important;
  border: none !important;
  z-index: 99;
  position: relative;
}

.panelSetting .inputWithIcon .inputBox .form-control {
  display: inline-block;
  max-width: 100%;
  height: 40px;
  font-size: 13px;
  padding: 10px 30px 10px 20px;
  border: 1px solid var(--darkColor-with-02-opacity);
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  z-index: 9999;
}

.agreeArea label {
  float: left;
  width: calc(100% - 20px);
  margin-top: 2px;
  font-weight: normal;
  font-size: 13px;
  text-align: left;
}

.agreeArea input {
  float: left;
  width: 20px;
}

/* Login page Ends */

/* all notification page start */
.notificationTable {
  float: left;
  width: 100%;
  margin-top: 4em;
  margin-bottom: 1em;
  position: relative;
}

.notificationProfileImageSec span.noProfileImageHeader.notifyUserName {
  position: static;
  width: 40px;
  border-radius: 5px;
  padding: 9px 0;
  float: left;
  cursor: pointer;
}

.notificationProfileImageSec .notificationImageSec {
  border: none !important;
  padding: 0;
  float: left;
}

.notificationProfileImageSec img.notifyUserImage {
  position: static;
  width: 40px;
  border-radius: 5px;
  cursor: pointer;
}

.notificationTable a.readNotification {
  opacity: 1;
}

.notificationProfileImageSec {
  padding: 12px 15px !important;
}

.notificationListHeading div,
.notificationTableData div,
.notificationTableData:last-child {
  border-color: #bbb !important;
}

/* .notificationListHeading div:last-child, .notificationTableData div:last-child {
  border-right-color: var(--main-background-color) !important;
} */

/* .optionsSetting .btn.orangeBtn:hover {
  color: var(--gmail-color) !important;
} */

.contractModalInfo .coachInfoDesc {
  max-height: 24px;
  overflow-y: auto;
}

.offerProfileImageSec {
  float: left;
  width: 100px;
}

body .offerReadMore {
  color: var(--darkColor-with-06-opacity) !important;
}

.proposalStatusInfo h4 {
  margin-bottom: 3px;
}

body .notificationListHeading div {
  background-color: var(--main-text-white-color);
}

body .notificationListHeading>div:first-child {
  border-left: 0px solid transparent;
}

.notificationTable>.row {
  padding: 10px;
  background-color: var(--main-text-white-color);
  /* box-shadow: 0 16px 40px 0 rgba(5, 5, 5, 0.1) !important; */
  -webkit-box-shadow: 2px 2px 0px #ddd !important;
  box-shadow: 2px 2px 0px var( --grey-color) !important;
  border-radius: 6px;
}

/* rates input field  */

.form-control.inputField {
  width: 100px;
}

.form-control.colWidth {
  width: 50px;
  padding: 15px 10px;
}

body .modal-content .colHeight {
  height: 500px !important;
}

.orangePackageBtn {
  color: var(--main-text-orange-color) !important;
}

.bluePackageBtn {
  color: var(--main-blue-color) !important;
}

button.btn.trashButton {
  background-color: var(--red-color);
  color: var(--main-text-white-color);
}

button.btn.trashButton:hover {
  opacity: 0.7 !important;
  /* color: var(--red-color);
  background-color: var(--main-text-white-color);
  border-color: var(--red-color); */
}

.packagesSec .packageRow {
  margin-top: 5px;
  float: left;
  width: 100%;
  margin-bottom: 5px;
}

/* all notification page ends */

/*  modal css of without token*/
.modal .modal-dialog.colWidth {
  /* width: 350px; */
  width: 535px;

}

span.OrSection {
  float: left;
  width: 100%;
  margin: 40px 0px;
  font-size: 18px;
  position: relative;
}

span.OrSection:after {
  position: absolute;
  content: "";
  width: 150px;
  background-color: var( --dark-grey);
  height: 1px;
  top: 10px;
  right: 65px;
}

span.OrSection:before {
  position: absolute;
  content: "";
  width: 150px;
  background-color: var( --dark-grey);
  height: 1px;
  top: 10px;
  left: 65px;
}

.mobileViewHeader {
  display: none;
}

.otherSkypeInfo p {
  margin: 0 10px 10px;
}

/* p.lightColorSec.Package {
  color: black;
} */

.hireCoachModalSec {
  padding: 0 15px;
}

/* Settings Page Start */
.settingsMenuSec .nav-tabs {
  border: none;
  margin: 0;
}

.settingsMenuSec .nav-tabs>li {
  margin: 0 0 5px 0;
  width: 100%;
  border-left: 3px solid transparent;
  float: left;
}

.uncompleteProfile {
  padding: 0 20px;
}

.modalTopBorder {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.borderNone {
  border: unset !important;
}

.settingsMenuSec .nav-tabs>li>a {
  margin: 0px;
  border: none !important;
  float: left;
  width: 100%;
  color: var(--black-color);
  border-radius: 0px;
}

.settingsMenuSec .nav.nav-tabs a i {
  width: 25px;
  text-align: center;
}

/* .settingsMenuSec .nav-tabs>li.active>a {
  color: var(--main-blue-color);
} */

.settingsMenuSec .nav-tabs>li.active>a,
.settingsMenuSec .nav>li.active>a:focus,
.settingsMenuSec .nav>li.active>a:hover {
  text-decoration: none;
  background-color: var( --light-white-color-1);
}

.settingsMenuSec .nav-tabs>li.active,
.settingsMenuSec .nav-tabs>li:hover,
.settingsMenuSec .nav-tabs>li:focus,
.settingsMenuSec .nav>li.active:focus,
.settingsMenuSec .nav>li.active:hover {
  border-left: 3px solid var( --main-text-black-color);
}

.settingMainTab .container {
  float: left;
  width: 100%;
}

.settingMainTab .bannerAdded.coachMainInfoSec {
  margin-top: 0;
}

.coachDesc.userSetting {
  width: 70%;
}

input.form-control.inputPassword {
  padding: 10px 30px 10px 20px;
  height: 40px;
  font-size: 13px;
}

a.pull-left.updatePicsSec {
  float: left;
  margin-left: 15px;
  margin-top: -2px;
  color: var(--main-blue-color);
}

a.pull-left.updatePicsSec:hover {
  text-decoration: underline;
}

.profileImageModal,
.bannerImageModal,
.skypeModal,
.popupBg {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  transform: 0.5s ease;
  overflow: scroll;
}

/* .profileImageModal .modal-content, .bannerImageModal .modal-content {
  max-height: 90vh;
} */

/* .profileImageModal span.resize-bar,
.profileImageModal span.resize {
  display: none !important;
} */

.bannerImageModal .modal-dialog,
.profileImageModal .modal-dialog {
  width: 800px
}

app-settings app-coach-profile .panelSetting,
app-settings app-koachee-profile .panelSetting {
  z-index: unset;
  padding: 0;
}



/* nav a {
  background-color: none !important;

} */
.coachDetailPanel h4 {
  margin-bottom: 5px;
}

.coachDetailPanel p {
  margin-bottom: 0px;
  word-break: break-word;
}

.showCursor {
  cursor: pointer;
}

.noCursor {
  text-decoration: none !important;
  cursor: auto;
}

.cursorUnset {
  cursor: unset;
}

app-settings app-koachee-profile .content,
app-settings app-coach-profile .content,
app-settings app-account .content {
  min-height: auto;
}

app-settings app-koachee-profile .content>.container,
app-settings app-coach-profile .content>.container,
app-settings app-account .content>.container {
  margin-bottom: 0px;
}

.packageFormRows input.form-control {
  float: left;
  width: 100%;
  margin-right: 15px;
  max-width: 88px;
}

span.dollarSym {
  float: left;
  position: relative;
  top: 5px;
}

span.sessionText {
  float: left;
  position: relative;
  top: 5px;
  margin-left: 15px;
}

/* Settings Page Ends */

.forHeading {
  margin-right: 20px;
  position: relative;
  top: 5px;
  float: left;
  margin-left: 20px;
}


.singlePackage {
  position: relative;
  top: 5px;
  float: left;
}

.deletePackage {
  position: relative;
  top: 5px;
  color: #FF9C4C !important;
  font-weight: bold;
  font-size: 12px;
  margin-left: 25px;
}

.ratedSec {
  margin-bottom: 10px;
}

image-cropper>div {
  max-height: 50vh;
}

/* image-cropper {
  max-height: 50vh !important;
  display: block !important;
  overflow-y: auto !important;
} */

.publishProfile ul {
  padding: 0 50px;
}

.publishProfile li {
  padding: 5px 0;
  font-size: 14px;
  list-style: disc;
}

.publishProfile i.fa.fa-caret-right.publishIcon,
.completeArea i.fa.fa-caret-right.publishIcon {
  margin-right: 5px;
}

.completeArea ul {
  padding: 0 30px;
}

.completeArea li {
  padding: 5px 0;
  font-size: 16px;
}

.completeArea a {
  padding: 0 5px;
}

.panelSetting.completeHeading {
  font-size: 22px !important;
}

.completeArea a.greenClass {
  color: #3abf19;
  text-decoration: none;
  /* font-weight: 550; */
}

h3 .completeHeading {
  font-weight: 800;
}

.skipButton {
  margin-right: 20px;
}

.locationSearchSec {
  display: inline-block;
  width: 250px;
  padding: 10px;
  border: 1px solid var(--darkColor-with-02-opacity);
  border-radius: 6px;
  color: #333;
  font-size: 13px;
  background-image: url("../src/assets/images/downArrow.png");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 95%;
  margin: 0 -3px;
}

.locationSearchSec {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
.locationSearchSec::-ms-expand {
  display: none;
}

.shareLink {
  width: 150px;
  margin-top: 1em;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 12px !important;
}

.facebookIcon {
  display: inline-block;
  background-color: #3b5998;
  padding: 12px 18px;
  border-radius: 40px;
  font-size: 30px;
  color: var(--main-text-white-color);
}


.shareCoachProfileLink {
  font-size: 20px;
}

.linkedIn {
  background-color: #0e76a8;
  padding: 12px 14px;
  border-radius: 40px;
  font-size: 30px;
  color: var(--main-text-white-color);
}

.twitterIcon {
  background-color: #00acee;
  padding: 12px 14px;
  border-radius: 34px;
  color: var(--main-text-white-color);
  font-size: 30px;
}

.emailIcon {
  background-color: #999;
  padding: 12px 14px;
  border-radius: 40px;
  float: left;
}

.emailIcon i {
  color: var(--main-text-white-color);
  font-size: 30px;
}

.shareIcons li {
  display: inline-block;
  margin: 0 12px;
  vertical-align: top;
}

.iconsList ul {
  text-align: center;
}


.copyInput {
  position: relative;
}

.copySpan {
  position: absolute;
  float: right;
  right: 20px;
  top: 8px;
  color: #1b1bcf;
  /* font-weight: 400; */
  font-size: 16px;
  cursor: pointer;
  z-index: 99;
}

.copyInput input[type="text"] {
  width: 100%;
  padding: 10px 75px 10px 20px;
  background-color: #f5f5f5
}

.sharelinkBtn {
  color: var(--gmail-color) !important;
  background-color: none !important;
  font-size: 18px;
  margin-right: 20px;
  background-color: none !important;
  float: right;
}

.linkBtn {
  color: var(--gmail-color) !important;
  background-color: none !important;
  font-size: 14px;
  margin-right: 20px;
  background-color: none !important;
}

.removeImageBtn {
  color: var(--gmail-color) !important;
  float: right !important;
  margin-right: 15px;
}

.copyInputField {
  padding: 5px 70px 5px 20px;
  border-radius: 0 6px 6px 0 !important;
}

.shareFooter {
  /* border: none; */
  padding-right: 45px;
  /* border-top: none !important; */
}

.sharePanel {
  box-shadow: unset !important;
  margin-bottom: 0px !important;
}

.shareClose {
  font-size: 30px;
}

/* share-button.sb-button.whatsapp{
  display: none;
} */

.socialButton button {
  padding: 0px;
  background-color: transparent
}

.unfollowButton {
  background-color: var(--gmail-color) !important;
  padding: 0px !important;
  margin-top: 10px;
}

button.btn.blueBtn.unfollowButton.shareLink:hover,
button.btn.blueBtn.unfollowButton.shareLink:focus,
button.btn.blueBtn.unfollowButton.shareLink:active {
  opacity: 0.7 !important;
  /* background-color: var(--main-text-white-color) !important;
  color: var(--gmail-color) !important;
  border-color: var(--gmail-color); */
  padding: 0px;
  margin-top: 5px;
}

.marginTop20px {
  margin-top: 20px;
}

.coachProfileLinkSec {
  text-align: center;
}

.coachProfileLinkSec li {
  display: inline-block;
  margin: 0px 8px;
  position: relative;
  top: 3px;
}

.coachProfileLinkSec li a {
  font-size: 40px;
  color: var(--main-text-black-color);

}

ul.coachProfileLinkSec {
  margin-bottom: 0px;
  float: left;
  width: 100%;
}

.introIframe {
  float: left;
  width: 100%;
  padding: 0 15px;
  height: auto;
}

.linkValue {
  float: left;
  width: 100%;
}

.gmailBtn i.fa.fa-google,
.facebookBtn .fa-facebook {
  margin-right: 5px;
}

/* .test .coachAboutMainSec{
  float: none;
  display: inline-block;
} */
.socialUserArea .coachAboutSec {
  min-height: unset;
  line-height: normal;
  /* height: 240px; */
}

p.noDataSec {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.mainSocialIcon {
  width: 35px;
  height: 35px;
  float: left;
}

.mainSocialIcon img {
  float: left;
  width: 100%;
}

.socialBtnRow button.btn {
  min-width: 150px;
}

.proposalList a.btn.orangeBtn.whiteColor {
  padding: 8px 0px !important;
  color: var(--main-text-white-color) !important;
}

.proposalList a.btn.orangeBtn.whiteColor:hover {
  color: var(--gmail-color) !important;
}

.whiteBoxSec h2 {
  font-size: 24px;
}

.modalSettingButton {
  min-width: 120px;
}

option {
  font-family: 'Roboto', sans-serif;
}

.sendMessagePopupHelpSec {
  border-bottom: 1px solid #e5e5e5 !important;
  padding: 10px 30px 10px 30px;
  font-size: 14px;
}

.labelForNotification {
  font-weight: normal !important;
  margin-left: 5px;
}

input[type="checkbox"]:disabled+label {
  color: #ddd;
}

.titleAlign {
  word-break: break-word;
}

.pwdHideShow {
  position: relative;
}

.pwdHideShowIcon {
  position: absolute;
  z-index: 9999;
  right: 10px;
  top: 11px;
  font-size: 16px;
  cursor: pointer;
}

.pwdHideShowForPwdPage {
  position: absolute;
  z-index: 9999;
  right: 10px;
  top: 35px;
  font-size: 16px;
  cursor: pointer;
}

/* this css is for Mocrosoft Edge,It removes the default eye icon in password field  */
input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.usdRate {
  font-size: 10px;
  float: right !important;
}

.resIcons {
  width: 25px !important;
}

.resTab {
  float: left;
  width: calc(100% - 25px) !important;
}

.settingsMenuSec .nav.nav-tabs a i {
  margin-top: 3px;
  float: left;
}

.dollar {
  margin-left: 10px;
  position: relative;
  top: 5px;
}

.detailReview {
  float: left;
  width: 100%;
}

.resAmount {
  margin-left: 5px;
  width: calc(100% - 75px);
}

.usdRateOfProfile {
  min-height: auto !important;
}

.contactImportSec button.btn.blueBtn {
  margin-left: 15px;
}

.notificationBarMain {
  width: 100%;
  float: left;
  text-align: center;
  height: 60px;
  background-color: #003f5a;
  box-shadow: 0 -2px 5px var(--main-text-white-color);
  position: relative;
  z-index: 1;
}

.verficationBar{
  background-color: rgba(255,0,0,0.5);
}

.resenEmailButton {
  color: var(--main-text-white-color) !important;
  text-decoration: underline;
}

.coachInfo span.paymentOverDue {
  /* color: var(--red-color); */
  float: none;
  font-size: 11px;
  margin-left: 5px;
  color: green;
}

.coachInfo span.paymentDue {
  color: var(--gmail-color);
  float: none;
  font-size: 11px;
  margin-left: 5px;
}

.oneLineDesc {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.paymentDueAndOverdue {
  float: none !important;
}

/* Graph Sec Started */
.greenSec {
  color: var(--btn-green-color);
}

.redSec {
  color: var(--red-color);
}

.greenSec i,
.redSec i {
  margin-right: 2px;
}

.counterSec {
  margin: 0px 0;
  font-size: 44px;
  font-weight: bold;
  color: var(--main-blue-color);
}

/* .dashboardCards .panel-body {
  min-height: 132px;
} */

.graphHeadingArea {
  /* border-bottom: 1px solid var( --dark-grey); */
  width: 100%;
  float: left;
  padding: 15px 0;
  margin: 0px !important;
}

.graphHeadingArea p {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
}

a.graphViewLinks {
  font-size: 12px;
  position: relative;
  top: 1px;
}

.graphSec {
  padding: 20px 30px;
}

/* Graph Sec Ended */
.breakWordAll {
  word-break: break-word;
}

.col-xs-12.graphSec {
  padding-left: 0;
  padding-right: 0;
  width: 90%;
  margin-left: 5%;
  min-height: 383px;
}

.chart-legend .legend-title {
  display: none;
}

button.btn.orangeBtn.whiteColor.equalSizeBtn.endContract:focus,
button.btn.orangeBtn.whiteColor.equalSizeBtn.endContractCoach:focus {
  color: var(--main-text-white-color);
}

/* toggle css start */

.flight-types {
  display: flex;
  max-width: 160px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  font-size: 14px;
  border: 1px solid var(--main-blue-color);
  border-radius: 3px;
  color: var(--main-blue-color);
  float: right;
  position: relative;
  top: 3px;
}

.flight-types>input {
  display: none;
}

.flight-types>input:checked+label {
  color: var(--main-text-white-color);
}

.flight-types>input:nth-of-type(1):checked~label:last-of-type:before {
  transform: translateX(calc(0% + 0px));
  opacity: 1;
}

.flight-types>input:nth-of-type(2):checked~label:last-of-type:before {
  transform: translateX(100%);
  opacity: 1;
}

.flight-types label {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: normal;
  margin: 0;
}

.flight-types label:last-of-type:before {
  content: "";
  display: block;
  max-width: calc(50% - 0px);
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translateX(0);
  opacity: 0.6;
}

.flight-types label {
  padding: 6px 3px;
  transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
  color: rgba(125, 125, 125, 0.8);
}

.flight-types label:before {
  background: var(--main-blue-color);
  transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}

.flight-types label:not(:last-child) {
  border-right: 1px solid var(--main-blue-color);
}

/* Toggle css ends */

/* free consultation toggle button css starts */

/* .showFreeConsult {
  top: 0px !important;
  max-width: 85px !important;
  padding: 0px !important;
  opacity: 1 !important;
} */

.freeConsult-types {
  display: flex;
  max-width: 85px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  font-size: 14px;
  border: 1px solid var(--main-blue-color);
  border-radius: 3px;
  color: var(--main-blue-color);
  float: right;
  position: relative;
  top: 0px;
}

.freeConsult-types>input {
  display: none;
}

.freeConsult-types>input:checked+label {
  color: var(--main-text-white-color);
}

.freeConsult-types>input:nth-of-type(1):checked~label:last-of-type:before {
  transform: translateX(calc(0% + 0px));
  opacity: 1;
}

.freeConsult-types>input:nth-of-type(2):checked~label:last-of-type:before {
  transform: translateX(100%);
  opacity: 1;
}

.freeConsult-types label {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: normal;
  margin: 0;
}

.freeConsult-types label:last-of-type:before {
  content: "";
  display: block;
  max-width: calc(50% - 0px);
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translateX(0);
  opacity: 1;
}

.freeConsult-types label {
  padding: 0px;
  transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
  color: rgba(125, 125, 125, 0.8);
}

.freeConsult-types label:before {
  background: var(--main-blue-color);
  transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}

.freeConsult-types label:not(:last-child) {
  color: var( --dark-grey);
  border-right: 1px solid var(--main-blue-color);
}

/* free consultation toggle button css starts */

.coachInfoDesc .maxHeight {
  max-height: 60px;
}

.filterSec {
  float: right;
  width: 250px;
}

.filterSec span {
  position: relative;
  top: 10px;
}



.showFreeConsult label {
  padding: 0px !important;
}

.rateFilterSec {
  float: right;
  width: 100px;
}

.addPackageIcon {
  color: var(--main-blue-color);
  font-size: 30px;
  cursor: pointer;
}

.addIconSec {
  float: right;
  text-align: right;
  width: 100%;
  top: -12px;
}

.noRates {
  margin-top: -15px;
  margin-bottom: 0px;
}

.addPackageIconText {
  color: var(--darkColor-with-06-opacity);
  font-weight: normal;
  position: relative;
  top: -7px;
  right: 5px;
  font-size: 11px;
}

body .chart-legend {
  width: 540px !important;
  /* position: absolute;
  left: 0;
  bottom: 30px; */
}

.chart-legend .legend-label {
  cursor: unset !important;
  font-size: 90%;
  margin: 8px;
  color: #76818a;
  float: none !important;
  text-align: left !important;
  display: inline-block;
}

.contactButtons button.btn {
  max-width: 130px;
  float: right;
}

.contactImportSec {
  top: 4px;
}

.chart-legend>div {
  width: 100% !important;
}

.chart-legend .legend-labels {
  background-color: var(--main-text-white-color) !important;
  text-align: center !important;
  margin-top: 1px;
}

body .ngx-charts {
  overflow-x: auto !important;
}

.mainGraph {
  width: 100%;
  overflow-x: hidden;
  display: grid;
}

.contractDetailModalArea .contractHistorySec .historyData,
.contractDetailModalArea .contractPaymentSec .historyData {
  overflow-y: auto;
}

.contractDetailModalArea .contractModalInfo .coachInfoDesc {
  max-height: 63px;
  overflow-y: auto;
}

.editButton {
  font-size: 12px !important;
  min-width: 45px !important;
  padding: 0 5px !important;
  margin-bottom: 5px !important;
}

.deleteButton {
  font-size: 12px !important;
  min-width: 40px !important;
  padding: 0 5px !important;
}

body .orangeBtn.btn.deleteButton:hover {
  color: var(--gmail-color) !important;
}

.userPackageMainSec {
  display: flex !important;
}

.packageDescSec {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 100%;
  /* margin-bottom: 10px; */
  height: 22px;
}

.packagesTextArea {
  resize: none;
  height: 90px !important;
}

.form-control[readonly] {
  background-color: var(--main-text-white-color);
}

.packTitleSec {
  display: block;
  word-wrap: break-word;
}

/* .ngxEditorSec .ngx-toolbar-set:nth-child(3) {
  display: none !important;
} */

.hirePackTitle {
  float: left;
  width: 90%;
  margin-bottom: 10px;
  margin-left: 10%;
}

.popupBg .packageDescSec {
  height: auto !important;
  text-overflow: inherit;
  overflow: auto !important;
  white-space: unset !important;
  word-wrap: break-word;
}

.packageDescSec ul,
.rateDescription ul {
  list-style: disc;
  width: 100%;
  padding-inline-start: 40px;
}

app-coach-profile .ngx-toolbar .ngx-toolbar-set .ngx-editor-button[title="Strikethrough"],
app-coach-profile .ngx-toolbar .ngx-toolbar-set .ngx-editor-button[title="Superscript"],
app-coach-profile .ngx-toolbar .ngx-toolbar-set .ngx-editor-button[title="Subscript"],
app-coach-profile .ngx-toolbar .ngx-toolbar-set .ngx-editor-button[title="Indent"],
app-coach-profile .ngx-toolbar .ngx-toolbar-set .ngx-editor-button[title="Outdent"],
app-packages .ngx-toolbar .ngx-toolbar-set .ngx-editor-button[title="Strikethrough"],
app-packages .ngx-toolbar .ngx-toolbar-set .ngx-editor-button[title="Superscript"],
app-packages .ngx-toolbar .ngx-toolbar-set .ngx-editor-button[title="Indent"],
app-packages .ngx-toolbar .ngx-toolbar-set .ngx-editor-button[title="Outdent"],
app-packages .ngx-toolbar .ngx-toolbar-set .ngx-editor-button[title="Horizontal Line"],
app-packages .ngx-toolbar .ngx-toolbar-set .ngx-editor-button[title="Subscript"] {
  display: none;
}

.col-xs-12.rateDescription {
  height: 125px;
  overflow-y: auto;
}

/* Contact Search Start */
.form-control.contactSearch {
  width: 200px;
  float: left;
  margin-right: 15px;
  padding: 19px 15px 18px;
  position: relative;
  top: 1px;
}

.allContactSearchSec .btn.blueBtn.pull-right {
  position: relative;
  top: 3px;
}

.allContactSearchSec {
  border-bottom: 1px solid var( --grey-color);
  padding-bottom: 20px;
}

.contactRow img {
  width: 70%;
  border-radius: 100px;
  height: 48px;
  min-width: 48px;
}

.contactName p,
.contactTitle p {
  margin-bottom: 0;
}

.contactName p {
  float: left;
  font-weight: bold;
  font-size: 16px;
}

.contactName span {
  position: relative;
  top: 2px;
  margin-left: 20px;
  color: var(--darkColor-with-06-opacity);
}

.contactTitle p {
  color: var(--darkColor-with-06-opacity);
}

.contactName span:before {
  position: absolute;
  content: '•';
  left: -11px;
  top: -3px;
  font-size: 18px;
  color: var(--darkColor-with-06-opacity);
}

.noPadding.contactDescSec {
  min-height: 50px;
  display: table;
  padding: 15px 0 !important;
}

.contactRow {
  display: table;
  width: 100%;
  margin-right: 0 !important;
  border-bottom: 1px solid var( --grey-color);
  margin-left: 0 !important;
  padding: 15px 0;
}

.container-left {
  display: table-cell;
  vertical-align: middle;
  float: none !important;
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
}

.container-right {
  display: table-cell;
  vertical-align: middle;
  float: none !important;
  /* white-space: pre-wrap; */
}

.commentInputSec.container-right {
  display: table-cell;
  vertical-align: middle;
  float: none !important;
  white-space: pre-wrap;
}

.container-right span {
  float: left;
}

.contactDescSec .orangeBtn.whiteColor {
  color: var(--main-text-white-color) !important;
}

.contactDescSec .orangeBtn.whiteColor:hover {
  color: var(--gmail-color) !important;
}

.contactImageArea {
  width: 70px !important;
  cursor: pointer;
}

/* Contact Search end */

/* Sign up section start */

app-sign-up .content {
  background-color: var( --light-bg-color);
}

app-sign-up .signUpMainArea .inputBox {
  margin-top: 5px;
}

app-sign-up .signUpMainArea .inputBox .form-control {
  height: auto;
  padding: 12px 15px;
}

body app-sign-up .signUpFirstName {
  padding-right: 5px;
}

body app-sign-up .signUpLastName {
  padding-left: 5px;
}

app-sign-up .signupBanner {
  background-image: url('./assets/images/signUpImage.jpg');
  box-shadow: -30px 20px 0px -15px #8DBEF6, 40px 5px 0px 0px #f7d1a4;
  width: 350px;
  height: 350px;
  margin-top: 55px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: -170px center;
  background-size: cover;
}

app-sign-up ul.signupAdvantages {
  margin-top: 150px;
}

app-sign-up ul.signupAdvantages .fa {
  color: green;
  font-size: 20px;
  margin-right: 5px;
}

app-sign-up .signupAdvantages li {
  font-size: 15px;
  font-style: italic;
  margin-bottom: 10px;
  font-weight: lighter;
}

app-sign-up .verticleLine {
  border-left: 2px solid var( --light-white-color-2);
  height: 330px;
  position: absolute;
  top: 100px;
  left: -10px;
}

app-sign-up .signUpMainArea .agreeArea label {
  font-style: italic;
}

app-sign-up .btnSignUpArea button.btn.gmailBtn {
  background-color: #db3236 !important;
}

app-sign-up .btnSignUpArea button.btn.facebookBtn {
  background-color: #3b5998 !important;
}

app-sign-up .inputWithIcon .inputBox .form-control {
  width: 100%;
}

app-sign-up .pwdHideShowIcon {
  top: 14px;
}

app-sign-up h4.signUpHeader {
  font-size: 24px;
  font-weight: bold;
}

app-sign-up .row.fullSignUpArea {
  margin-top: 50px;
}

/* Sign up section end */

tag.ng-trigger-animation .disabled {
  cursor: default !important;
}

tag-input .bootstrap.ng2-tag-input.ng2-tag-input--disabled {
  cursor: default !important;
  opacity: 1 !important;
  border: 0 !important;
  padding: 0 !important;
}

body .bootstrap tag {
  background: var(--main-blue-color) !important;
  color: var(--main-text-white-color) !important;
  height: 24px !important;
  line-height: 24px !important;
  padding: 0 5px 0 6px;
  margin-right: 4px !important;
  /* margin-top: 4px; */
}

body .bootstrap.ng2-tag-input {
  border: 1px solid var( --light-white-color-2) !important;
  border-radius: 6px;
  padding: 10px !important;
  background-color: var(--main-text-white-color);
}

tag-input .ng2-tag-input__text-input {
  height: auto !important;
  width: 100%;
}

.ng2-tag-input.bootstrap.ng2-tag-input--disabled tag-input-form {
  display: none;
}

delete-icon svg {
  height: 24px !important;
}

tag-input .ng2-tag-input {
  transition: unset !important;
}

body .selected-list .c-btn {
  box-shadow: unset !important;
  border-radius: 6px !important;
}

tag-input delete-icon:hover {
  transform: unset !important;
}

tag-input-form.ng-tns-c15-0 {
  margin-top: 4px !important;
  max-width: 120px;
}

tag-input .progress-bar {
  position: absolute;
  left: 0;
}

body ng2-menu-item {
  padding: 0 !important;
}

/* .ng2-dropdown-menu.ng2-dropdown-menu---width--4.ng-trigger.ng-trigger-fade{
  opacity: 0;
}

.ng2-dropdown-menu.ng2-dropdown-menu---width--4.ng-trigger.ng-trigger-fade.ng2-dropdown-menu--open{
  opacity: 1;
} */

.expertiseSec {
  min-height: 340px;
}

.nextButton,
.skipBtn {
  position: absolute;
  bottom: 0px;
  float: right !important;
  text-align: right;
}

.backButton {
  position: absolute;
  bottom: 0px;
  text-align: left;
  margin-top: 5px;
}

.mainExpertiseSec {
  width: 100%;
}

.coachSec,
.coacheeSec {
  float: left;
  width: 100%;
  padding: 15px;
  word-break: break-word;
  /* text-align: justify; */
}

.hireACoachButton {
  min-width: 167px;
}

span.editProfileIconSec.updateUserImage.bannerImageUpdateIcon.bannerPicIconSec {
  top: unset;
}

.font11px {
  font-size: 11px;
  color: #888;
}

.font12px {
  font-size: 12px;
  color: #888;
}

.detailDescSec p,
.detailDescSec span,
.detailDescSec td,
.detailDescSec tr,
.detailDescSec th,
.detailDescSec div,
.detailDescSec textarea,
.detailDescSec h1,
.detailDescSec h2,
.detailDescSec h3,
.detailDescSec h4,
.detailDescSec h5,
.detailDescSec h6,
.detailDescSec ul,
.detailDescSec li,
.ngx-editor-textarea ul,
.ngx-editor-textarea li,
.ngx-editor-textarea p,
.ngx-editor-textarea span,
.ngx-editor-textarea td,
.ngx-editor-textarea tr,
.ngx-editor-textarea th,
.ngx-editor-textarea div,
.ngx-editor-textarea textarea,
.ngx-editor-textarea h1,
.ngx-editor-textarea h2,
.ngx-editor-textarea h3,
.ngx-editor-textarea h4,
.ngx-editor-textarea h5,
.ngx-editor-textarea h6,
.packageDescSec *,
.rateDescription * {
  color: #333 !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  /* margin: 0px !important; */
  font-weight: normal !important;
  line-height: 1.42857143 !important;
  background-color: var(--main-text-white-color) !important;
  width: 100% !important;
  /* display: inline-block !important; */
}

body .packageDescSec *,
body .rateDescription * {
  background-color: var( --light-bg-color) !important;
}

body .sharebtn {
  border-radius: 0px !important;
  margin-top: 5px;
}

body .nps {
  font-style: italic;
}

body .tagm {
  margin-top: 3em;
}

span.expertiseSecBg {
  background-color: #e8e6e6;
  margin: 2px 2px 2px 0;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: inline-block;
}

body .tagtextdeco {
  font-size: 12px;
  position: relative;
  border-bottom: 1px solid var( --light-white-color-2);
  /* text-decoration: underline; */
}

body .tagtextdeco::after {
  content: ',';
}

body .singleTag:last-child .tagtextdeco::after {
  content: '';
}

.noRatingSecHome {
  float: left !important;
  margin-right: 10px;
}

body .coachProfileArea .nps {
  font-size: 12px;
  padding-top: 8px;
}

body .ng2-dropdown-menu {
  padding: 0px !important;
}

app-coaches .homeCoachesList>h3 {
  text-align: center;
  text-transform: uppercase;
}

.thankYouPage {
  text-align: center;
}

.profileeIcon {
  color: var(--main-blue-color);
  font-size: 40px;
}

#counter {
  background-color: #A4E295;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.freeDemoSec {
  min-height: 100px;
}

/* Feed Sec */
.row.vertical-align {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

img.followIcon {
  width: 30px;
}

.followCoaches img.profileImage {
  margin: 0;
  width: 55px;
}

.followCoaches h3 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.followCoaches .fullWidth {
  font-size: 12px;
  opacity: 0.7;
}

.createPost textarea {
  width: calc(100% - 50px);
  float: right;
  border-radius: 6px;
  resize: none;
  padding: 10px 15px 15px 15px;
  border: none;
  margin-left: 15px;
  margin-top: 5px;
  margin-right: 0px;
  background-color: #f0f0f0;
  min-height: 120px;
}

.createPost img.profileImage {
  position: absolute;
  z-index: 99;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  left: 27px;
  top: 10px;
}

.createPost .publishPostSec span.hirePersonNoImage {
  font-size: 12px;
  width: 30px;
  height: 30px;
  padding: 7px 0;
  border-radius: 100%;
  margin: 0;
  position: relative;
  top: 10px;
  left: 12px;
}

.createPost .publishPostSec {
  background-color: #f0f0f0;
  width: calc(100% - 30px);
  border-radius: 6px;
  margin-left: 15px;
  min-height: 180px;
}

.createPost .publishPostSec.newPostWithImage {
  min-height: 270px;
}

.postList .profileImage {
  height: 60px;
  width: 60px;
}

ul.topLikeCommentSec {
  float: right;
  font-size: 12px;
}

.topLikeCommentSec li {
  float: left;
  margin-left: 15px;
  background-color: #f4f4f4;
  padding: 2px 20px;
  border-radius: 5px;
}

span.postDateTime {
  position: relative;
  top: 4px;
  font-size: 11px;
  color: #888;
}

.topLikeCommentSec .fa-thumbs-o-up {
  color: var(--main-blue-color);
}

.topLikeCommentSec .fa-commenting-o {
  color: #94D285;
}

.postLikeCommentSec li {
  float: left;
  margin-right: 15px;
  color: var(--darkColor-with-05-opacity);
  cursor: pointer;
}

.postLikeCommentSec li.postLiked {
  color: var(--main-blue-color);
  font-weight: bold;
}

.postLikeCommentSec {
  float: left;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 0;
  font-size: 12px;
}

.postList {
  float: left;
  width: 100%;
  margin-bottom: 25px;
  padding-top: 25px;
  position: relative;
}

.postList:last-child {
  margin-bottom: 0px;
}

.followCoaches {
  margin-top: 10px;
  margin-bottom: 10px;
  /* float: left; */
}

.followCoaches span.hirePersonNoImage {
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 55px;
  height: 55px;
  margin-left: 0;
}

.unfollowButton img {
  width: 16px;
  margin-right: 2px;
}

.shareLink img {
  width: 16px;
  margin-right: 2px;
}

.shareLink .onHoverImage,
.shareLink:hover .normalImage,
.unfollowButton .onHoverImage,
.unfollowButton:hover .normalImage {
  display: none;
}

.shareLink:hover .onHoverImage,
.unfollowButton:hover .onHoverImage {
  display: inline-block;
}

.followCoachIcon {
  background-color: var(--main-blue-color);
  cursor: pointer;
  height: 28px;
  width: 28px;
  display: inline-block;
  padding: 4px 5px;
  border-radius: 30px;
  position: relative;
  left: -5px;
  color: var(--main-text-white-color);
  font-size: 15px;
}

.followCoachIcon i {
  position: relative;
  left: 1px;
}

.followCoachIcon:hover {
  background-color: var(--main-text-orange-color);
}

.followCoachIcon img {
  width: 18px;
  height: 20px;
}

.publishPostBtnSec .btnBlue {
  position: absolute;
  right: 45px;
  bottom: 15px;
}

.listImageSec {
  float: left;
  width: 90px;
  margin-left: 15px;
}

.listImageSec .profileImage {
  cursor: pointer;
}

.listImageSec .hirePersonNoImage {
  cursor: pointer;
}

.listDataSec {
  float: left;
  width: calc(100% - 110px);
}

/* .followUserArea {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
} */

.createPost textarea::-webkit-input-placeholder {
  font-weight: bold;
}

.createPost textarea::-moz-placeholder {
  font-weight: bold;
}

.createPost textarea:-ms-input-placeholder {
  font-weight: bold;
}

.createPost textarea:-o-input-placeholder {
  font-weight: bold;
}

.publishPostBtnSec .uploadImageButton {
  right: unset;
  left: 45px;
}

.publishPostBtnSec .uploadImageButton:hover {
  /* background-color: var(--main-text-white-color) !important;
  color: var(--main-blue-color) !important;
  border: 1px solid var(--main-blue-color); */
  opacity: 0.7;
}

img.mainPostImage {
  max-width: 75px;
  max-height: 75px;
}

.popupImageSec {
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translatex(-50%);
  max-width: 90%;
  background-color: var(--main-text-white-color);
}

.popupImageSec img {
  max-width: 100%;
  max-height: 100%;
}

.uploadedImageArea {
  position: absolute;
  left: 90px;
  top: 135px;
}

img.feedImageSec {
  margin-top: 15px;
  max-height: 500px;
  max-width: 500px;
}

.borderForPost {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var( --grey-color);
  top: 0;
  right: -15px;
}

.postList:first-child .borderForPost {
  height: 0px !important;
}

.postList:first-child {
  padding-top: 0;
}

body .fixedFollowArea {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 277px;
  z-index: 0;
}

body .panelSetting.createPost {
  padding: 15px 0;
}

.hireCoachDesc {
  float: right !important;
}

.hireSkills {
  float: left;
  width: 50%;
}

.activatedFreeDemo .coachMainInfoSec {
  min-height: 300px;
}

app-coach-profile .coachProfileButtonGroup button.btn.blueBtn.messageBtn.freeDemoBtn {
  bottom: 127px;
}

.popupImageSec button.close {
  color: var(--main-text-white-color);
  opacity: 01;
  background-color: var(--main-blue-color);
  width: 25px;
  border-radius: 30px;
  line-height: 0;
  padding: 11px 0 13px 0;
  font-size: 20px;
  position: absolute;
  top: -10px;
  z-index: 99;
  right: -12px;
  text-shadow: none;
}

.popupImageSec button.close:hover {
  /* background-color: var(--main-text-white-color);
  color: var(--main-blue-color); */
  opacity: 0.7 !important;
}

.style {
  height: 500px !important;
}

.packageHeading {
  font-size: 16px;
}

span.addReadMore {
  height: 43px;
  float: left;
  overflow: hidden;
}

.readMoreLessSec {
  float: left;
  color: var(--main-blue-color);
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

.feedDescArea {
  float: left;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
}

span.feedDescArea.postMinHeight {
  max-height: 80px;
  overflow: hidden;
}

span.feedDescArea.postMaxHeight {
  max-height: auto;
  overflow: hidden;
}

span.postFollowBtns {
  position: relative;
  top: 4px;
  font-size: 12px;
  color: var(--main-blue-color);
  cursor: pointer;
}

.postFollowingLink {
  position: relative;
  top: 4px;
  font-size: 12px;
}

.rateFixedHeight {
  height: 210px;
  overflow: hidden;
  padding-top: 7px;
}

.rateTitleFixedHeight {
  height: 20px;
  overflow: hidden;
  /* padding-top: 7px; */
}

.commentsSec.commentfixedHeight {
  height: 190px;
  overflow: hidden;
}

.detailDescSec.aboutFixedHeight {
  height: 225px;
  overflow: hidden;
}

.coachAboutSec .detailDescSec {
  /* height: 337px; */
  overflow: hidden;
}

.postsFixedHeight {
  max-height: 332px;
  overflow: hidden;
  padding-top: 5px;
}

.detailDescSec.aboutFixedHeight.aboutFullHeight,
.rateFixedHeight.rateFullHeight,
.currentCoachRateSec .rateFixedHeight.rateFullHeight,
.commentsSec.commentfixedHeight.commentFullHeight,
.postsFixedHeight.postsFullHeight,
.rateTitleFixedHeight {
  height: auto;
}

.showMoreLessSec.aboutSeeMoreLess {
  margin-top: 7px;
}

/* .panelSetting.followCoachSec {
  height: calc(100vh - 170px);
  overflow-y: auto;
  overflow-x: hidden;
} */

.panelSetting.followCoachSec.fixedFollowArea {
  height: calc(100vh - 95px);
}

.profilePostsSec {
  padding: 23px 15px;
  border-bottom: 1px solid var( --grey-color);
}

.profilePostsSec:last-child {
  border-bottom: 0px solid var( --grey-color);
}

.profilePostsSec img {
  max-width: 100%;
  max-height: 100px;
}

.postsFixedHeight p.noDataSec {
  margin-top: 110px;
}


/* .socialUserAreaWithHeading.socialUserArea .row.coachAboutSec.panel.panel-default {
  height: 280px;
} */

.currentCoachRateSec .rateFixedHeight {
  height: 250px;
}

.commentsSec.commentfixedHeight.commentSectionWithNoPost {
  height: 190px;
}

.dashboardCards ul {
  float: left;
  width: 100%;
}

.dashboardCards ul li {
  float: left;
  width: 18%;
  margin: 0 1%;
}

.write_msg {
  resize: none
}

.createPost .panel-body {
  padding: 0;
}

button.btn.orangeBtn.whiteColor.equalSizeBtn.endContract.freeEndContract {
  margin-top: 0 !important;
}

.allPostsArea span.hirePersonNoImage {
  width: 60px;
  height: 60px;
  padding: 10px 0;
  font-size: 30px;
  margin-left: 15px;
}

.coachProfileArea .profilePostsSec .noProfileImageSec {
  max-width: 100px;
  padding: 29px;
  max-height: 100px;
}

.tool {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tool .tooltext {
  visibility: hidden;
  width: 150px;
  background-color: grey;
  color: var(--main-text-white-color);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.floatRight {
  float: right;
}

.tool:hover .tooltext {
  visibility: visible;
}

.shareProfilePost {
  float: left;
  color: var(--main-blue-color);
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
}

ul.addComment {
  float: left;
  width: 100%;
}

ul.addComment li {
  /* display: inline-block; */
  margin: 10px 5px 0;
  float: left;
}

.form-control.commentInput {
  background-color: var( --light-white-color-1);
  border: none;
  float: left;
  box-shadow: none;
  resize: none !important;
  overflow-y: hidden !important;
  padding: 10px 55px 10px 10px !important;
}

.form-control.commentInput:focus {
  box-shadow: none;
}

.commentInputSec {
  width: calc(100% - 65px);
}

img.profileImageCommentSec {
  width: 39px;
  border-radius: 5px;
}

span.noProfileImageHeader.noProfileImageCommentSec {
  border-radius: 6px;
  padding: 9px 0 8px;
  position: relative;
  top: 1px;
}

button.msg_send_btn.commentSubmit {
  font-size: 12px;
  width: 26px;
  height: 26px;
  top: 17px;
  right: 30px;
}

.seeAllComments {
  font-size: 12px;
  text-decoration: underline;
  color: var(--main-blue-color);
  cursor: pointer;
}

.feedAllComment .container-left {
  padding-right: 10px;
  width: 65px;
}

ul.feedAllComment.contactRow {
  padding: 0;
  border: none;
}

ul.feedAllComment.contactRow img {
  min-width: auto;
  height: auto;
  margin-left: 5px;
}

.sponsoredUser {
  color: var(--gmail-color);
  font-size: 12px !important;
}

body div.ng2-dropdown-menu.ng2-dropdown-menu---width--4.ng2-dropdown-menu--open {
  display: block !important;
  max-height: 100px !important;
}

.commentsSec .ratingBarArea.reviewRatingSec span.ratingSecHeading {
  width: 114px;
  word-break: break-all;
}

.commentsSec .ratings:hover .ratingPopupBox {
  right: -73px !important;
  width: 260px !important;
}

.commentsSec .ratingPopupBox .empty-stars:before,
.commentsSec .ratingPopupBox .full-stars:before {
  font-size: 12px !important;
}

.row.coachProfileArea .sponsoredCoachesCardsSec .homeCoachesList {
  width: 98%;
  margin-left: 25px;
}

.row.coachProfileArea .sponsoredCoachesCardsSec .homeCoachesList .slick-arrow.slick-prev {
  left: -12px;
}

.singleReview:last-child .ratingPopupBox {
  top: -46px;
}

.expertiseCoachNotFoundSec {
  margin-bottom: 60px;
  margin-top: -50px;
}

.exploreCard .fa-plus-circle {
  color: var(--main-blue-color) !important;
  font-size: 70px !important;
  margin: 10px 0 10px 0
}

.exploreCard {
  text-align: center !important;
  max-width: 365px !important;
  height: auto !important;
  margin-top: 10px;
}z

.exploreCard h3 {
  text-transform: uppercase;
}

.versionData {
  color: #444;
}

button.linkBtn.linkBtnSec {
  color: var(--main-blue-color) !important;
  padding: 0;
  background: transparent;
}

button.linkBtn.linkBtnSec:hover {
  text-decoration: underline !important;
}

.modalButtonsWidth {
  min-width: 260px;
  float: none;
  padding: 10px 12px !important;
}

.homeBtn {
  min-width: 200px !important;
  padding: 12px 15px !important;
  float: right !important;
}

.test {

  margin-bottom: 55px
}

.testt {
  margin-bottom: 40px;
  margin-top: 35px;

}

.padding5px {
  padding: 5px !important;
}

.OrSection span {
  background-color: var( --light-bg-color);
  position: relative;
  z-index: 99;
  padding: 0 5px;
}

.coachSearchForm .btn:hover,
.btn.searchCoachBtn:hover {
  opacity: 0.7 !important;
}

.adveriseHead {
  text-align: center;
  margin: 20px 0 20px 0;
}

.adHeading {
  font-weight: 600;
  margin-bottom: 5px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 0 !important;
  max-width: 300px;
  display: inline-block;
  float: none !important;
  vertical-align: top;
  margin: 0px 20px;
  /* max-width: 300px;
  margin-left: 7px; */
}

.col-lg-12.text-center.topSec {
  float: left;
  width: 100%;
  height: 150px;
}

.middleSec {
  min-height: 260px !important;
}

.middleSec p {
  text-align: left;
}

.layer1 {
  background-color: var(--main-blue-color);
  padding: 6px;
}

.categoryRate {
  font-weight: bold;
  margin-bottom: 3px;
  font-size: 22px;
  color: #000;
  position: relative;
}

.category {
  font-size: 20px;
}

.categoryText {
  font-size: 12px;
}

.layer2 {
  background-color: var(--gmail-color);
  padding: 6px;
}

.layer3 {
  background-color: var(--red-color);
  padding: 6px;

}

.SubscribeBtn {
  padding: 10px 60px;
  font-size: 18px;
  background-color: var(--main-blue-color);
  color: var(--main-text-white-color);
  border-radius: 4px;
  margin-bottom: 2em;
}

.popular {
  background-color: var(--gmail-color);
  color: var(--main-text-white-color);
  padding: 6px 42px;
  margin: auto;
}

.recommend {
  background-color: var(--red-color);
  color: var(--main-text-white-color);
  padding: 6px 35px;
  margin: auto;
}

.hide {
  display: none !important;
  background-color: rgba(31, 130, 242, 0.1);
}

.show {
  display: block !important;

}

.hideshow,
.hide {
  background-color: rgba(31, 130, 242, 0.04);
  float: left;
  width: 100%;
  padding: 0px 26px 16px 38px;
  font-size: 16px;
  color: var( --main-text-black-color);
  text-align: left;
}

.faqSec {
  background-color: transparent;
  display: inline-block;
  max-width: 990px !important;
}

.Questions {
  font-size: 18px;
  color: var(--main-blue-color);
  text-align: left;
  padding: 25px 40px;
  background-color: rgba(31, 130, 242, 0.04);
  margin-top: 0px;
  border-radius: 0px;
}

.card li img {
  float: right;
  width: 25px;
  position: relative;
  top: 2px;
}

.card li {
  text-align: left !important;
  float: left;
  width: 93%;
  margin-bottom: 10px;
  list-style: disc;
  padding-left: 0px;
  margin-left: 7%;
}

.advLogo img {
  width: 30px;
  position: relative;
  left: 28px;
  top: 10px;
}

.Questions span {
  float: right;
}

.underText {
  position: relative;
  font-weight: 900;
  top: 31px;
  color: #009d21;
  left: -11px;
  font-size: 10px;
}

li.advLogo {
  cursor: pointer;
}

.subscribeBtnSec {
  margin-bottom: 30px;
}

.subscribeBtnSec button.btn.blueBtn {
  /* text-transform: uppercase; */
  font-size: 18px;
  width: 200px;
}

.expertBar {
  background-color: #003f5a;
  height: 60px;
  padding: 14px;
  float: left;
  width: 100%;
}

.expertBarSuite {
  background-color: #003f5a;
  height: 60px;
  padding: 14px;
  float: left;
  margin-top: 5px;
  width: 100%;
}

.qimage {
  height: 30px;
}

.expertText {
  color: var(--main-text-white-color);
  margin: 0 10px;
}

.learnBtn {
  background-color: var(--main-blue-color);
  color: var(--main-text-white-color);
}

body .learnBtn:hover,
.helpBtn:hover {
  color: var(--main-text-white-color);
  /* opacity: 0.7; */
  /* background-color: rgba(31, 130, 242,.7); */
}

.crossSign {
  color: var(--main-text-white-color);
  font-size: 25px;
  float: right;
  position: relative;
  top: -4px;
}

.expertBox {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 0 !important;
  max-width: 990px;
  display: inline-block;
  float: none !important;
}

.mainBox {
  padding: 20px;
  float: left;
  width: 100%;
}

.helpBtn {
  background-color: var(--main-blue-color);
  color: var(--main-text-white-color);
  padding: 10px 30px;
}

body .reviewPaymentSec {
  position: absolute;
  bottom: 35px;
  right: 20px;
}

a.manageSubscription {
  font-size: 12px;
  margin-top: 10px;
  display: inline-block;
  text-decoration: underline;
}

a.manageSubscription:hover {
  text-decoration: none;
}

span.fullWidth.currentRateSec {
  font-size: 30px;
  font-weight: bolder;
  margin-top: -3px;
  color: var(--red-color);
}

span.fullWidth.currentRateHeading {
  color: black;
}

.line1 {
  width: 70px;
  height: 42px;
  border-bottom: 1px solid #888;
  -webkit-transform: translateY(20px) translateX(-50%) rotate(-12deg);
  position: absolute;
  top: 42px;
  left: 50%;
}

span.expertBadge {
  background-color: var(--main-text-orange-color);
  position: absolute;
  left: -30px;
  top: -80px;
  width: 110px;
  font-size: 36px;
  font-weight: bold;
  z-index: 9;
  color: var(--main-text-white-color);
  line-height: 36px;
  height: 110px;
  padding: 22px 24px;
  transform: rotate(-20deg);
  border-radius: 60px;
  box-shadow: 0px 2px 7px var( --dark-grey);
}

.leftExpertArea p {
  font-size: 16px;
  margin-left: 25px;
}

.cardCancelBtn{
  position: relative;
  top: 6px;
}

h3.categoryRate.redColorPrice {
  color: var(--red-color);
}

p.priceDiscountSec {
  margin: 0;
  opacity: 0.7;
}

.priceDiscountSec span {
  text-decoration: line-through;
}

p.percentOffSec {
  margin: 0;
  background-color: var(--red-color);
  display: inline-block;
  color: var(--main-text-white-color);
  padding: 5px 15px;
  font-weight: bold;
  font-size: 28px;
  height: 42px;
}

p.percentOffSec.goldSecPerOff{
  background-color: var(--gmail-color)
}

.percentOffSec2{
  margin: 0;
  background-color: transparent;
  display: inline-block;
  color: var(--main-text-white-color);
  padding: 5px 15px;
  font-weight: bold;
  font-size: 28px;
  height: 36px;
}

.porfileUserName span.input-group-addon {
  min-width: 165px;  
}

.homeSkillArea span.sponsoredUser {
  display: none;
}

div.row:nth-child(-n + 2) .coachInfo .sponsoredUser {
  display: block;
}

.coachInfo .sponsoredUser {
  display: none;
}

body .theme-green .bs-datepicker-head, body .theme-green .bs-datepicker-body table td span.selected{
  background-color: var(--main-blue-color) !important;
}

span.c-remove.clear-all, #fr-logo{
  display: none;
}

.selected-list .c-list .c-token .c-label{
  color: var(--main-text-white-color);
}

.c-btn {
  display: inline-block;
  background: var(--main-text-white-color);
  border: 1px solid var( --light-white-color-2);
  border-radius: 3px;
  font-size: 14px;
  color: #333;
}

quill-view-html .ql-editor{
  padding: 0;
}
/* 
button.ql-strike, span.ql-formats:nth-child(2), span.ql-formats:nth-child(3), span.ql-formats:nth-child(5), span.ql-formats:nth-child(6), span.ql-formats:nth-child(7), span.ql-formats:nth-child(8), span.ql-formats:nth-child(9), span.ql-formats:nth-child(10), span.ql-formats:nth-child(11), span.ql-formats:nth-child(13), span.ql-formats:nth-child(14) {
  display: none !important;
} */

quill-editor .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  border-radius: 0 0 6px 6px;
  background-color: var(--main-text-white-color);
}

quill-editor .ql-editor {
  height: 150px;
  overflow-y: auto;
}

.ql-toolbar.ql-snow {
  border-radius: 6px 6px 0 0;
}

span.ql-emojiblot {
  font-size: 0px;
}

body .backdrop,
body .spinner-rectangle-bounce {
  z-index: 999999999 !important;
}

.spinner-rectangle-bounce > div{
  margin: 0 1px;
}

.deletePostSec{
  cursor: pointer;
}

.deletePostSec span{
  color: var(--red-color);
}

.postLikeCommentSec li i, .topLikeCommentSec li i{
  margin-right: 2px;
}

body .ql-container {
  font-size: 14px;
}

.completeReg {
  font-size: 18px !important;
}

a.skipProfileBtn {
  color: var( --dark-grey);
  font-size: 12px;
}

body .panel.panel-default.marginTop2em.coachMainInfoSec.bannerAdded .orangeBtn.btn{
  text-overflow: ellipsis;
  overflow: hidden !important;
}

.homeSkillArea .followBtnSec button.btn{
  text-overflow: ellipsis;
  overflow: hidden !important;
  width: 100%;
}

app-koahcee-complete-profile .selected-list .c-btn{
  font-size: 14px;
}

app-koahcee-complete-profile .dropdown-list {
  padding-top: 0;
}

.ql-editor ol, .ql-editor ul {
  display: inline-block;
}

span.singleLang:last-child span {
  display: none;
}

.languageSec{
  position: relative !important;
}

.languageSec .dropdown-list.tagToBody.animated.fadeIn {
  top: unset !important;
  bottom: 38px;
  position: absolute;
  left: 0 !important;
  overflow: hidden;
}

.filterDropdownSec .languageSec .dropdown-list.tagToBody.animated.fadeIn {
  bottom: unset !important;
}

.languageSec .arrow-down {
  display: none;
}

.coachSkillsSec .singleLang span {
  float: right;
  margin-top: 0;
  margin-right: 5px;
}

.coachSkillsSec span.singleLang {
  margin: 0;
}

.languageSec .list-area {
  box-shadow: 0 5px 10px var( --dark-grey);
  width: 250px;
}

app-search-coach-list .searchCardRating {
  margin-top: 25px;
  margin-left: 20px;
}

.priceFilterSec {
  float: right;
  width: 280px;
  position: relative;
  top: 3px;
}

body .slider .bar > span.left-handle, body .slider .bar > span.right-handle {
  border: 7px solid var(--main-blue-color) !important;
  background: rgba(31, 130, 242, 0.4) !important;
}

.slider .bar div.filler > span {
  background: rgba(31, 130, 242, 0.4) !important;
}

.slider .bar > span.left-handle .handle-tooltip, .slider .bar > span.right-handle .handle-tooltip{
  border: 1px solid #333 !important;
  background: var(--main-text-white-color) !important;
  color: #333 !important;
}

.slider .bar > span.left-handle .handle-tooltip:before, .slider .bar > span.right-handle .handle-tooltip:before {
  border-top-color: #333 !important;
}

.slider .bar > span.left-handle .handle-tooltip:after, .slider .bar > span.right-handle .handle-tooltip:after {
  border-top-color: var(--main-text-white-color) !important;
}

.advanceSearchSec {
  float: left;
  margin-top: -5px;
}

input.form-control.minValBox {
  width: 100px;
  float: left;
}

input.form-control.maxValBox {
  width: 100px;
  float: right;
}

.advanceSearchModal, .advanceSearchModal .modal-body, .advanceSearchModal .modal-footer, .advanceSearchModal .modal-content {
  float: left;
  width: 100%;
}

.priceSec {
  float: left;
  width: 100%;
  margin-top: 25px;
}

.minValBox::-webkit-outer-spin-button, .minValBox::-webkit-inner-spin-button, .maxValBox::-webkit-outer-spin-button, .maxValBox::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

p.minValSec {
  float: left;
  margin: 0;
}

p.minValSec span {
  float: left;
  margin-top: 7px;
  margin-right: 5px;
}

p.maxValSec {
  float: right;
  margin: 0;
}

p.maxValSec span{
  float: left;
  margin-top: 7px;
  margin-right: 5px;
}

body .rateBtn.button.btn.btn-primary {
  margin-left: 1px !important;
  margin-right: 11px;
  margin-top: 0px;
  padding: 9px 20px;
  background-color: var(--main-text-white-color) !important;
  color: #333;
  border: 1px solid var(--darkColor-with-02-opacity);
  width: 125px;
  text-align: left;
  background-image: url("../src/assets/images/downArrow.png");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  box-shadow: none;
  border-radius: 6px;
}

.rateBtnArea {
  float: left;
  position: relative;
}

.rateArea {
  position: absolute;
  background-color: var(--main-text-white-color);
  border: 1px solid var(--darkColor-with-02-opacity);
  width: 350px;
  border-radius: 4px;
  padding: 30px 20px;
  z-index: 99;
  left: 1px;
  top: 50px;
  box-shadow: 0 5px 10px var( --dark-grey);
}

.rateBtnArea ul.dropdown-menu {
  left: 7px;
  width: 400px;
  padding: 30px 20px 20px 20px;
}

.rateSecBtnArea button.btn.blueBtn.messageBtn.modalSettingButton {
  margin-left: 0;
  float: right;
  margin-top: 35px;
  min-width: 80px !important;
}

.rateBtnArea ul.dropdown-menu li {
  float: left;
}

.rateBtnArea.filterSec .rateBtn.button.btn.btn-primary {
  margin: 0 !important;
}

.coachSearchListSec button.btn.searchCoachBtn {
  margin-top: 0;
  margin-left: 11px;
}

.milesRadioSec input[type=radio] {
  margin-right: 5px;
  position: relative;
  top: 1px;
  margin-top: 7px;
}

.rateBtnArea.filterSec {
  width: 125px;
}

app-search-coach-list .list-area{
  box-shadow: 0 5px 10px var( --dark-grey);
}

app-search-coach-list .selected-list .c-angle-down, app-search-coach-list .selected-list .c-angle-up{
  display: none;
}

app-search-coach-list .skillsDropDownList .selected-list .c-btn {
  background-image: url("../src/assets/images/downArrow.png");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
}

body .rateBtn.button.btn.btn-primary:hover {
  opacity: 1 !important;
}

.milesRadioSec label {
  font-weight: normal;
}

.filterCheckBoxSec h4, .locationSec h4{
  margin-bottom: 5px;
}

.filterCheckBoxSec label {
  font-weight: 500;
  margin-left: 5px;
}

.filterCheckBoxSec > input[type=checkbox] {
  position: relative;
  top: 1px;
}

.filterDropdownSec h4 {
  margin-bottom: 10px;
}

input.hideinput {
  height: 0px;
  padding: 0;
  line-height: 0;
  border: unset;
  float: left;
}

.copyHandleSec .copyInputField, .shareScreenCopy .copyInputField {
  width: calc(100% - 40px) !important;
}

.copyHandleSecSubDpmain .copyInputField {
  /* width: calc(100% - 150px) !important; */
  /* width: 210px !important; */
  width: calc(100% - 40px) !important;
  border-radius: 0 0 0 0 !important;
}
.spnchild { 
  position: relative;
  left: -41px;
}

.copyHandleSec .copySpan, .shareScreenCopy .copySpan {
  right: 12px;
  color: #000;
}

body .btn.blueBtn.homeBtn.marginBottom0Imp.previewProfileBtn {
  /* margin: 0; */
  padding: 10px 0 11px 0 !important;
  min-width: auto !important;
  position: relative;
  left: -10px;
}

.previewBtns {
  position: relative;
  right: -15px;
}

.mat-calendar-body-cell-content {
  border-width: none !important;
  border-style: none !important;
  border-radius: none  !important;
}



.colOuter .mat-calendar-body-cell-content.mat-calendar-body-selected {
  background-color: var(--main-blue-color);
  color: var(--main-text-white-color);
}


.colOuter .mat-calendar-body-cell-content.mat-focus-indicator:hover {
  background-color: rgba(31, 130, 242, 0.2);
}

.colOuter .mat-calendar-body-disabled {
  opacity: 0.5;
}

.cdk-live-announcer-element.cdk-visually-hidden {
  display: none !important;
}

.mat-icon-button{
  background: none;
  color: var(--main-blue-color);
}

.mat-icon-button.mat-button-disabled{
  background: none;
}

/* this code of styling for available date. The color of available date (day of week wise) will be light green */
.highlight-dates{
  color: var(--main-blue-color);
  border-radius: 100%;
  font-weight: 600;
}

mention-list.null {
  width: 250px;
  max-width: 90%;
}

mention-list.null ul, mention-list.null ul li {
  float: left;
  width: 100%;
}

.schedulerPopup .modal-dialog{
  width: 900px;
  max-width: 100%;
}

body .calmainbox .mat-calendar-body-cell.mat-calendar-body-disabled {
  pointer-events: none;
}

.schedulerPopup .confirmPopup .modal-dialog {
  width: 600px;
}

.not-allowed {
  cursor: not-allowed !important;
  opacity: 0.4 !important;
}


.noSessions {
  color: var(--main-text-black-color) !important;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

div#agora_local *, div#agora_local video {
  left: 0 !important;
}

.camera-container video {
  width: 100%;
  transform: rotateY(180deg);
}

.remote-containers video{
  width: unset !important;
  object-fit: unset !important;
  right: 0;
  transform: rotateY(180deg);
}
/* .schedulingInput {
  width: 60% !important;
} */

.schedulingPadding {
  padding-left: 80px !important;
  padding-top: 30px !important;
  padding-right: 30px !important;
  /* padding-bottom: 30px !important; */
}

div#toolbar #btnclicker, div#toolbar #btnlaserPointer, div#toolbar #btnselector, div#toolbar #btnpencil, div#toolbar #btnrectangle, div#toolbar #btnellipse, div#toolbar #btnstraight, div#toolbar #btnarrow, div#toolbar #btntext, div#toolbar #btneraser, div#toolbar #btnhand, div#toolbar #btnred, div#toolbar #btnblue, div#toolbar #btnblack  {
  font-size: 0;
  background-position: center;
  height: 25px;
  min-width: 25px;
  background-size: 15px;
  background-repeat: no-repeat;
}

div#toolbar #btnclicker {
  background-image: url('./assets/white-board-icons/pointer.png');
}

div#toolbar #btnlaserPointer {
  background-image: url('./assets/white-board-icons/laserPointer.png');
  background-size: 22px;
}

div#toolbar #btnselector {
  background-image: url('./assets/white-board-icons/selector.png');
}

div#toolbar #btnpencil {
  background-image: url('./assets/white-board-icons/pencil.png');
}

div#toolbar #btnrectangle {
  background-image: url('./assets/white-board-icons/rectangle.png');
}

div#toolbar #btnellipse {
  background-image: url('./assets/white-board-icons/ellipse.png');
}

div#toolbar #btnstraight {
  background-image: url('./assets/white-board-icons/straightLine.png');
}

div#toolbar #btnarrow {
  background-image: url('./assets/white-board-icons/arrow.png');
}

div#toolbar #btntext {
  background-image: url('./assets/white-board-icons/text.png');
}

div#toolbar #btneraser {
  background-image: url('./assets/white-board-icons/eraser.png');
}

div#toolbar #btnhand {
  background-image: url('./assets/white-board-icons/drag.png');
}

div#toolbar #btnred {
  background-image: url('./assets/white-board-icons/redIcon.png');
}

div#toolbar #btnblue {
  background-image: url('./assets/white-board-icons/blueIcon.png');
}

div#toolbar #btnblack {
  background-image: url('./assets/white-board-icons/blackIcon.png');
}

.languageSec.multiSelectArea .selected-list .c-btn {
  font-size: 14px;
  max-height: 63px;
  overflow-y: auto;
  display: inline-block;
}

.languageSec.multiSelectArea .list-area {
  width: 100%;
}

.languageSec.multiSelectArea .c-btn > span:not(.c-angle-down, .c-angle-up) {
  position: relative;
  top: 5px;
}

.languageSec.multiSelectArea .dropdown-list.tagToBody.animated.fadeIn {
  bottom: 100%;
}

.mat-calendar-table-header span.cdk-visually-hidden {
  display: none;
}

::ng-deep .mat-calendar-body-label{
  display: none;
}

.mat-calendar-body-cell:focus .mat-focus-indicator::before{
  display: none;
}

body .popupBg .chart-legend {
  width: 90% !important;
}

.questionSec .selected-list .c-list .c-token .c-label, .questionSec .c-btn {
  font-size: 14px;
}