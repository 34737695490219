@media screen and (max-width : 767px) {

  body .swiper-button-prev {
    left: 7px;
  }

  body .swiper-button-next {
    right: 6px;
  }

  body .notificationBar{
    margin-bottom: 10px;
    top: unset;
  }

  body .notificationBarMain{
    height: auto;
  }

  .graphSecRow.row.marginTop2em {
    width: 90%;
    margin: 0 5%;
  }

  .dashboardCards.row {
    width: 90%;
    margin-left: 5%;
  }

  body .dashboardCards .panelSetting, body .graphSecRow .panelSetting{
    width: 100%;
    margin: unset;
  }

  body #managesubscription .paymentBox {
    width: 100%!important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }

  body .mainImageSec{
    width: 100%;
  }

  body .mainProfileImage {
    max-width: 100%;
    margin: 0 0 20px 0;
  }

  body .modal-content {
    border: 0px solid #000 !important;
  }

  body .expertBar {
    height: 95px;
    position: relative;
  }

  body .crossSign {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  body .tool .tooltext {
    top: unset;
    bottom: 20px;
    left: -70px;
  }

  .feedDescArea {
    margin-top: 15px;
    word-break: break-word;
  }

  .coachInfo span {
    word-break: break-all;
  }

  body span.expertBadge {
    transform: translateX(-50%) rotate(-20deg);
    left: 50%;
  }

  .expertBox {
    margin-top: 40px;
  }

  body .card {
    margin: 0px 20px 25px;
  }

  .notificationTable a.readNotification {
    opacity: 1;
    position: relative;
    z-index: 999999 !important;
  }

  body .reviewPaymentSec {
    position: relative;
    bottom: unset;
    right: unset;
  }

  body .wc-date-popover {
    z-index: 9999999 !important;
    height: 424px !important;
    width: 300px !important;
  }

  /* .middleSec {
    overflow: hidden;
  } */

  span.advLogo {
    float: left;
    position: relative;
    top: -17px;
  }

  .leftExpertArea {
    text-align: center;
  }

  .listImageSec {
    /* float: left; */
    width: 35px;
    /* margin-left: 15px; */
  }

  .postList .profileImage {
    height: 25px;
    width: 25px;
  }

  .listDataSec {
    /* float: left; */
    width: calc(100% - 50px);
  }

  .topLikeCommentSec li {
    margin-left: 0px;
    float: right;
    margin-bottom: 5px;
  }

  .dateSec .toDate {
    margin: 1em 0;
  }

  .contractHistorySec .historyData, .contractPaymentSec .historyData {
    overflow-y: auto;
  }

  .row.coachProfileArea .sponsoredCoachesCardsSec {
    float: left;
    width: 100%;
  }

  .row.coachProfileArea .sponsoredCoachesCardsSec .homeCoachesList {
    margin-left: 1.5%;
  }

  body .commentsSec .singleReview:last-child .ratings .ratingPopupBox {
    top: -65px !important;
    left: -5px !important;
  }

  .row.coachProfileArea .sponsoredCoachesCardsSec .homeCoachesList .slick-arrow.slick-prev {
    left: 0 !important;
  }

  body .followCoachSec {
    display: none;
  }

  .dashboardCards ul li {
    width: 100%;
    margin: 0 0;
  }

  body .panelSetting.followCoachSec {
    height: 300px;
    width: calc(100% - 6%);
  }

  body .panelSetting.followCoachSec.fixedFollowArea {
    position: static;
    max-width: calc(100% - 6%);
    height: 300px;
  }

  .panelSetting.createPost,
  .panelSetting.allPostsArea {
    margin-left: 5px;
    width: calc(100% - 7%);
  }

  label.btn.btnBlue.uploadImageButton {
    padding-left: 10px;
    padding-right: 10px;
  }

  body .detailDescSec {
    word-break: break-word;
    float: left;
    width: 100%;
    overflow: auto;
  }

  .userProfileBanner {
    height: 150px;
    width: calc(100% - 30px);
    margin-left: 15px;
  }

  .noBannerImageSec {
    float: left;
    width: calc(100% - 30px);
    height: 150px;
    margin-left: 15px;
  }

  body app-coach-profile button.btn.blueBtn.hireCoachBtn,
  body app-coach-profile .coachProfileButtonGroup button.btn.blueBtn.messageBtn {
    position: static;
  }

  body .coachRightRatingArea .text-center {
    text-align: left !important;
    margin-bottom: 10px;
  }

  body .coachProfileButtonGroup .ratingArea {
    text-align: left;
  }

  .modal-dialog {
    height: calc(100% - 100px);
    overflow-y: auto;
  }

  span.editProfileIconSec.displayNoneForDesktop.displayForMobile {
    top: 0;
  }

  span.editProfileIconSec.updateUserImage.bannerImageUpdateIcon {
    right: 20px;
  }

  body .userPackageMainSec button,
  body .displayForMobile {
    display: block !important;
  }

  body .paddingLeftXS0px {
    padding-left: 0px !important;
  }

  body .paddingLeftXS15px {
    padding-left: 15px !important;
  }

  body .paddingXS0px {
    padding: 0px !important;
  }

  app-packages span.dollarSym {
    position: absolute;
    left: 0;
  }

  app-packages .form-control.inputField {
    width: calc(100% - 100px);
    margin-bottom: 10px;
  }

  body .container-left {
    float: left !important;
    display: block !important;
  }

  body .container-left p {
    width: 100%;
    /* text-align: right; */
  }

  .container-right {
    float: right !important;
    vertical-align: auto;
  }

  .contactImportSec button {
    margin-top: 10px;
    min-width: 135px;
  }

  .allContactSearchSec button.btn.searchCoachBtn {
    float: right !important;
    margin-top: 15px;
  }

  .form-control.contactSearch {
    width: 100%;
  }

  .contactImageArea {
    float: left !important;
    width: 60px !important;
  }

  .contactRow {
    display: block;
  }

  .fixed.greyBannerArea,
  .fixed .row.blueBannerArea.coachSearchForm {
    padding-top: 8px;
    padding-bottom: 10px;
  }

  .greyBannerArea,
  .row.blueBannerArea.coachSearchForm {
    padding-top: 20px;
    padding-bottom: 15px;
    text-align: center;
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 80% !important;
    border: none !important;
  }

  body .filterSec {
    width: 100%;
    position: relative;
    top: 10px;
    text-align: right;
  }


  .filterSec span {
    margin-right: 20px;
  }

  body .noMarginRes {
    margin: 0 !important;
  }

  body .textAlignCenter {
    text-align: right !important;
  }

  .modal .modal-dialog.colWidth {
    margin: auto;
    top: 20px;
    max-width: 100%;
  }

  .normalLogoutPage a {
    width: 100%;
  }

  .singleCoachBox .singleUserImage {
    width: 110px;
  }

  .mobileViewHeader .logoutBtnArea {
    text-align: center;
    float: none !important;
    display: inline-block;
  }

  .singleCoachBox .singleUserRatingSec .ratings {
    float: none;
    width: auto;
  }

  body .chart-legend .legend-label {
    float: left !important;
    width: 40%;
    margin: 5px 5%;
    text-align: left;
  }

  body .chart-legend .legend-label-text {
    padding-right: 0px;
  }

  body .col-xs-12.graphSec {
    min-height: auto !important;
  }

  body div .chart-legend {
    width: 100% !important;
    min-width: 100% !important;
  }

  .chart-legend .legend-wrap {
    width: 100% !important;
    float: left;
  }

  body .timeline.timeline-icons li {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  body .contractModalInfo .coachInfoDesc {
    word-break: break-all;
  }

  .approveBtnMob {
    position: relative;
    margin-top: 0;
    top: -1px;
  }

  .marginBottomMob1em {
    margin-bottom: 1em !important;
  }


  body .currentUserName {
    float: left;
    max-width: calc(100% - 50px);
    word-break: break-word;
  }

  .coachProfileArea .coachProfileButtonGroup .btn.hireCoachBtn:hover,
  .coachProfileArea .coachProfileButtonGroup .btn.hireCoachBtn:active {
    background-color: var(--main-text-white-color) !important;
  }

  .btn.btnBlue,
  .btn.btnBlue:hover,
  .btn.btnBlue:active {
    background-color: var(--main-blue-color) !important;
    color: var(--main-text-white-color) !important;
  }

  body .blueBtn:focus,
  body .blueBtn:hover,
  body .blueBtn:active {
    background-color: var(--main-blue-color) !important;
    color: var(--main-text-white-color) !important;
  }

  a.deletePackage {
    float: left;
    /* width: 100%; */
    margin: 5px 0 0 0;
  }

  button.btn.orangeBtn.whiteColor.equalSizeBtn.endContract,
  a.btn.blueBtn.equalSizeBtn.marginTop1em.pendingContract {
    position: relative;
    top: -1px;
    margin-top: 0px !important;
  }

  app-koachee-offers-list .modal-dialog {
    max-height: calc(100% - 100px);
    overflow-y: auto;
  }

  body .coacheeProfileArea .coachAboutSec {
    margin-left: 0 !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .coacheeProfileArea .commentsSec {
    padding-left: 30px;
    padding-right: 30px;
  }

  li.dropdown.helpMenuSection {
    position: relative;
    left: -8px;
  }

  .dropdown-list {
    width: 90% !important;
    margin: 0 5%;
  }

  .hireCoach ul,
  .becomeCoach ul {
    padding: 0 0 0 15px;
    margin-top: 16px
  }

  .bannerImageModal .modal-dialog,
  .profileImageModal .modal-dialog {
    width: 94%;
  }

  body .form-control.invoiceInput {
    position: relative;
    z-index: 9999;
  }

  body .locationImage {
    margin: 0px;
  }

  body .singleCoachBox {
    max-width: 86%;
    width: 86%;
    left: 0%;
  }

  body .expertiseCoachSec .singleCoachBox {
    width: 84%;
    left: 4%;
  }

  body app-sign-up .signupBanner {
    width: 250px;
    height: 250px;
    margin-top: 0;
    position: relative;
    left: 50%;
    transform: translateX(-55%);
    background-position: -150px center;
  }

  app-sign-up .socialBtnRow button.btn {
    min-width: 185px;
  }

  app-sign-up ul.signupAdvantages {
    margin-top: 40px;
  }

  body app-sign-up .signUpFirstName {
    padding-right: 15px;
  }

  body app-sign-up .signUpLastName {
    padding-left: 15px;
  }

  body app-sign-up div.inputBox.lastNameInput {
    margin-top: 5px;
  }

  .footerArea p {
    text-align: center;
  }

  body .countryName {
    top: 10px;
  }

  .messageCoach {
    text-align: left;
  }

  .coachAboutSec {
    min-height: auto;
  }

  .coachInfo {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  body .noPaddingLeftXs {
    padding-left: 0px;
  }


  body .noPaddingRightXs {
    padding-right: 0px;
  }

  body .noPaddingXs {
    padding: 0px;
  }

  body .noPaddingXsImp {
    padding: 0px !important;
  }

  body .hireCoachExpertiseSec,
  body .hireCoachRateSec {
    height: auto;
    max-height: 125px;
  }

  body .hireCoachExpertiseSec li {
    position: relative;
  }

  body .hireCoachExpertiseSec li input[type=checkbox] {
    position: absolute;
    left: 0;
  }

  .rateSecForXS .row.coachAboutSec {
    padding-left: 15px;
    padding-right: 15px;
  }

  .coachMainInfoSec .coachProfileButtonGroup {
    padding-left: 15px;
  }

  .coachAboutSec h3.detailHeading span {
    left: 0;
  }

  body .commentsSec .reviewBoxArea {
    padding-left: 15px;
  }

  body .marginTop1emXs {
    margin-top: 1em;
  }

  body .coachSearchForm .formField {
    margin-bottom: 1em;
    width: 300px;
  }

  body .coachSearchForm .btn {
    margin-left: 0px;
    margin-top: 10px;
    float: right;
  }

  body .paypalBox a.pull-right {
    position: relative;
  }

  body .coachDesc {
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  body .coachProfileButtonGroup {
    text-align: center;
  }

  body .coachAboutSec {
    margin-left: -15px !important;
  }

  body .ratingGridFullArea {
    margin-top: 15px;
  }

  body .reviewBoxArea .reviewBy {
    width: 100%;
  }

  /* search coach list css starts */

  /* area and keword section  */
  .fullCoachListSec {
    top: 0;
    width: 90%
  }

  .selected-list {
    width: 100%;
    margin: 0% 0%;
  }

  .messageCoach a.btn.orangeBtn.whiteColor {
    float: left;
  }

  .locationSearchSec {
    margin: 0px;
    width: 100%;
    background-position: 96%;
    margin-top: 2%;
  }

  .reswidthForSkills {
    width: 92% !important;
  }

  .skillsDropDownList {
    width: 100%;
    margin-top: 0px;
  }

  body .skillsDropDownList .selected-list .c-btn {
    top: 0;
    width: 100%;
  }

  .dropdown-list.tagToBody {
    position: fixed;
    padding: 6px;
    left: 0px !important;
    top: 55px !important;
  }

  .coachSearchListSec.coachSearchForm {
    margin: 0;
    width: 100%;
  }

  .coachSearchListSec.form-horizontal .form-group {
    margin: 0;
  }

  body .hireCoachExpertiseSec span.hireSkillName {
    width: calc(100% - 20px);
  }

  .coachSearchForm {
    width: 100%;
    margin: 0 0%;
  }

  body .coachSearchForm .formField {
    width: 100%;
  }

  .coachSearchForm .formField,
  body .dateViewer .wc-date-container {
    margin: 0;
  }

  .coachSearchForm.form-horizontal .form-group {
    margin: 0;
    width: 100%;
  }

  /* .greyBannerArea.fixed {
    position: static;
    border-bottom: 0px solid var(--light-white-color-2);
    min-height: 250px;
  } */

  .greyBannerArea.fixed>div {

    width: 100%;
    top: 8px;
  }

  .container.coachSearchListSec {
    padding: 0;
  }

  .hireCoachExpertiseSec label.nonBold.noMargin {
    width: 100%;
  }

  .greyBannerArea {
    margin: 0 !important;
  }

  .empty-stars:before,
  .full-stars:before {
    margin-left: 0px;
    ;
  }

  a.coachListReadReview {
    float: left;
  }

  .coachSkillsSec button.btn.blueBtn {
    top: 5px;
    left: 0px;
    /* width: 100%; */
    float: left;
  }

  .messageCoach {
    min-height: 90px;
  }

  .fullCoachListSec .btnsSec {
    position: relative;
    /* left: 5px; */
    margin-right: 0px;
    margin-top: 20px;
  }

  .btn.unfollowBtn {
    float: left !important;
  }

  .toggleArea.showToggle button.btn.unfollowBtn.pull-right {
    float: right !important;
    margin-top: 0px;
  }

  .greyBg {
    height: 230px;
    position: relative;
    z-index: 99;
  }

  /* search coach list css ends*/

  /* koachee proposal list page css starts */
  select.offersDropdown {
    width: 100%;
  }

  body .contractDetailModal .modal-dialog {
    width: 1100px;
    max-width: 95%;
    max-height: 96%;
    overflow: auto;
    border-radius: 6px;
  }

  .row.blueBannerArea.coachSearchForm {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  body .coachSearchForm .formField {
    width: 100%;
    margin: 2% 0;
  }

  .row.blueBannerArea.coachSearchForm button.btn.searchCoachBtn {
    margin-right: 0;
  }

  .offerList,
  .proposalList {
    width: 90%;
    margin-top: 5px;
  }

  body .equalSizeBtn {
    float: left;
    margin-left: 10px;
    ;
  }

  /* koachee proposal list page css ends */

  /*Home page css starts */
  .slick-slide .singleMessageSec img {
    display: inline-block;
    width: 16px;
  }

  .singleMessageSec .msgSpan {
    font-size: 12px;
  }

  .singleCoachBox {
    width: 90%;
    /* margin-left: 5%; */
  }

  .row.marginTop1em.coachSlider.slick-initialized.slick-slider {
    margin-left: 0;
  }

  .slick-arrow.slick-next {
    right: 0px;
    z-index: 999;
  }

  .slick-arrow.slick-prev {
    right: unset;
    left: 0px;
    z-index: 999;
  }

  .mainBannerImage {
    height: 670px;
  }

  .userDataSec {
    height: unset;
  }

  body .whiteBoxSec {
    width: 90%;
    margin-bottom: 15px;
  }

  body .toggleArea.showToggle {
    max-height: unset !important;
    margin-bottom: -30px;
  }

  body .commentsSec {
    padding-left: 0px;
    padding-right: 0px;
  }

  img.profileImageInTopBar,
  .noProfileImageHeader {
    position: absolute;
    top: 10px;
    margin-left: 10px;
  }

  body div .btn.homeSlideBtn,
  body div .btn.homeSlideBtn:hover {
    float: left;
  }

  body .homeSkillsSec {
    padding-left: 15px !important;
  }

  .toggleArea {
    margin-bottom: 0px;
  }

  .homeCoachesList {
    overflow: hidden;
    top: 0;
    margin-top: 2em;
  }

  .homeCoachesList>div,
  .homeSkillArea .userDataSec {
    margin-top: 0;
  }

  /* Home page css ends */

  /* coach profile css start */
  body .commentsSec {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .coachProfileArea h4 {
    width: 100%;
  }

  body .coachAboutMainSec {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  body .coachHourlySec {
    width: 90%;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }

  .coachMainInfoSec {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  body .coachProfileButtonGroup {
    width: 100%;
  }

  .coachProfileButtonGroup .btn {
    display: block;
    /* margin-left: 15px; */
  }

  /* coach profile css end */

  /* account page start */

  .paymentBox {
    width: 90% !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  /* account page end */

  /* Header CSS START */
  body .mobileViewHeader {
    background-color: transparent;
    border: none;
    float: left;
    width: 100%;
    padding: 0 5%;
  }

  body .mobileViewHeader.navbar-default .navbar-toggle {
    margin-right: 0px;
    margin-top: 13px;
  }

  body .mobileViewHeader.navbar-default .navbar-collapse,
  body .mobileViewHeader.navbar-default .navbar-form {
    border: none;
  }

  .mobileViewHeader .header1AccountArea a {
    /* float: left;
    width: 100%; */
    text-align: left;
    margin: 0;
  }

  .logoutBtnArea span {
    font-size: 12px;
    margin-right: 15px;
  }

  ul.mobileIcons {
    float: right;
    margin-bottom: 3px;
  }

  .mobileIcons li {
    float: left;
  }

  .mobileViewHeader .setMenuRight {
    width: 100%;
  }

  .mobileViewHeader .setMenuRight li {
    float: left;
    width: 100%;
  }

  .mobileViewHeader .navbar-nav {
    margin: 0;
  }

  .headerMainArea .mobileViewHeader .navbar-nav>li>a {
    padding: 20px 15px 10px 15px;
  }

  body .notificationBell ul.dropdown-menu.dropdown-menu-left.pull-right {
    width: 275px;
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    transform: translateX(45px) !important;
    /* transform: translate(25%, 0); */
  }

  .mobileViewHeader .notificationBell .dropdown-menu>li>a,
  body .notificationBell ul.dropdown-menu.dropdown-menu-left.pull-right li {
    width: 100%;
  }

  body .timeline {
    list-style: none;
    padding-left: 0;
    position: relative;
    float: left;
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }

  /* notofication page css starts */
  .notificationTable {
    width: 100%;
    overflow-y: scroll;
  }

  .notificationTable>.row {
    width: 700px;
  }

  /* notofication page css end */


  /* Header CSS ENDS */

  /* settings page (koache or kochee type page) css starts */

  label.cardSec {
    width: 90%
  }

  .panelSetting {
    width: 90%;
    margin-left: 5%
  }

  /* settings page (koache or kochee type page) css end */

  /* sign up page css starts */
  div.inputBox.lastNameInput {
    margin-top: 16px;
  }

  .btnSignUpArea h4:before,
  .btnSignUpArea h4:after {
    width: 15%;
  }

  .btn.btnBlue {
    margin-top: 0px;
  }

  /* sign up page css end */

  /* message page start */

  .inbox_people {
    width: 100%;
    margin-bottom: 15px;
  }

  .inbox_chat {
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    margin-top: 10px;
  }

  .chat_img {
    width: 100%;
    text-align: center;
  }

  .chat_img img {
    height: 65px;
    width: 65px;
    float: none;
  }

  .chat_ib {
    float: left;
    padding: 0 0;
    width: 100%;
  }

  .chat_ib h5 {
    font-size: 12px;
  }

  .chat_list {
    margin: 0;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    width: 75px;
    border-radius: 3px;
  }

  .time_date {
    top: -5px;
    right: 40px;
  }

  .received_msg {
    width: 80%;
  }

  .received_withd_msg {
    width: 90%;
  }

  .outgoing_msg .sent_msg {
    width: 80%;
  }

  .outgoing_msg .sent_msg p {
    width: 75%;
  }

  .outgoing_msg .sent_msg .time_date {
    left: 40px;
  }

  .oppTopEmail {
    float: left;
    width: 100%;
    margin: 0px;
  }

  .chat_list .chatNoProfileImageSec {
    font-size: 35px;
    padding: 10px 7px;
    width: 65px;
    max-height: 65px;
  }

  .mesgs {
    width: 100%;
    min-height: unset;
  }

  .hideSec {
    display: none;
  }

  /* message page end */

  /* invoice list css starts */
  .invoiceListTable {
    margin-top: 0px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 6px;
  }

  .invoiceListTable>.row {
    width: 900px;
    margin: 0px;
  }

  /* .invoiceListHeading div,
  .invoiceTableData div {
    width: 150px;
  } */

  body .noPadding.paddingForMobile {
    padding: 0 15px !important;
  }

  .coachSearchForm .formField,
  body .dateViewer .wc-date-container {
    width: 250px;
    float: right;
    margin-bottom: 10px;
  }

  /* invoice list css end */

  .ratingArea,
  .ratings {
    float: left;
    width: 100%;
    margin-left: 0px !important;
  }

  body .ratings:hover .ratingPopupBox {
    right: unset;
    top: 30px;
    left: -3px;
    width: 100%;
  }

  body .arrow-left {
    display: none;
  }

  body .cuppa-dropdown .arrow-up, body .cuppa-dropdown .arrow-down {
    display: none;
  }

  .reviewBoxArea .ratingPopupBox .ratings {
    width: auto;
  }

  body .ratingBarArea.reviewRatingSec span.ratingSecHeading {
    width: 100%;
  }

  .ratingBox .modal-dialog {
    max-width: 90%;
    margin: 10px auto;
  }

  .questionsText .starRatingBottomText,
  .ratingSecSubHeading,
  .fiveStarRatingSec {
    width: 100%;
  }

  .questionAnswerRatingArea h5 {
    /* font-size: 14px; */
    margin-bottom: 15px;
    line-height: 20px;
  }

  /* hire coach section start */
  .hireCoachSlidingMain {
    width: 100%;
    right: -100%;
  }

  body button.closeBtnHireModal {
    right: 5px;
    font-size: 26px;
    box-shadow: 0 1px 20px var( --light-white-color-2);
    top: 5px;
    height: 40px;
    width: 40px;
  }

  /* hire coach section ends */

  .fullWidthMobileView {
    float: left;
    width: 100%;
  }

  .resMarginTop1em {
    margin-top: 1em !important;
  }

  .shareIcons li {
    margin: 0 3px;
  }

  .ratesUSD {
    top: 5px;
  }

  .packagesSec .packageRow {
    margin-bottom: 30px;
  }

  body .cardInfo {
    min-height: 87px;
    width: calc(100% - 110px);

  }

  body .remote-containers {
    position: relative !important;
    max-height: 260px !important;
    width: 100% !important;
    margin-bottom: 30px;
  }

  .bottom-controls-container {
    max-width: 95%;
  }

  body .notepadPopup {
    max-width: 90% !important;
    right: 5% !important;
  }

  body .notepadPopup.resize {
    height: calc(100% - 220px) !important;
  }

  .camera-container {
    height: 238px !important;
  }

  #agora_local .bottom-controls-container {
    bottom: 45px !important;
    margin-left: 0px !important;
  }

  body div .btn.sessionBtn {
    margin-top: 10px ;
    margin-left: 55px;
  }

  button.btn.orangeBtn.equalSizeBtn {
    margin-left: 55px;
  }

  .responsive-btn {
    margin-bottom: 5px !important;
  }

  .schedulingPadding {
     padding-left: 30px !important;
  }

  .margin-time{
    margin-bottom: 5px ;
  }

  .label-width {
    width: 45% !important;
  }

  .callSettingBtns ul.callButtons {
    position: relative;
    left: 15px;
  }

  .schedulerPopup .modal-dialog {
    max-width: 94%;
  }

  .remote-containers video, .remote-containers div {
    height: 270px;
  }

  ul.callButtons {
    position: relative;
    z-index: 99999;
  }
}


@media screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
  .expertiseCoachSec .row.marginTop1em.coachSlider>div {
    width: 48%;
    float: left;
    margin: 0 2% 0 0;
  }

  .expertiseCoachSec .singleCoachBox {
    margin-left: 10px !important;
  }

  .postsFullArea .col-xs-12.col-sm-3.col-md-3.col-lg-3.noPaddingLeft {
    width: 260px;
  }

  .postsFullArea .panelSetting.followCoachSec.fixedFollowArea {
    width: 245px;
  }

  .postsFullArea .col-xs-12.col-sm-9.col-md-9.col-lg-9.noPaddingRight.pull-right {
    width: calc(100% - 260px);
  }

  app-koachee-offers-list select.offersDropdown,
  body .offersKeyword {
    width: 170px;
  }

  app-koachee-offers-list .coachSearchForm .formField,
  body .dateViewer .wc-date-container {
    width: 185px;
    margin-left: 12px;
  }

  app-koachee-offers-list .greyBg .container {
    padding: 0px;
  }

  app-koachee-offers-list .coachSearchForm .btn {
    margin-right: 5px;
  }

  body .detailDescSec {
    word-break: break-word;
    float: left;
    width: 100%;
    overflow: auto;
  }

  .editProfileIconSec.hideSec {
    display: block !important;
  }

  .btn.searchCoachBtn {
    margin-left: 15px;
  }

  .form-control.contactSearch {
    width: 100px;
  }

  .row.marginTop1em.coachSlider.slick-initialized.slick-slider {
    margin-left: 0;
  }

  body .col-xs-12.graphSec {
    min-height: auto !important;
  }

  .singleCoachBox .singleUserImage {
    width: 110px;
  }

  body .cardInfo {
    min-height: 87px;
    width: calc(100% - 110px);
  }

  body .headerMenu {
    width: calc(100% - 260px) !important;
    text-align: center !important;
  }

  body .navbar.navbar-default .headerMenu>.nav>li.activeMenu>a:before {
    left: 1px;
    display: none;
  }

  .logo img{
    margin-right: 10px;
  }

  li.advLogo{
    width: 50px;
  }

  .advLogo img{
    left: -5px;
  }

  .underText {
    top: 10px;
    left: -2px;
  }

  .coachProfileArea .coachProfileButtonGroup .btn.hireCoachBtn:hover,
  .coachProfileArea .coachProfileButtonGroup .btn.hireCoachBtn:active {
    background-color: var(--main-text-white-color) !important;
  }

  .btn.btnBlue,
  .btn.btnBlue:hover,
  .btn.btnBlue:active {
    background-color: var(--main-blue-color) !important;
    color: var(--main-text-white-color) !important;
  }

  body .blueBtn:focus,
  body .blueBtn:hover,
  body .blueBtn:active {
    background-color: var(--main-blue-color) !important;
    color: var(--main-text-white-color) !important;
  }

  body .userDataSec,
  body .mainBannerImage {
    height: 420px;
  }

  .notificationBell ul.dropdown-menu.dropdown-menu-left.pull-right {
    top: 75px;
  }

  body .blueBtn:hover,
  body .blueBtn:active {
    background-color: var(--main-text-white-color) !important;
    color: var(--main-blue-color) !important;
  }

  body .blueBtn:focus {
    background-color: var(--main-blue-color) !important;
    color: var(--main-text-white-color) !important;
  }

  .endContract {
    margin-top: 16px !important;
  }

  .col-xs-12.col-sm-12.col-md-8.ratesUSD {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 21px;
  }

  /* .forHeading {
    margin-right: 0px !important;
  } */

  body .btn.homeSlideBtn,
  body .btn.homeSlideBtn:hover {
    font-size: 12px;
    float: right;
    min-width: 150px;
    padding: 12px 5px;
  }

  .homeSkillsSec {
    padding-left: 15px !important;
  }

  .homeExpertiseSec {
    width: 80%;
  }


  body .paypalBox a.pull-right {
    position: relative;
  }

  .bannerImageModal .modal-dialog,
  .profileImageModal .modal-dialog {
    width: 94%;
  }

  .noPaddingLeftSm {
    padding-left: 0px !important;
  }

  .noPaddingRightSm {
    padding-right: 0px !important;
  }

  .paddingLeftSm15px {
    padding-left: 15px !important;
  }

  .paddingLeftSm7px {
    padding-left: 7px !important;
  }

  body .singleCoachBox {
    max-width: 90%;
  }

  .row.marginTop1em.coachSlider {
    width: 100%;
  }

  .coachSearchForm .btn {
    margin-left: 12px;
  }

  body .headerMainArea .navbar-nav>li>a {
    padding: 23px 8px 21px 8px;
  }

  body .ratings {
    left: 0px;
  }

  app-search-coach-list .searchCardRating{
    margin-left: 5px;
  }

  nav a {
    padding: 5px 5px;
  }

  img.profileImageInTopBar {
    width: 30px;
  }

  .headerMainArea .navbar-nav>li>a {
    letter-spacing: 0px;
  }

  .menuHelpBox {
    padding: 3px 5px;
  }

  .notificationBellIcon {
    margin-top: 13px;
    position: relative;
    left: 9px;
  }

  ul.nav.navbar-nav.rightSideUserMenu {
    margin-top: -4px;
  }

  /* search coach list page starts */
  .portraitTabCss .rateSection {
    padding: 0 15px !important;
  }

  .readReview.col-sm-12 {
    margin-top: 0;
  }

  .greyBannerArea.fixed .coachSearchForm {
    width: calc(100% - 630px);
  }

  body .coachSearchForm {
    width: calc(100% - 200px);
  }

  body .skillsDropDownList {
    width: 180px;
  }

  body .coachSearchForm .formField{
    margin: 0px;
    width: 180px;
    margin-left: 10px;
    margin-right: 10px;
  }

  body .navbar.navbar-default .headerMenu>.nav>li.activeMenu>a:before, body .navbar.navbar-default .headerMenu>.nav>li:hover>a:before{
    display: none;
  }

  /* .greyBannerArea.fixed {
    padding-left: 3%;
  } */

  .greyBannerArea.fixed>div {
    left: 50%;
    transform: translateX(-32%);
  }

  .coachSkillsSec button.btn.blueBtn {
    padding: 10px 15px 11px;
  }

  /* search coach list page starts */

  /* sign up page css  */
  .btn.btnBlue {
    margin-top: 0px;
  }

  /* sign up page css  ends */

  .inbox_people {
    width: 100%;
    margin-bottom: 15px;
  }

  .inbox_chat {
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    margin-top: 10px;
  }

  .chat_img {
    width: 100%;
    text-align: center;
  }

  .chat_img img {
    height: 65px;
    width: 65px;
    float: none;
  }

  .chat_ib {
    float: left;
    padding: 0 0;
    width: 100%;
  }

  .chat_ib h5 {
    font-size: 12px;
  }

  .chat_list {
    margin: 0;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    width: 75px;
    border-radius: 3px;
  }

  .time_date {
    top: -5px;
  }

  .received_msg {
    width: 80%;
  }

  .received_withd_msg {
    width: 90%;
  }

  .outgoing_msg .sent_msg {
    width: 80%;
  }

  .outgoing_msg .sent_msg p {
    width: 75%;
  }

  .outgoing_msg .sent_msg .time_date {
    left: 120px;
  }

  .oppTopEmail {
    float: left;
    width: 100%;
    margin: 0px;
  }

  .incoming_msg .time_date {
    right: 120px;
  }

  .chat_list .chatNoProfileImageSec {
    font-size: 35px;
    padding: 10px 7px;
    max-height: 65px;
    width: 65px;
  }


  .mesgs {
    width: 100%;
  }

  .hideSec {
    display: none;
  }

  .coachProfileButtonGroup .btn {
    display: block;
  }

  .coachProfileImageArea {
    margin-top: 10px;
  }

  .coachDesc {
    margin-top: 10px;

  }

  label.cardSec {
    max-width: 90%;
  }

  /* invoice page css start */
  /* .row.invoiceTableData div,
  .row .invoiceListHeading div {
    width: 160px;
  } */

  span.datePickerHeadingSec {
    left: 15px;
  }

  /* app-koachee-offers-list .coachSearchForm .formField,
  body .dateViewer .wc-date-container {
    margin: 0px;
    width: 250px;
    margin-left: 15px;
  } */

  .coachSearchForm .formField,
  body .dateViewer .wc-date-container {
    margin: 0px;
    width: 200px;
    margin-left: 15px;
  }


  .locationSearchSec {
    width: 200px;
    margin-left: 12px;
  }

  .skillsDropDownList {
    width: 200px;
  }

  /* invoice page css end */

  .ratingArea,
  .ratings {
    float: left;
    width: 100%;
    margin-left: 0px !important;
  }

  body .ratings:hover .ratingPopupBox {
    right: unset;
    top: 30px;
    left: 0px;
  }

  body .arrow-left {
    display: none;
  }

  body .arrow-up {
    display: inline-block;
    top: -10px !important;
    left: 10px;
  }

  .reviewBoxArea .ratingPopupBox .ratings {
    width: auto;
  }

  .ratingBox .modal-dialog {
    max-width: 90%;
    margin: 10px auto;
  }

  app-invoice-list .greyBg {
    height: 110px;
  }
}

@media screen and (min-width : 768px) and (max-width : 1200px) and (orientation : landscape) {

  /* .expertiseCoachSec .row.marginTop1em.coachSlider>div {
    width: 48%;
    float: left;
    margin: 0 2% 0 0;
  }

  .expertiseCoachSec .singleCoachBox {
    margin-left: 10px !important;
  } */
  .postsFullArea .col-xs-12.col-sm-3.col-md-3.col-lg-3.noPaddingLeft {
    width: 260px;
  }

  .postsFullArea .panelSetting.followCoachSec.fixedFollowArea {
    width: 245px;
  }

  .postsFullArea .col-xs-12.col-sm-9.col-md-9.col-lg-9.noPaddingRight.pull-right {
    width: calc(100% - 260px);
  }

  app-invoice-list .greyBg {
    height: 110px;
  }

  app-invoice-list .toDate {
    text-align: right !important;
  }

  app-invoice-list .btn.searchCoachBtn {
    margin-left: 12px;
  }

  app-koachee-offers-list select.offersDropdown,
  body .offersKeyword {
    width: 170px;
  }

  app-koachee-offers-list .coachSearchForm .formField,
  body .dateViewer .wc-date-container {
    width: 185px;
    margin-left: 12px;
    margin-right: 0px;
  }

  app-koachee-offers-list .greyBg .container {
    padding: 0px;
  }

  app-koachee-offers-list .coachSearchForm .btn {
    margin-right: 5px;
    margin-left: 12px;
  }

  body .detailDescSec {
    word-break: break-word;
    float: left;
    width: 100%;
    overflow: auto;
  }

  .ratings {
    text-align: left;
    left: 27px
  }

  .userProfileBanner {
    height: 280px;
  }

  .coachSearchForm .formField,
  body .dateViewer .wc-date-container {
    width: 190px;
  }

  .editProfileIconSec.hideSec {
    display: block !important;
  }

  .form-control.contactSearch {
    width: 190px;
  }

  .contactImportSec button {
    width: 130px;
    margin-bottom: 5px;
  }

  body .userDataSec,
  body .mainBannerImage {
    height: 450px;
  }

  app-show-current-card .col-xs-12.col-sm-7.breakWordAll {
    width: calc(100% - 435px);
  }

  app-show-current-card .col-xs-12.col-sm-3.homeSkillsSec {
    width: 200px;
  }

  app-show-current-card .col-xs-12.col-sm-2.followBtnSec {
    width: 230px;
  }

  body .col-xs-12.graphSec {
    min-height: auto !important;
  }

  .singleCoachBox .singleUserImage {
    width: 110px;
  }

  body .cardInfo {
    min-height: 87px;
    width: calc(100% - 110px);
  }

  .row.marginTop1em.coachSlider.slick-initialized.slick-slider {
    margin-left: 0;
  }

  body .paypalBox a.pull-right {
    position: relative;
  }

  body .headerMenu {
    width: calc(100% - 350px) !important;
    text-align: center !important;
  }

  body .navbar.navbar-default .headerMenu>.nav>li.activeMenu>a:before {
    left: 1px;
  }

  .coachProfileArea .coachProfileButtonGroup .btn.hireCoachBtn:hover,
  .coachProfileArea .coachProfileButtonGroup .btn.hireCoachBtn:active {
    background-color: var(--main-text-white-color) !important;
  }


  .btn.btnBlue,
  .btn.btnBlue:hover,
  .btn.btnBlue:active,
  body .blueBtn:focus {
    background-color: var(--main-blue-color) !important;
    color: var(--main-text-white-color) !important;
  }

  body .blueBtn,
  body .blueBtn:focus,
  body .blueBtn:hover,
  body .blueBtn:active {
    background-color: var(--main-blue-color) !important;
    color: var(--main-text-white-color) !important;
  }

  body .userPackageMainSec .deleteButton.orangeBtn.whiteColor {
    background-color: var(--gmail-color) !important;
  }

  /* body .blueBtn:hover,
  body .blueBtn:active {
    background-color: var(--main-text-white-color) !important;
    color: var(--main-blue-color) !important;
  }

  body .blueBtn:focus {
    background-color: var(--main-blue-color) !important;
    color: var(--main-text-white-color) !important;
  } */

  .endContract {
    margin-top: 16px !important;
  }

  .paddingLeftXS {
    padding-left: 6px !important;
  }

  /* .forHeading {
    margin-right: 0px !important;
  } */

  .greyBannerArea .fixed .bannerImageModal .modal-dialog,
  .profileImageModal .modal-dialog {
    width: 94%;
  }

  .greyBannerArea.fixed .coachSearchForm {
    width: calc(100% - 562px);
  }


  .noPaddingLeftMd {
    padding-left: 0px !important;
  }

  body .singleCoachBox {
    max-width: 94%;
  }

  body .headerMainArea .navbar-nav>li>a {
    padding: 21px 15px;
  }

  nav a {
    padding: 5px 5px;
  }

  img.profileImageInTopBar {
    width: 30px;
  }

  .menuHelpBox {
    padding: 3px 5px;
  }

  .navbar.navbar-default .headerMenu>.nav>li:hover>a:before{
    left: 3px;
  }

  .notificationBellIcon {
    margin-top: 9px;
  }

  .locationSearchSec {
    margin-left: 0px;
  }

  ul.nav.navbar-nav.rightSideUserMenu {
    margin-top: -4px;
  }

  .headerMainArea .navbar-nav>li>a {
    letter-spacing: 0px;
  }

  .greyBannerArea.fixed .coachSearchForm {
    width: calc(100% - 670px);
  }

  .greyBannerArea.fixed {
    padding-left: 30px;
  }

  /*.greyBannerArea.fixed .skillsDropDownList {
    padding-left: 15px;
  } */

  body .coachSearchForm {
    /* margin-left: 30px; */
    float: left;
    margin-top: 25px;
    width: calc(100% - 250px);
    text-align: left;
  }

  .coachSearchForm .btn {
    /* float: right; */
    margin-top: 0px;
  }


  /* sign up page css  */
  .btn.btnBlue {
    margin-top: 0px;
  }

  /* sign up page css  ends */
  .coachSkillsSec button.btn.blueBtn {
    padding: 10px 15px 11px;
  }

  /* message page start */
  .time_date {
    right: 80px;
    top: -8px;
  }

  .outgoing_msg .sent_msg .time_date {
    left: 80px;
  }

  body .chat_img img,
  body .chatNoProfileImageSec {
    width: 30px;
    height: 30px;
  }

  span.chat_date.hideSec {
    display: none;
  }

  .coachProfileButtonGroup .btn {
    display: block;
  }

  .coachProfileImageArea {
    margin-top: 10px;
  }

  .coachDesc {
    margin-top: 10px;

  }

  label.cardSec {
    width: 90%;
  }

  /* message page end */

  /* invoice page start */
  .invoiceListTable {
    margin-top: 0px;
    background-color: #fff;
    border-radius: 6px;
  }

  /* .row.invoiceTableData div,
  .row .invoiceListHeading div {
    width: 160px;
  } */


  /* invoice page end */

  .ratingArea,
  .ratings {
    float: left;
    width: 100%;
    margin-left: 0px !important;
  }

  body .ratings:hover .ratingPopupBox {
    right: unset;
    top: 30px;
    left: 0px;
  }

  body .arrow-left {
    display: none;
  }

  body .arrow-up {
    display: inline-block;
    top: -10px !important;
    left: 10px;
  }

  .reviewBoxArea .ratingPopupBox .ratings {
    width: auto;
  }

  .ratingBox .modal-dialog {
    max-width: 90%;
    margin: 10px auto;
  }

  .skillsDropDownList {
    width: 200px;
  }

  .locationSearchSec {
    width: 200px;
  }

  /* body .coachSearchForm {
    width: calc(100% - 315px);
  } */
}


@media screen and (min-width : 768px) and (max-width : 1366px) and (orientation : portrait) {

  .singleCoachBox .singleUserImage {
    width: 110px;
  }

  body .cardInfo {
    min-height: 87px;
    width: calc(100% - 110px);
  }

  body .userDataSec,
  body .mainBannerImage {
    height: 420px;
  }

  .portraitTabCss .rateSection {
    padding: 0 15px !important;
  }

  .bannerImageModal .modal-dialog,
  .profileImageModal .modal-dialog {
    width: 94%;
  }

  .coachSkillsSec button.btn.blueBtn {
    padding: 10px 15px 11px;
  }

  .coachProfileButtonGroup .btn {
    display: block;
  }

  .coachProfileImageArea {
    margin-top: 10px;
  }

  .coachDesc {
    margin-top: 10px;
  }

  .coachSearchForm .btn {
    /* float: right; */
    margin-top: 0px;
  }

  /* body .coachSearchForm {
    margin-left: 30px;
    float: left;
    margin-top: 25px;
    width: calc(100% - 365px);
    text-align: left;
  } */

  /* invoice page start */
  .invoiceListTable {
    margin-top: 0px;
    background-color: #fff;
    border-radius: 6px;
  }

  body .noPadding.paddingForMobile {
    padding: 0 15px !important;
  }

  .skillsDropDownList {
    width: 200px;
  }

  body .dateViewer .wc-date-container {
    padding: 8px 10px;
  }

  body .dateViewer {
    width: 75%;
  }


  /* invoice page end */
}

.hireCoachDiv .panel.panel-default {
  border-radius: 6px !important;
}




@media screen and (min-width : 1024px) and (max-width : 1366px) and (orientation : portrait) {

  app-koachee-offers-list select.offersDropdown,
  body .offersKeyword {
    width: 250px;
  }

  .skillsDropDownList {
    width: 250px;
  }

  .greyBannerArea.fixed {
    padding: 10px 25px 10px 25px;
  }

  body .coachSearchForm {
    width: calc(100% - 360px);
  }

  .coachSearchForm .formField,
  body .dateViewer .wc-date-container {
    width: 250px;
  }

  .locationSearchSec {
    width: 250px;
  }

  .greyBannerArea.fixed .coachSearchForm {
    width: calc(100% - 560px);
  }

  app-invoice-list .greyBg {
    height: 110px;
  }
}

@media screen and (min-width : 1024px) and (max-width : 1366px) and (orientation : landscape) {

  app-koachee-offers-list select.offersDropdown,
  body .offersKeyword {
    width: 250px;
  }

  app-koachee-offers-list .coachSearchForm .formField,
  body .dateViewer .wc-date-container {
    width: 250px !important;
  }

  .locationSearchSec {
    width: 250px;
  }

  .endContract {
    margin-top: 16px !important;
  }

  .coachSearchForm .formField,
  body .dateViewer .wc-date-container {
    width: 250px;
  }

  .skillsDropDownList {
    width: 250px;
  }

  body .coachSearchForm {
    width: calc(100% - 330px);
  }

  .greyBannerArea.fixed .coachSearchForm {
    width: calc(100% - 562px);
  }

}

@media screen and (min-width : 1366px) {
  body .PaddingLeft {
    padding-left: 30px !important;
  }


  .endContract {
    margin-top: 16px !important;
  }

  .paddingLeftForPad {
    padding-left: 0px !important;
  }

  span.sessionText {
    margin-left: 20px;
  }

}

@media screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {

  .contactImportSec button {
    margin-top: 10px;
    min-width: 135px;
  }

  .contactButtons button.btn {
    min-width: 135px;
  }

  .ratings {
    left: 27px;
    text-align: left;
  }
}

@media screen and (min-width : 768px) and (max-width : 1280px) and (orientation : landscape) {
  .contactButtons button.btn {
    min-width: 135px;
  }
}